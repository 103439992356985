import React, { ReactElement } from 'react';
import { Columns, DataGrid } from '../../../../../../common/pov-common-ui';
import DisconnectedDataCenterWarning from '../DisconnectedDataCenterWarning/DisconnectedDataCenterWarning';

export type RebalanceTabContentProps = {
  isRebalanceTabVisible: boolean | undefined;
  data: IDemoDetails[];
  columns: Columns<IDemoDetails>;
};

export default function RebalanceTabContent({
  isRebalanceTabVisible,
  data,
  columns,
}: RebalanceTabContentProps): ReactElement {
  if (isRebalanceTabVisible === undefined) {
    return <></>;
  }

  if (!isRebalanceTabVisible) {
    return <DisconnectedDataCenterWarning />;
  }

  return (
    <DataGrid
      columns={columns}
      data={data}
      sortable={true}
      minRows={1}
      pageSize={500}
    />
  );
}
