import { ISavedExpo } from '../../../../../app/entities/expos/types';
import { Actions, FETCH_EXPO_SUCCESS, RESET } from '../../actions';

export type EditExpoState = ISavedExpo | null;

export default function expoReducer(
  state: EditExpoState = null,
  action: Actions,
): EditExpoState {
  switch (action.type) {
    case FETCH_EXPO_SUCCESS:
      return action.payload.expo;

    case RESET:
      return null;

    default:
      return state;
  }
}
