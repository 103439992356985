import { Card } from '../../../common/pov-common-ui';
import * as React from 'react';
import ContentLoader from 'react-content-loader';
import './styles.scss';

interface IProps {
  uniquekey: string;
}

export default function ExpoLoadingCard(props: IProps) {
  return (
    <div className="col-lg-6 base-margin-top menu-expo-card">
      <Card className="expo-loading-card">
        <ContentLoader uniquekey={props.uniquekey}>
          <rect x="0" y="20" rx="0" ry="0" width="80" height="90" />
          <rect x="100" y="12" rx="4" ry="4" width="60" height="18" />
          <rect x="100" y="40" rx="4" ry="4" width="150" height="15" />
          <rect x="100" y="70" rx="4" ry="4" width="120" height="10" />
          <rect x="100" y="100" rx="4" ry="4" width="120" height="10" />
        </ContentLoader>
      </Card>
    </div>
  );
}
