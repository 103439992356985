import * as React from 'react';
import './styles.scss';

interface IProps {
  showLoader?: boolean;
  accessibleLoadingMessage?: string;
  accessibleLoadedMessage?: string;
}

const defaultProps = {
  accessibleLoadedMessage: 'Content loaded',
  accessibleLoadingMessage: 'Content loading',
  showLoader: true,
};

const Loader: React.SFC<IProps> = ({
  showLoader,
  accessibleLoadedMessage,
  accessibleLoadingMessage,
}) => (
  <div className="row">
    <div className="col-md-12">
      <div
        className="loading-spinner flex-center"
        role="alert"
        aria-live="assertive"
      >
        {showLoader && (
          <div className="wrapper">
            <div className="wheel" />
          </div>
        )}
        <p className="loading-spinner-message">
          {showLoader ? accessibleLoadingMessage : accessibleLoadedMessage}
        </p>
      </div>
    </div>
  </div>
);

Loader.defaultProps = defaultProps;

export default Loader;
