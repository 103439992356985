import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IApplicationState } from '../../../../redux/types';
import { getEngagementData } from '../../../../redux/public/engagements/selectors';
import { getPublicExpo } from '../../../../redux/public/expos/selectors';
import { modalActions } from '../../../../redux/modals/actions';
import { engagementActions } from '../../../../redux/public/engagements/actions';
import { MODAL_NAMES } from '../../../common/components/modal/constants';
import ScheduledDemo from '.';

const mapStateToProps = (state: IApplicationState) => ({
  engagementData: getEngagementData(state),
  expo: getPublicExpo(state),
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  endDemo: () => dispatch(engagementActions.endEngagement()),
  extendEngagement: () => dispatch(engagementActions.extendEngagement()),
  openResourcesModal: (demo: IScheduledDemo) => {
    dispatch(
      modalActions.openModal(MODAL_NAMES.DEMO_RESOURCES, {
        resources: demo.resources,
      }),
    );
  },
  openAnyConnectModal: (demo: IScheduledDemo) => {
    dispatch(
      modalActions.openModal(MODAL_NAMES.ANY_CONNECT, {
        anyConnect: demo.session.anyConnect,
      }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledDemo);
