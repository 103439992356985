import { combineReducers } from 'redux';
import engagementsReducer, { IEngagementsState } from './engagements/reducer';
import publicExpoReducer, { PublicExpoState } from './expos/reducer';

import landingPageReducer, { LandingPageState } from './landing-page/reducer';

export interface IPublicState {
  engagements: IEngagementsState;
  expo: PublicExpoState;
  landing: LandingPageState;
}

export default combineReducers<IPublicState>({
  engagements: engagementsReducer,
  expo: publicExpoReducer,
  landing: landingPageReducer,
});
