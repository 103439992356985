import classnames from 'classnames';
import * as React from 'react';

import { GenerateId } from '../../utils';

import TabsItem from '../TabsItem/TabsItem';
import { Align } from '../../types';
import './styles.scss';

export interface IProps {
  active: number;
  id: string;
  bordered?: boolean;
  align?: Align;
  items: React.ReactNode;
  tall?: boolean;
  vertical?: boolean;
  setSelected(index: number, title: string): void;
}

class TabsItems extends React.Component<IProps> {
  public render(): React.ReactNode {
    const { active, id, bordered, align, tall, vertical, items, setSelected } =
      this.props;
    return (
      <ul
        role="tablist"
        className={classnames('tabs', {
          'tabs--bordered': bordered,
          'tabs--centered': align === 'centered',
          'tabs--right': align === 'right',
          'tabs--tall': tall,
          'tabs--vertical': vertical,
        })}
      >
        {React.Children.map(
          items,
          // tslint:disable-next-line:no-any
          (child: any, index: number): React.ReactNode =>
            child ? (
              <TabsItem
                id={GenerateId(id, index)}
                index={index}
                setSelected={setSelected}
                active={index === active}
                title={child.props.title}
              />
            ) : null,
        )}
      </ul>
    );
  }
}

export default TabsItems;
