import classNames from 'classnames';
import * as React from 'react';
import FormGroupText from './FormGroupText/FormGroupText';

export interface IProps {
  children: React.ReactNode;
  className?: string;
  compressed?: boolean;
  errors?: string[];
  inline?: boolean;
  inlineLabel?: boolean;
  formGroupTextClass?: string;
  withFormGroupText?: boolean;
}

const defaultProps = {
  className: '',
  compressed: false,
  errors: [],
  formGroupTextClass: '',
  inline: false,
  inlineLabel: false,
  withFormGroupText: false,
};

const FormGroup: React.SFC<IProps> = ({
  children,
  className,
  compressed,
  errors,
  formGroupTextClass,
  inline,
  inlineLabel,
  withFormGroupText,
}) => {
  const classes = classNames('form-group', className, {
    'form-group--error': errors!.length,
    'form-group--inline': inline,
    'input--compressed': compressed,
    'label--inline': inlineLabel,
  });

  return (
    <div className={classes}>
      {withFormGroupText ? (
        <FormGroupText className={formGroupTextClass}>{children}</FormGroupText>
      ) : (
        children
      )}
      {errors!.map((error) => (
        <div key={error} className="help-block text-danger" role="alert">
          <span>{error}</span>
        </div>
      ))}
    </div>
  );
};

FormGroup.defaultProps = defaultProps;

export default FormGroup;
