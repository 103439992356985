import {
  Actions,
  FETCH_ENGAGEMENT_SUCCESS,
  END_ENGAGEMENT_SUCCESS,
  CREATE_ENGAGEMENT_SUCCESS,
  CLEAR_ENGAGEMENT,
} from '../actions';

import { storageGet } from '../../../../app/common/storage';
import { LOCAL_STORAGE_KEYS } from '../../../../app/common/constants';

export interface IEngagementsState {
  data?: IDemoEngagement;
  uid: string | null;
  expoUid: string | null;
}

export const initialState: IEngagementsState = {
  uid: storageGet(LOCAL_STORAGE_KEYS.ENGAGEMENT_ID),
  expoUid: storageGet(LOCAL_STORAGE_KEYS.EXPO_UID),
};

function engagementsReducer(
  state: IEngagementsState = initialState,
  action: Actions,
): IEngagementsState {
  switch (action.type) {
    case FETCH_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };

    case CREATE_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        uid: action.payload.uid,
        expoUid: action.payload.expoUid,
      };

    case END_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        data: undefined,
        uid: null,
        expoUid: null,
      };

    case CLEAR_ENGAGEMENT:
      return {
        ...state,
        data: undefined,
        uid: null,
        expoUid: null,
      };

    default:
      return state;
  }
}

export default engagementsReducer;
