import { Columns } from '../../../../../common/pov-common-ui';
import * as React from 'react';
import Cell from '../../../../../common/components/table-cell';

export const ipAddressColumns: Columns<IIPAddressColumn> = [
  {
    Header: 'Public Address',
    Cell: (cellInfo) => (
      <Cell canCopy={true} value={cellInfo.original.publicAddress} />
    ),
    accessor: 'publicAddress',
  },
  {
    Header: 'Private Address',
    Cell: (cellInfo) => (
      <Cell canCopy={true} value={cellInfo.original.privateAddress} />
    ),
    accessor: 'privateAddress',
  },
  {
    Header: 'Description',
    Cell: (cellInfo) => <Cell value={cellInfo.original.description} />,
    accessor: 'description',
  },
];
