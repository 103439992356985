import { ExpoStates } from '../../entities/expos/constants';
import { IExpoBase, ISavedExpo } from '../../entities/expos/types';

export function isSavedExpo(expo: IExpoBase | ISavedExpo): expo is ISavedExpo {
  return !!expo.uid;
}

export const isPublishedExpo = (
  expoState: keyof typeof ExpoStates | undefined,
): boolean =>
  expoState === ExpoStates.PUBLISHED || expoState === ExpoStates.IN_PROGRESS;
