import { ToastPadding, ToastSeverity } from '../../../app/common/pov-common-ui';
import { Actions, HIDE_TOAST, SHOW_TOAST } from '../actions';

export const initialState = {
  title: '',
  message: '',
  severity: ToastSeverity.INFORMATION,
  padding: ToastPadding.LOOSE,
  show: false,
};

export interface IToastState {
  title: string;
  message: string;
  severity: ToastSeverity;
  padding: ToastPadding;
  show: boolean;
}

export default (state: IToastState = initialState, action: Actions) => {
  switch (action.type) {
    case SHOW_TOAST:
      return {
        ...state,
        title: action.payload.title,
        message: action.payload.message,
        severity: action.payload.severity,
        show: true,
      };

    case HIDE_TOAST:
      return {
        ...state,
        show: false,
      };

    default:
      return state;
  }
};
