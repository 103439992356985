import { Columns } from '../../../../../common/pov-common-ui';
import * as React from 'react';
import Cell from '../../../../../common/components/table-cell';

export const dnsColumns: Columns<IDnsColumn> = [
  {
    Header: 'Record type',
    Cell: (cellInfo) => <Cell value={cellInfo.original.type} />,
    accessor: 'type',
  },
  {
    Header: 'DNS Name',
    Cell: (cellInfo) => <Cell canCopy={true} value={cellInfo.original.name} />,
    accessor: 'name',
  },
];
