import { Button } from '../../pov-common-ui';
import classNames from 'classnames';
import * as React from 'react';
import { IEngagementTimerProps } from './state';
import './styles.scss';

export default function EngagementTimer({
  expiring,
  handleAcceptOffer,
  handleRejectOffer,
  info,
  offerText,
  showExtension,
  title,
}: Partial<IEngagementTimerProps>) {
  return (
    <div
      className={classNames('engagement-timer', {
        'can-extend': showExtension,
      })}
    >
      <div className="timer-title">{title}</div>
      <div className={classNames('timer-info', { expiring })}>{info}</div>
      {showExtension && (
        <div className="extension-offer">
          <span>{offerText}</span>
          <div className="buttons">
            <Button color="primary" size="default" onClick={handleAcceptOffer}>
              Yes
            </Button>
            <Button
              color="secondary"
              size="default"
              onClick={handleRejectOffer}
            >
              No
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
