import classNames from 'classnames';
import * as React from 'react';
import Tooltip from '../tool-tip';

import './styles.scss';
import { copyTextToClipboard } from '../../utilities/helpers';
import { IconButton } from '../../pov-common-ui';

export enum Classes {
  CELL = 'cell',
  CELL_ERROR = 'cell--error',
}

export interface IProps {
  errors?: string[];
  value?: string;
  error?: boolean;
  link?: string;
  canCopy?: boolean;
  newTab?: boolean;
}

export default function Cell({
  errors,
  value = '-',
  error,
  link,
  canCopy,
  newTab,
}: IProps): JSX.Element {
  const hasErrors = errors && !!errors.length;
  const classes = classNames(Classes.CELL, {
    [Classes.CELL_ERROR]: hasErrors || error,
  });
  const renderedValue = value === 'null' ? '' : value;

  return (
    <span className={classes}>
      {link && (
        <a target={newTab ? '_blank' : '_self'} href={link} rel="noreferrer">
          {renderedValue}
        </a>
      )}
      {!link && renderedValue}
      {!!hasErrors && <Tooltip content={(errors && errors.join(',')) ?? ''} />}
      {error && <span className="icon__alert icon-info-circle" />}
      {canCopy && (
        <IconButton
          icon="icon-recent-apps"
          color="primary"
          className="table-cell_copy-value"
          onClick={() => copyTextToClipboard(value)}
        />
      )}
    </span>
  );
}
