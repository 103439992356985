import { IExpo, IExpoWithCategories } from '../../../entities/expos/types';
import { imageAsset } from '../../../common/utilities/imageAssets';

export const DEMO_IMAGE_AMOUNT = 50;

export const assignThumbnailToDemo = (
  demo: IDemoWithoutAssignedImage,
  index: number,
): IDemo => ({
  ...demo,
  thumbnail: imageAsset(`demo/${index % DEMO_IMAGE_AMOUNT}.png`),
});

export const sortCategoriesByName = (
  categories: ICategorizedDemos[],
): ICategorizedDemos[] =>
  categories.sort((a, b) => {
    if (a.name < b.name) return -1;
    return 1;
  });

export function formatDemosIntoCategories(expo: IExpo): IExpoWithCategories {
  const { demos = [], ...newExpo } = expo;

  const categories = demos.reduce((arr, demo, i) => {
    const newDemo = assignThumbnailToDemo(demo, i);
    const index = arr.findIndex((item) => item.name === newDemo.category);

    if (index !== -1) {
      arr[index].demos.push(newDemo);
      return arr;
    }

    return [...arr, { name: demo.category, demos: [newDemo] }];
  }, [] as ICategorizedDemos[]);

  return {
    ...newExpo,
    categories: sortCategoriesByName(categories),
  };
}
