import React, { PropsWithChildren, ReactElement } from 'react';

export type DisconnectedDataCenterTooltipProps = PropsWithChildren<{
  tooltipLabel: string;
}>;

export default function DisconnectedDataCenterTooltip({
  tooltipLabel,
  children,
}: DisconnectedDataCenterTooltipProps): ReactElement {
  return (
    <span
      data-balloon={tooltipLabel}
      data-balloon-pos="up"
      data-balloon-break={true}
    >
      {children}
    </span>
  );
}
