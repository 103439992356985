import React, { ReactElement } from 'react';

export type ExclamationTriangleIconProps = {
  title?: string;
  titleId?: string;
};

const ExclamationTriangleIcon = ({
  title = 'Exclamation triangle icon',
  titleId = 'exclamation-triangle-icon',
}: ExclamationTriangleIconProps): ReactElement => (
  <svg
    aria-labelledby={titleId}
    role="img"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id={titleId}>{title}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.82473 4.34161C10.7822 2.65077 13.2181 2.65078 14.1756 4.34162L21.6369 17.518C22.5807 19.1846 21.3767 21.2499 19.4615 21.2499H4.53882C2.62359 21.2499 1.41966 19.1846 2.36339 17.518L9.82473 4.34161Z"
      fill="#D9B216"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 13.75L11 9.5C11 8.94772 11.4477 8.5 12 8.5C12.5523 8.5 13 8.94772 13 9.5L13 13.75C13 14.3023 12.5523 14.75 12 14.75C11.4477 14.75 11 14.3023 11 13.75Z"
      fill="#464C54"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.25 17.25C13.25 17.9404 12.6904 18.5 12 18.5C11.3096 18.5 10.75 17.9404 10.75 17.25C10.75 16.5596 11.3096 16 12 16C12.6904 16 13.25 16.5596 13.25 17.25Z"
      fill="#464C54"
    />
  </svg>
);

export default ExclamationTriangleIcon;
