import classnames from 'classnames';
import * as React from 'react';
import { CiscoButtonColours, CiscoButtonSizes } from '../../constants';

export interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  ariaLabel?: string;
  className?: string;
  icon: string;
  disabled?: boolean;
  id?: string;
  size?: CiscoButtonSizes;
  testId?: string;
  colour?: CiscoButtonColours;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const defaultProps: Partial<IProps> = {
  ariaLabel: undefined,
  className: '',
  colour: CiscoButtonColours.DEFAULT,
  disabled: false,
  id: undefined,
  onClick: () => void 0,
  size: CiscoButtonSizes.DEFAULT,
  testId: 'icon-button',
  type: 'button',
};

const classes = (props: IProps) =>
  classnames(
    'btn btn--icon',
    `btn--${props.size}`,
    `btn--${props.colour}`,
    props.className,
  );

const IconButton: React.SFC<IProps> = (props) => {
  const { ariaLabel, id, disabled, onClick, icon, type } = props;
  return (
    <button
      aria-label={ariaLabel}
      disabled={disabled}
      id={id}
      onClick={onClick}
      className={classes(props)}
      data-testid={props.testId}
      type={type}
    >
      <span className={icon} />
    </button>
  );
};

IconButton.defaultProps = defaultProps;
export default IconButton;
