import {
  IModalProps,
  IAccessCodeModalProps,
  IApiErrorModalProps,
  IAnyConnectModalProps,
  IDemoModalProps,
} from '../../app/common/components/modal/modal-types';
import { Actions, CLOSE_MODAL, OPEN_MODAL, MODAL_LOADING } from './actions';

export const initialState = {
  name: '',
  props: null,
  loading: false,
};

export interface IModalState {
  name: string;
  props:
    | IModalProps
    | IAccessCodeModalProps
    | IApiErrorModalProps
    | IAnyConnectModalProps
    | IDemoModalProps
    | null;
  loading: boolean;
}

export default (state: IModalState = initialState, action: Actions) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        name: action.payload.name,
        props: action.payload.props || {},
      };

    case CLOSE_MODAL:
      return initialState;

    case MODAL_LOADING:
      return { ...state, loading: action.payload };

    default:
      return state;
  }
};
