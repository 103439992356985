import { imageAsset } from '../../common/utilities/imageAssets';

export const TOP_DCLOUD_DEMOS = [
  {
    title: 'Cisco Umbrella',
    description: 'Inteligence to uncover current and emerging threats',
    buttonText: 'Instant',
    buttonUrl: 'https://dcloud-dna-umbrella-rtp.cisco.com/?_open=ext',
    thumbnail: imageAsset('landing-page/top-demos/Umbrella.png'),
  },
  {
    title: 'Hyperflex Simulator',
    description: 'Evaluate, deploy, and manage HyperFlex Systems',
    buttonText: 'Schedule',
    buttonUrl:
      'https://dcloud2-rtp.cisco.com/content/demo/576825?returnPathTitleKey=content-view',
    thumbnail: imageAsset('landing-page/top-demos/Hyperflex.png'),
  },
  {
    title: 'FMC',
    description: 'Threat-centric next-generation securtiy system',
    buttonText: 'Instant',
    buttonUrl:
      'https://dcloud-dna-fmc-rtp.cisco.com/dashboard/view.cgi?id=7ae1d93c-e8f3-11e6-97df-442571fbd5b7',
    thumbnail: imageAsset(
      'landing-page/top-demos/Firepower-Management-Center.png',
    ),
  },
  {
    title: '4D SD-WAN Viptela',
    description: 'Viptela provides a compelling SD-WAN solution',
    buttonText: 'Schedule',
    buttonUrl:
      'https://dcloud2-rtp.cisco.com/content/demo/434224?returnPathTitleKey=content-view',
    thumbnail: imageAsset('landing-page/top-demos/4d-SD-WAN.png'),
  },
  {
    title: 'Cyber Defense Clinic Lab',
    description: 'Watch a cyberattack unfold',
    buttonText: 'Schedule',
    buttonUrl:
      'https://dcloud2-rtp.cisco.com/content/demo/522666?returnPathTitleKey=content-view\n',
    thumbnail: imageAsset('landing-page/top-demos/Cyber-Defence.png'),
  },
];

export const EXPO_TUTORIALS = [
  {
    title: 'Introducing eXpo',
    description: 'Discover the essentials of Cisco eXpo',
    buttonText: 'Watch',
    buttonUrl: 'https://player.vimeo.com/video/342252160',
    thumbnail: imageAsset('landing-page/video-thumbnails/thumbnail1.png'),
  },
  {
    title: 'Creating an eXpo',
    description: 'Learn how to setup an eXpo as an event admin',
    buttonText: 'Watch',
    buttonUrl: 'https://player.vimeo.com/video/343613992',
    thumbnail: imageAsset('landing-page/video-thumbnails/thumbnail2.png'),
  },
  {
    title: 'Running an eXpo',
    description: "OK, it's the day of the event. What happens?",
    buttonText: 'Watch',
    buttonUrl: '',
    thumbnail: imageAsset('landing-page/video-play.png'),
  },
  {
    title: 'The future of eXpo',
    description: "So, what's next for eXpo",
    buttonText: 'Watch',
    buttonUrl: '',
    thumbnail: imageAsset('landing-page/video-play.png'),
  },
];

export const WHY_EXPO_TABLE_DATA = [
  'Custom portals for each event',
  'Leverage content from all dCloud locations',
  '24/6 Helpdesk Support',
  'Cisco Brand Approved Graphics',
  'Self Service portal building',
  'No Cisco account needed to run demos',
  'Ability to balance content during event',
  'Metrics capture',
];
