import { connect } from 'react-redux';
import {
  getCsvState,
  getCsvValue,
  getDisableValidate,
  getDisableCheckCapacity,
  isCapacityChecked,
  getCapacityDetail,
} from '../../../../../redux/admin/edit/selectors';
import { IApplicationState } from '../../../../../redux/types';
import Content from '.';
import { editActions } from '../../../../../redux/admin/edit/actions';

const mapStateToProps = (state: IApplicationState) => {
  const csvState = getCsvState(state);

  return {
    demoContent: csvState.demoContent,
    errors: csvState.errors,
    successfulCsvValue: csvState.successfulCsvValue,
    valid: csvState.valid,
    validating: csvState.validating,
    value: getCsvValue(state),
    disableCheckCapacity: getDisableCheckCapacity(state),
    disableValidate: getDisableValidate(state),
    isCapacityChecked: isCapacityChecked(state),
    capacityDetail: getCapacityDetail(state),
  };
};

const mapDispatchToProps = {
  validate: editActions.validateCsv,
  setCsvValue: editActions.setCsvValue,
  checkCapacity: editActions.checkCapacity,
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
