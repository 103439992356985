import { LOCAL_STORAGE_KEYS } from './constants';

export function storageSet(key: LOCAL_STORAGE_KEYS, value: string) {
  window.localStorage.setItem(key, value);
}

export function storageDelete(key: LOCAL_STORAGE_KEYS) {
  window.localStorage.removeItem(key);
}

export function storageGet(key: LOCAL_STORAGE_KEYS) {
  return window.localStorage.getItem(key);
}
