import { SagaIterator } from 'redux-saga';
import { call, delay, put, select } from 'redux-saga/effects';
import { getExposSortBy } from '../../selectors';
import { getExpos } from '../../../../../app/entities/expos/service';
import {
  IExpoResponse,
  ISavedExpo,
} from '../../../../../app/entities/expos/types';
import { exposActions } from '../../actions';
import { FLICKER_DELAY } from '../../../../../app/common/constants';
import { ExpoDateFilters } from '../../../../../app/entities/expos/constants';
import { mapFromExpoResponse } from '../../../../../app/entities/expos/utilities';

export default function* fetchExposWorker(): SagaIterator {
  const sortBy: ExpoDateFilters = yield select(getExposSortBy);

  try {
    const response: IExpoResponse[] = yield call(getExpos, sortBy);
    const expos: ISavedExpo[] = response.map(mapFromExpoResponse);
    yield delay(FLICKER_DELAY);
    yield put(exposActions.fetchExposSuccess(expos));
  } catch (e) {
    throw e;
  }
}
