import { Columns } from '../../../../../common/pov-common-ui';
import * as React from 'react';
import Cell from '../../../../../common/components/table-cell';

export const anyConnectColumns: Columns<IAnyconnectColumn> = [
  {
    Header: 'Host',
    Cell: (cellInfo) => <Cell canCopy={true} value={cellInfo.original.host} />,
    accessor: 'host',
  },
  {
    Header: 'User',
    Cell: (cellInfo) => <Cell canCopy={true} value={cellInfo.original.user} />,
    accessor: 'user',
  },
  {
    Header: 'Password',
    Cell: (cellInfo) => (
      <Cell canCopy={true} value={cellInfo.original.password} />
    ),
    accessor: 'password',
  },
];
