import { ToastSeverity } from '../../../../../app/common/pov-common-ui';
import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { globalUiActions } from '../../../../ui/loader/actions';
import { checkCapacity } from '../../../../../app/entities/expos/service';
import { toastActions } from '../../../../toast/actions';
import { ISavedExpo } from '../../../../../app/entities/expos/types';
import {
  doesContentContainError,
  renderCapacityContent,
} from '../../../../../app/admin/edit/utilities';
import { editActions } from '../../actions';
import { getTempExpo } from '../../selectors';

export const CHECK_CAPACITY_SUCCESS_MSG = 'Check Capacity Success';

export default function* checkCapacityWorker(): SagaIterator {
  const expo: ISavedExpo = yield select(getTempExpo);
  yield put(globalUiActions.showLoader());

  try {
    const content = yield call(checkCapacity, expo.uid);

    const containsError = yield call(doesContentContainError, content);

    const formattedData = yield call(renderCapacityContent, content);

    if (containsError) {
      yield put(editActions.checkCapacityFailed(formattedData));
    } else {
      yield put(
        toastActions.showToast(
          'Success',
          CHECK_CAPACITY_SUCCESS_MSG,
          ToastSeverity.SUCCESS,
        ),
      );

      yield put(editActions.checkCapacitySuccess(formattedData));
    }
  } catch (e) {
    throw e;
  } finally {
    yield put(globalUiActions.hideLoader());
  }
}
