import * as React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../common/constants';
import Create from './create/container';
import Edit from './edit/container';
import Info from './info/container';
import Menu from './menu/container';
import { exposActions } from '../../redux/admin/expos/actions';
import { metadataActions } from '../../redux/admin/metadata/actions';
import { CenteredLoader } from '../common/components/loader';

interface IProps {
  exposFetched: boolean;
  exposLoading: boolean;
  surveyTypesFetched: boolean;
  surveyTypesLoading: boolean;
  fetchExpos: typeof exposActions.fetchExpos;
  fetchSurveyTypes: typeof metadataActions.fetchSurveyTypes;
}

export default function Admin({
  exposFetched,
  exposLoading,
  surveyTypesFetched,
  surveyTypesLoading,
  fetchExpos,
  fetchSurveyTypes,
}: IProps) {
  React.useEffect(() => {
    if (!exposFetched && !exposLoading) {
      fetchExpos();
    }
    if (!surveyTypesFetched && !surveyTypesLoading) {
      fetchSurveyTypes();
    }
  }, [
    fetchExpos,
    exposFetched,
    exposLoading,
    fetchSurveyTypes,
    surveyTypesFetched,
    surveyTypesLoading,
  ]);

  if (!exposFetched) {
    return <CenteredLoader />;
  }

  return (
    <div className="u-with-sticky-header">
      <Switch>
        <Route exact={true} path={ROUTES.ADMIN} component={Menu} />
        <Route exact={true} path={ROUTES.ADMIN_CREATE} component={Create} />
        <Route
          exact={true}
          path={[ROUTES.ADMIN_EDIT, ROUTES.ADMIN_PREVIEW]}
          component={Edit}
        />
        <Route path={ROUTES.ADMIN_VIEW_SESSION_INFO_CONTENT} component={Info} />
        <Redirect to={ROUTES.PAGE_NOT_FOUND_404} />
      </Switch>
    </div>
  );
}
