import { IExpoWithCategories } from '../../../../app/entities/expos/types';
import { Actions, FETCH_EXPO_SUCCESS } from '../actions';

export type PublicExpoState = IExpoWithCategories | null;

export default function publicExpoReducer(
  state: PublicExpoState = null,
  action: Actions,
): PublicExpoState {
  switch (action.type) {
    case FETCH_EXPO_SUCCESS:
      return action.payload;

    default:
      return state;
  }
}
