/* eslint-disable no-unused-vars */
import * as React from 'react';
import { KeyCodes } from '../../constants';
import './styles.scss';

export interface IProps {
  name: string;
  onSelect: (value: string) => void;
}

export default class Option extends React.Component<IProps> {
  public onSelect = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.props.onSelect(this.props.name);
  };

  public onKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.keyCode === KeyCodes.ENTER) {
      this.props.onSelect(this.props.name);
    }
  };

  public render() {
    const { name } = this.props;

    return (
      <button
        className="btn btn--link"
        key={name}
        onClick={this.onSelect}
        onKeyDown={this.onKeyDown}
        tabIndex={1}
      >
        {name}
      </button>
    );
  }
}
