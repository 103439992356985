import {
  Actions,
  SEND_ACCESS_CODE,
  SEND_ACCESS_CODE_SUCCESS,
  SEND_ACCESS_CODE_ERROR,
} from '../actions';
import { ACCESS_CODE_ERRORS } from '../constants';

export type LandingPageState = Readonly<typeof initialState>;

export const initialState = {
  loading: false,
  errors: [] as string[],
};

export default function landingPageReducer(
  state = initialState,
  action: Actions,
): LandingPageState {
  switch (action.type) {
    case SEND_ACCESS_CODE:
      return {
        ...state,
        errors: [],
        loading: true,
      };

    case SEND_ACCESS_CODE_SUCCESS:
      return initialState;

    case SEND_ACCESS_CODE_ERROR:
      return {
        ...state,
        loading: false,
        errors: ACCESS_CODE_ERRORS,
      };

    default:
      return state;
  }
}
