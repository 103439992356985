import { IApplicationState } from '../../types';

export const getMetadataSurveyTypesState = (state: IApplicationState) =>
  state.admin.metadata.surveyTypes;

export const getMetadataSurveyTypes = (state: IApplicationState) =>
  getMetadataSurveyTypesState(state).items;

export const getMetadataSurveyTypesFetched = (state: IApplicationState) =>
  getMetadataSurveyTypesState(state).fetched;

export const getMetadataSurveyTypesLoading = (state: IApplicationState) =>
  getMetadataSurveyTypesState(state).loading;
