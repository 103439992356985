import { call, put, select } from 'redux-saga/effects';
import { IActionWithPayload } from '../../../../action-helpers';
import { APPLY_REBALANCE_CHANGES, infoActions } from '../../actions';
import { MODAL_NAMES } from '../../../../../app/common/components/modal/constants';
import { updateTargetCount } from '../../../../../app/entities/expos/service';
import {
  getFilterBy,
  getInitialTotalTargetSessions,
  getInputTargetState,
  getTotalTargetSessions,
} from '../../selectors';

import { modalActions } from '../../../../modals/actions';
import { SagaIterator } from 'redux-saga';
import { toastActions } from '../../../../toast/actions';
import { ToastSeverity } from '../../../../../app/common/pov-common-ui';

type Action = IActionWithPayload<typeof APPLY_REBALANCE_CHANGES, string>;

export const buildUpdateCountData = (
  filterBy: IRebalanceFilterValues,
  inputTargetState: ITargetState,
) => {
  return {
    dataCenter: filterBy.dataCenter,
    contentPool: {
      id: filterBy.contentPool?.id,
    },
    demos: Object.keys(inputTargetState).map((key) => ({
      uid: key,
      targetSessions: inputTargetState[key],
    })),
  };
};

export default function* getApplyChangesWorker(action: Action): SagaIterator {
  const uid = action.payload;
  const totalTargetSessions: number = yield select(getTotalTargetSessions);
  const initialTotalTargetSessions: number = yield select(
    getInitialTotalTargetSessions,
  );

  if (totalTargetSessions !== initialTotalTargetSessions) {
    yield put(
      modalActions.openModal(MODAL_NAMES.GENERIC_ERROR, {
        errors: [
          {
            message:
              'Target value does not equal the Total session count for this Data Center and Content Pool.',
          },
        ],
        title: 'Total Session Count Invalid',
      }),
    );

    return;
  }

  yield put(infoActions.showLoader());

  try {
    const inputTargetState = yield select(getInputTargetState);
    const filterBy = yield select(getFilterBy);

    const data = buildUpdateCountData(filterBy, inputTargetState);

    yield call(updateTargetCount, uid, data);

    yield put(infoActions.updateTargetCountSuccess());
    yield put(
      toastActions.showToast(
        'Session Rebalance Success',
        'Demo session target counts successfully applied',
        ToastSeverity.SUCCESS,
      ),
    );
  } catch (e) {
    throw e;
  } finally {
    yield put(infoActions.hideLoader());
  }
}
