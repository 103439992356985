import * as React from 'react';
import './styles.scss';

interface IProps {
  amount: number;
}

export default function NotificationBubble({ amount }: IProps) {
  return <span className="notification-bubble">{amount}</span>;
}
