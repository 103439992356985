import { ToastSeverity } from '../../../../../app/common/pov-common-ui';
import { SagaIterator } from 'redux-saga';
import { call, delay, put } from 'redux-saga/effects';
import { FLICKER_DELAY } from '../../../../../app/common/constants';
import { toastActions } from '../../../../toast/actions';
import { modalActions } from '../../../../modals/actions';
import { deleteExpo } from '../../../../../app/entities/expos/service';
import { exposActions, DELETE_EXPO } from '../../actions';
import { IActionWithPayload } from '../../../../action-helpers';

export default function* deleteExpoWorker(
  action: IActionWithPayload<typeof DELETE_EXPO, string>,
): SagaIterator {
  try {
    yield put(modalActions.modalLoading(true));
    yield call(deleteExpo, action.payload);
    yield delay(FLICKER_DELAY);
    yield put(exposActions.deleteExpoSuccess(action.payload));
    yield put(modalActions.closeModal());
    yield put(
      toastActions.showToast('Success', 'eXpo deleted', ToastSeverity.SUCCESS),
    );
  } catch (e) {
    throw e;
  } finally {
    yield put(modalActions.modalLoading(false));
  }
}
