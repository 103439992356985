import classNames from 'classnames';
import * as React from 'react';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const FormGroupText: React.SFC<IProps> = ({ children, className = '' }) => {
  const classes = classNames('form-group__text', className);

  return <div className={classes}>{children}</div>;
};

export default FormGroupText;
