import React, { ReactElement } from 'react';
import {
  Checkbox,
  Dropdown,
  FormGroup,
  Label,
  TextInput,
} from '../../../common/pov-common-ui';
import { feedbackSurveyOptions } from '../../../entities/expos/utilities';
import useFeedbackSurveyManagementState from './state';

import { IFeedbackSurveyManagementProps } from './types';

const FeedbackSurveyManagement = ({
  fieldOption,
  fieldUrl,
  updateField,
  values,
  surveyTypes = [],
}: IFeedbackSurveyManagementProps): ReactElement => {
  const { feedbackSurveyManagement, feedbackOption, feedbackUrl } = values;
  const { toggleSurvey, updateURL } =
    useFeedbackSurveyManagementState(updateField);

  return (
    <div className="feedback-survey-control">
      <Checkbox
        id="feedbackSurveyManagement"
        name="feedbackSurveyManagement"
        onChange={toggleSurvey}
        label="Feedback/Survey Management"
        checked={feedbackSurveyManagement}
      />
      {feedbackSurveyManagement && (
        <div className="row">
          <div className="col-md-3">
            <FormGroup inline={true} errors={fieldOption.errors}>
              <Label labelFor={fieldOption.id}>Feedback Option</Label>
              <Dropdown
                id={fieldOption.id}
                name={fieldOption.name}
                onChange={updateField}
                options={feedbackSurveyOptions(surveyTypes)}
                selected={feedbackOption ?? ''}
              />
            </FormGroup>
          </div>
          <div className="col-md-6">
            <TextInput
              id={fieldUrl.id}
              name={fieldUrl.name}
              errors={fieldUrl.errors}
              onBlur={updateURL}
              onChange={updateURL}
              required={true}
              label="Survey URL"
              type="text"
              value={feedbackUrl}
              disabled={!feedbackOption}
              maxLength={2048}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FeedbackSurveyManagement;
