import React, { ReactElement } from 'react';
import Cell from '../../../../../../common/components/table-cell';
import DisconnectedDataCenterTooltip from '../DisconnectedDataCenterTooltip/DisconnectedDataCenterTooltip';
import './styles.scss';

export type DataCenterCellRendererProps = {
  dataCenterCode: string | undefined;
  isDataCenterConnected: boolean;
};

export default function DataCenterCellRenderer({
  dataCenterCode,
  isDataCenterConnected,
}: DataCenterCellRendererProps): ReactElement {
  if (isDataCenterConnected) {
    return <Cell value={dataCenterCode} />;
  }

  return (
    <div className="dataCenterStatus">
      <Cell value={dataCenterCode} />
      <DisconnectedDataCenterTooltip tooltipLabel="Disconnected">
        <span className="icon-exclamation-circle" />
      </DisconnectedDataCenterTooltip>
    </div>
  );
}
