import classnames from 'classnames';
import * as React from 'react';

export type SizeTypes = 'default' | 'large';

const defaultProps = {
  checked: false,
  disabled: false,
  required: false,
  size: 'default' as SizeTypes,
  value: '',
};

type DefaultProps = Readonly<typeof defaultProps>;

export type TProps = Required<{
  id?: string;
  label: string;
  name: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
}> &
  Partial<DefaultProps>;

const Switch: React.SFC<TProps> = ({
  checked,
  id,
  label,
  onChange,
  name,
  required,
  disabled,
  size,
  value,
}) => {
  const classes = classnames('switch', {
    disabled,
    'switch--large': size === 'large',
  });

  return (
    <label className={classes}>
      <input
        aria-labelledby={id}
        type="checkbox"
        aria-checked={checked}
        checked={checked}
        name={name}
        onChange={onChange}
        disabled={disabled}
        value={value}
      />
      <span className="switch__input" role="checkbox" aria-checked={checked} />
      <span className="switch__label hidden-xs" id={id}>
        {label}
        {required && <sup>*</sup>}
      </span>
    </label>
  );
};

Switch.defaultProps = defaultProps;

export default Switch;
