import { connect } from 'react-redux';
import { infoActions } from '../../../../../redux/admin/info/actions';
import EndDemoEngagementModal, { IProps } from './index';
import { Dispatch } from 'redux';

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IProps) => ({
  adminEndEngagement: () =>
    dispatch(infoActions.endEngagement(ownProps.engagementId)),
});

export default connect(null, mapDispatchToProps)(EndDemoEngagementModal);
