import { createAction } from '../../../action-helpers';
import { ActionsUnion } from '../../../action-types';
import { IExpoBase } from '../../../../app/entities/expos/types';

export const SET_FORM_VALIDITY = 'ADMIN/CREATE/SET_FORM_VALIDITY';
export const UPDATE_EXPO = 'ADMIN/CREATE/UPDATE_EXPO';
export const CREATE_EXPO = 'ADMIN/CREATE/CREATE_EXPO';
export const CREATE_EXPO_SUCCESS = 'ADMIN/CREATE/CREATE_EXPO_SUCCESS';

const setFormValidity = (valid: boolean) =>
  createAction(SET_FORM_VALIDITY, valid);

const updateExpo = (expo: IExpoBase) => createAction(UPDATE_EXPO, expo);
const createExpo = () => createAction(CREATE_EXPO);
const createExpoSuccess = () => createAction(CREATE_EXPO_SUCCESS);

export const createActions = {
  createExpo,
  createExpoSuccess,
  setFormValidity,
  updateExpo,
};

export type Actions = ActionsUnion<typeof createActions>;
