import { all } from 'redux-saga/effects';
import toastWatcher from './toast/sagas';
import engagementsWatcher from './public/engagements/sagas';
import publicExpoWatcher from './public/expos/sagas';
import landingPageWatcher from './public/landing-page/sagas';
import expoInfoWatcher from './admin/info/sagas';
import { authWatchers } from './authenticated/sagas';

export default function* sagas() {
  yield all([
    expoInfoWatcher(),
    toastWatcher(),
    engagementsWatcher(),
    publicExpoWatcher(),
    landingPageWatcher(),
    authWatchers(),
  ]);
}
