import { FETCH_EXPO_SUCCESS, Actions, RESET } from '../../actions';

export default function etagReducer(state = '', action: Actions): string {
  switch (action.type) {
    case FETCH_EXPO_SUCCESS:
      return action.payload.etag;

    case RESET:
      return '';

    default:
      return state;
  }
}
