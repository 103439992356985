import { ChangeEvent, useState, useCallback } from 'react';

type Touched<T> = { [K in keyof T]: boolean };
type Inputs = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
type Fields<T> = { [K in keyof T]+?: T[K] };

// tslint:disable-next-line: no-any
export type ValidationFunction = <V = {}>(value: any, values?: V) => string;
export type Validation<T> = { [K in keyof T]: ValidationFunction[] };
export type RegexRestriction<T> = { [K in keyof T]+?: RegExp };
export type OnChange = (e: ChangeEvent<Inputs>) => void;

export type Config<T, OC> = {
  readonly [K in keyof T]-?: {
    id: K;
    name: K;
    onBlur: OC;
    onChange: OC;
    value: T[K];
    errors: string[];
  };
};

export interface IFormConfig {
  id: string;
  name: string;
  onBlur: OnChange;
  onChange: OnChange;
  value: string | number | undefined;
  errors: string[];
}

export const checkAnyObjValuesSet = <T>(obj: T): boolean =>
  Object.keys(obj).some(
    (k) => obj[k] !== undefined && obj[k] !== null && obj[k] !== '',
  );

export const checkInputHasValue = <T>(value: T): boolean => {
  if (
    typeof value === 'object' &&
    !(value instanceof Array) &&
    value !== null
  ) {
    return checkAnyObjValuesSet(value);
  }

  if (value instanceof Array) {
    return !!value.length;
  }

  return !!value;
};

export const validate = <T, V>(
  validators: ValidationFunction[],
  value: T,
  values: V,
  touched = false,
): string[] => {
  if (!touched || !validators) return [];

  return validators
    .map((validator) => validator(value, values))
    .filter((error) => !!error);
};

export const setupTouched = <T>(values: T): Touched<T> => {
  const items = Object.keys(values).map((key: string) => ({
    [key]: checkInputHasValue(values[key]),
  }));
  return Object.assign({}, ...items) as Touched<T>;
};

export const checkValidity = <V, R>(values: V, rules: R): boolean => {
  const errors = Object.keys(values).reduce((arr, key) => {
    const validators: ValidationFunction[] = rules[key];
    return [...arr, ...validate(validators, values[key], values, true)];
  }, [] as string[]);

  return !errors.length;
};

export const createFormConfig = <T, OC>(
  values: T,
  onChange: OC,
  validation: Validation<T>,
  touched: Touched<T>,
): Config<T, OC> => {
  const items = Object.keys(values).map((key: string) => ({
    [key]: {
      id: key,
      name: key,
      onChange,
      onBlur: onChange,
      value: values[key],
      errors: validate(validation[key], values[key], values, touched[key]),
    },
  }));

  return Object.assign({}, ...items) as Config<T, OC>;
};

export default function useFormValidation<T>(
  initialValues: T,
  validation: Validation<T>,
  onValuesChange?: (values: T) => void,
  regexRestriction?: RegexRestriction<T>,
): {
  form: Config<T, OnChange>;
  updateField: <V>(name: string, value: V) => void;
  updateMultipleFields: (fields: Fields<T>) => void;
  valid: boolean;
  values: T;
} {
  const [values, setValues] = useState(initialValues);
  const [touched, setTouched] = useState(setupTouched(initialValues));

  const changeFieldValue = useCallback(
    <V>(name: string, value: V): void => {
      setValues((prevState) => {
        const newValues = { ...prevState, [name]: value };
        if (onValuesChange) onValuesChange(newValues);
        return newValues;
      });
    },
    [setValues, onValuesChange],
  );

  const setFieldToTouched = useCallback(
    (name: string): void => {
      setTouched((prevState) => ({
        ...prevState,
        [name]: true,
      }));
    },
    [setTouched],
  );

  const setMultipleFieldsToTouched = useCallback(
    (fields: Fields<T>) => {
      const keys = Object.keys(fields);

      setTouched((prevState) => {
        const newTouched = { ...prevState };
        keys.forEach((key) => {
          newTouched[key] = true;
        });
        return newTouched;
      });
    },
    [setTouched],
  );

  const onChange = useCallback(
    (e: ChangeEvent<Inputs>): void => {
      const { name, value } = e.target;

      if (
        !regexRestriction ||
        !regexRestriction[name] ||
        (regexRestriction[name] && value.match(regexRestriction[name]))
      ) {
        changeFieldValue(name, value);
        setFieldToTouched(name);
      }
    },
    [changeFieldValue, setFieldToTouched, regexRestriction],
  );

  const updateField = useCallback(
    <V>(name: string, value: V): void => {
      setFieldToTouched(name);
      changeFieldValue(name, value);
    },
    [setFieldToTouched, changeFieldValue],
  );

  const updateMultipleFields = useCallback(
    (fields: Fields<T>) => {
      setMultipleFieldsToTouched(fields);
      setValues((prevState) => {
        const newValues = { ...prevState, ...fields };
        if (onValuesChange) onValuesChange(newValues);
        return newValues;
      });
    },
    [setMultipleFieldsToTouched, onValuesChange],
  );

  return {
    form: createFormConfig(values, onChange, validation, touched),
    updateField,
    updateMultipleFields,
    valid: checkValidity(values, validation),
    values,
  };
}
