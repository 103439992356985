import * as React from 'react';
import DemoItem from '../../../common/components/demo-item';
import { LinkButton } from '../../../common/components/link-button';
import './styles.scss';

export interface IProps {
  title: string;
  thumbnail: string;
  description: string;
  buttonText: string;
  buttonUrl: string;
}

export default function LandingPageItem(props: IProps) {
  return (
    <DemoItem
      className="landing-page-item"
      id={props.title}
      thumbnail={props.thumbnail}
      content={
        <>
          <h5 className="landing-page-item__title">{props.title}</h5>
          <p className="landing-page-item__desc">{props.description}</p>
        </>
      }
      buttons={
        <LinkButton to={props.buttonUrl} target="_blank">
          {props.buttonText}
        </LinkButton>
      }
    />
  );
}
