import * as React from 'react';
import { Button, Card } from '../../../common/pov-common-ui';
import './styles.scss';

export default function DemoOfTheMonth() {
  return (
    <Card className="col-lg-8 demo-of-the-month">
      <h6>Demo of the month</h6>
      <h1 className="display-4"> Cisco DNA Center</h1>
      <h5>Open platform for intent-based networking</h5>
      <Button color="primary">
        <a
          href="https://dcloud-dna-center-inst-rtp.cisco.com/?_open=ext"
          rel="noopener noreferrer"
          target="_blank"
        >
          Demo Now
        </a>
      </Button>
    </Card>
  );
}
