import { Timezone } from 'react-timezone';

export const maxLength =
  (err: string, length: number) =>
  (value = ''): string => {
    return value.length > length ? err : '';
  };

export const notAFloat =
  (err: string) =>
  (value: string): string =>
    value.toString().indexOf('.') < 0 ? '' : err;

export const positiveNum =
  (err: string) =>
  (value = ''): string => {
    return Number(value) <= 0 && value.length ? err : '';
  };

export const aboveEqualToInt =
  (err: string, num: number) =>
  (value = ''): string => {
    const val = Number(value);
    return val >= num ? '' : err;
  };

export const belowEqualToInt =
  (err: string, num: number) =>
  (value = ''): string => {
    const val = Number(value);
    return val <= num ? '' : err;
  };

export const numberBetween =
  (err: string, min: number, max: number) =>
  (value: number): string => {
    return value >= min && value <= max ? '' : err;
  };

export const requiredValue =
  (err: string) =>
  (value = ''): string => {
    return !value ? err : '';
  };

export const requiredAlphaNumSpace =
  (err: string) =>
  (value = ''): string => {
    return !value === !value.match(/^\s+$/) || !value.match(/^[a-zA-Z0-9/\s]+$/)
      ? err
      : '';
  };

export const requiredType =
  (err: string) =>
  (value = ''): string => {
    return parseInt(value, 10) === -1 ? err : '';
  };

export const noSpecialCharacters =
  (err: string) =>
  (value: string): string => {
    const regex = /^[a-zA-Z0-9/\-.',\-\s]+$/;
    return value.match(regex) ? '' : err;
  };

export const requiredTimeZone =
  (err: string) =>
  (value: Timezone): string =>
    !value.name && !value.label ? err : '';

export const emailValidation =
  (err: string) =>
  (value: string): string => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return !regex.test(value.toLowerCase()) ? err : '';
  };

export const requireCheckedBox =
  (err: string) =>
  (value: boolean): string => {
    return value ? '' : err;
  };

export const isCheckedAndHasValue =
  (err: string, elementId: string) =>
  (value = ''): string => {
    const [checkbox] = document.getElementsByName(
      elementId,
    ) as NodeListOf<HTMLInputElement>;
    if (!checkbox?.checked) {
      return '';
    }
    return !value ? err : '';
  };
