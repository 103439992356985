import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getPublicExpo } from '../../../../redux/public/expos/selectors';
import { IApplicationState } from '../../../../redux/types';
import { modalActions } from '../../../../redux/modals/actions';
import { engagementActions } from '../../../../redux/public/engagements/actions';
import Main from '.';
import { MODAL_NAMES } from '../../../common/components/modal/constants';
import {
  getEngagementUid,
  getEngagementData,
} from '../../../../redux/public/engagements/selectors';

const mapStateToProps = (state: IApplicationState) => ({
  expo: getPublicExpo(state),
  engagementUid: getEngagementUid(state),
  engagementData: getEngagementData(state),
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  endDemo: () => dispatch(engagementActions.endEngagement()),
  openGettingStartedModel: (content: string) =>
    dispatch(
      modalActions.openModal(MODAL_NAMES.GETTING_STARTED, {
        content,
      }),
    ),
  openExploreDemoModal: (demo: IDemo, expoUid: string) =>
    dispatch(
      modalActions.openModal(MODAL_NAMES.EXPLORE_DEMO, {
        demo,
        expoUid,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
