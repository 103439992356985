import * as React from 'react';
import { Button } from '../../Buttons/Button';
import './styles.scss';

type DefaultProps = Readonly<typeof defaultProps>;

export type Props = {
  backgroundImageUrl: string;
  errorCode?: number;
  icon: React.ReactNode;
  message?: string;
  backButtonText?: string;
  onClick: () => void;
} & Partial<DefaultProps>;

const defaultProps = {
  backButtonText: 'Back to Menu',
  subHeading: "Looks like something's gone wrong.",
};

export const ErrorPage: React.SFC<Props> = ({
  subHeading,
  message,
  backgroundImageUrl,
  errorCode,
  onClick,
  icon,
  backButtonText,
}) => {
  return (
    <div
      className="error-page"
      style={{ backgroundImage: `url('${backgroundImageUrl}')` }}
    >
      <header className="error-page__header">
        <div className="logo-holder">
          <span className="icon-cisco" />
          {icon}
        </div>
      </header>
      <div className="error-page__content">
        <h1 className="error-page__heading">Sorry</h1>
        <h2 className="error-page__sub-heading">{subHeading}</h2>
        {errorCode && (
          <p className="error-page__message">
            <strong>{errorCode}.</strong> {message}
          </p>
        )}
        <Button color="white" onClick={onClick}>
          {backButtonText}
        </Button>
      </div>
    </div>
  );
};

ErrorPage.defaultProps = defaultProps;

export default ErrorPage;
