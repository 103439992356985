const useFeedbackSurveyManagementState = (
  updateField: (name: string, value: string | boolean | undefined) => void,
) => {
  const toggleSurvey = (e: React.FormEvent<HTMLInputElement>): void => {
    const { name, checked } = e.currentTarget;
    updateField(name, checked);
  };

  const updateURL = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    updateField(name, value);
  };

  return {
    toggleSurvey,
    updateURL,
  };
};

export default useFeedbackSurveyManagementState;
