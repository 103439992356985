import * as React from 'react';
import Footer from '../footer';
import Topbar from '../top-bar';
import './styles.scss';
import BackgroundImage from '../background-image';

interface IProps {
  children: React.ReactNode;
  hideTopBar?: boolean;
  url: string;
}

const defaultProps: Partial<IProps> = {
  hideTopBar: false,
};

export default function Layout({ url, hideTopBar, children }: IProps) {
  return (
    <>
      <div className="layout">
        <BackgroundImage src={url} key={url} />
        <div className="container layout__container">
          {!hideTopBar && <Topbar />}
          {children}
        </div>
      </div>
      <Footer />
    </>
  );
}

Layout.defaultProps = defaultProps;
