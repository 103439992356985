import { ErrorPage } from '../common/pov-common-ui';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { ROUTES } from '../common/constants';
import { imageAsset } from '../common/utilities/imageAssets';

export default function FourOhFour({
  history,
  location: { pathname },
}: RouteComponentProps): React.ReactElement<typeof ErrorPage> {
  const type = pathname === ROUTES.PAGE_NOT_FOUND_404 ? 'page' : 'resource';

  const route = ROUTES.RESOURCE_NOT_FOUND_404_USER
    ? ROUTES.ACCESS_CODE
    : ROUTES.ADMIN;

  return (
    <ErrorPage
      onClick={() => history.push(route)}
      backgroundImageUrl={imageAsset('admin/background.png')}
      message={`We can't seem to find the ${type} you're looking for.`}
      errorCode={404}
      icon={<div className="Logo__title">eXpo</div>}
    />
  );
}
