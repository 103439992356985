import * as React from 'react';
import './styles.scss';

function Logo() {
  return (
    <div className="Logo">
      <span className="icon-cisco" />
      <h1 className="Logo__title">eXpo</h1>
    </div>
  );
}

export default Logo;
