import { connect } from 'react-redux';
import Create from './index';
import {
  getFormValidity,
  getExpo,
} from '../../../redux/admin/create/selectors';
import { IApplicationState } from '../../../redux/types';
import { createActions } from '../../../redux/admin/create/actions';

const mapStateToProps = (state: IApplicationState) => ({
  formIsValid: getFormValidity(state),
  expo: getExpo(state),
});

const mapDispatchToProps = {
  onFieldUpdate: createActions.updateExpo,
  setFormValidity: createActions.setFormValidity,
  createExpo: createActions.createExpo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
