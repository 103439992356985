import { Columns } from '../../../common/pov-common-ui';
import * as React from 'react';
import Cell from '../../../common/components/table-cell';

const renderLink = (
  type: IScheduledDemoServer['type'],
  link: IScheduledDemoServer['link'],
) => {
  let linkText;
  const attributes: {
    href: string;
    target: string;
    rel: string | undefined;
    referrerPolicy?: ReferrerPolicy;
  } = {
    href: link,
    target: '_blank',
    rel: 'noopener noreferrer',
  };
  switch (type) {
    case 'HAPROXY':
      attributes['referrerPolicy'] = 'origin';
      attributes['rel'] = undefined;
      linkText = 'URL';
      break;
    case 'WEB_RDP':
      linkText = 'Remote Desktop';
      break;
    case 'SSH':
      linkText = 'SSH';
      break;
    case 'HW_CONSOLE':
      linkText = 'Hardware Console';
      break;
    default:
  }
  return linkText === undefined ? '' : <a {...attributes}>{linkText}</a>;
};

export const columns: Columns<IScheduledDemoServer> = [
  {
    Header: 'Name',
    Cell: (cellInfo) => <Cell value={cellInfo.original.name} />,
    accessor: 'name',
  },
  {
    Header: 'IP Address',
    Cell: (cellInfo) => <Cell value={cellInfo.original.ipAddress} />,
    accessor: 'ipAddress',
    minWidth: 25,
  },
  {
    Header: 'Username',
    Cell: (cellInfo) => <Cell value={cellInfo.original.username} />,
    accessor: 'username',
    minWidth: 25,
  },
  {
    Header: 'Password',
    Cell: (cellInfo) => <Cell value={cellInfo.original.password} />,
    accessor: 'password',
    minWidth: 25,
  },
  {
    Header: 'Link',
    accessor: 'link',
    Cell: (cellInfo) =>
      renderLink(cellInfo.original.type, cellInfo.original.link),
    minWidth: 25,
  },
];
