import { IApplicationState } from '../../../../redux/types';
import { getMetadataSurveyTypes } from '../../../../redux/admin/metadata/selectors';
import { connect } from 'react-redux';
import FeedbackSurveyManagement from './index';
import {
  IFeedbackSurveyManagementProps,
  IFeedbackSurveyManagementBaseProps,
} from './types';

const mapStateToProps = (
  state: IApplicationState,
  ownProps: IFeedbackSurveyManagementBaseProps,
): IFeedbackSurveyManagementProps => ({
  surveyTypes: getMetadataSurveyTypes(state),
  fieldOption: ownProps.fieldOption,
  fieldUrl: ownProps.fieldUrl,
  updateField: ownProps.updateField,
  values: ownProps.values,
});

export default connect(mapStateToProps, null)(FeedbackSurveyManagement);
