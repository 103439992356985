import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { metadataActions, FETCH_SURVEY_TYPES } from './actions';
import { getSurveyTypes } from '../../../app/entities/expos/service';
import { ISurveyType } from '../../../app/entities/expos/types';

export function* fetchMetadataSurveyTypesWorker(): SagaIterator {
  try {
    const response: ISurveyType[] = yield call(getSurveyTypes);
    yield put(metadataActions.fetchSurveyTypesSuccess(response));
  } catch (e) {
    throw e;
  }
}

export default function* metadataSurveyTypesWatcher(): SagaIterator {
  yield all([takeLatest(FETCH_SURVEY_TYPES, fetchMetadataSurveyTypesWorker)]);
}
