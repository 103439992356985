import * as React from 'react';
import classNames from 'classnames';
import {
  Checkbox,
  Dropdown,
  FormGroup,
  Label,
  TextInput,
} from '../../../common/pov-common-ui';
import {
  extensionHrsOptions,
  extensionMinsOptions,
  timeoutWarningOptions,
} from '../../../entities/expos/utilities';
import { IExpoBase } from '../../../entities/expos/types';
import useEngagementDurationState, { extensionIsMax } from './state';
import {
  EXTENSION_HRS_ID,
  EXTENSION_MINS_ID,
  END_ENGAGEMENT_MANAGEMENT_ID,
} from './constants';
import { IFormConfig } from '../../../common/hooks/useFormValidation';

export interface IEngagementDurationProps {
  isNewExpo: boolean;
  maxExtensionFormConfig: IFormConfig;
  timeoutFormConfig: IFormConfig;
  timeoutWarningFormConfig: IFormConfig;
  updateField: (name: string, value: number | boolean | undefined) => void;
  values: IExpoBase;
}

export default function EngagementDurationControls({
  isNewExpo,
  maxExtensionFormConfig,
  timeoutFormConfig,
  timeoutWarningFormConfig,
  updateField,
  values,
}: IEngagementDurationProps) {
  const {
    engagementManaged,
    engagementTimeoutInMin,
    engagementTimeoutWarningInMin,
    maxEngagementExtensionInMin,
  } = values;

  const {
    extensionHrs,
    extensionMins,
    toggleManagement,
    updateAllowExtension,
    updateExtension,
    updateTimeout,
  } = useEngagementDurationState(
    isNewExpo,
    updateField,
    maxExtensionFormConfig.name,
    maxEngagementExtensionInMin,
  );

  return (
    <div className="engagement-duration-control">
      <Checkbox
        id={END_ENGAGEMENT_MANAGEMENT_ID}
        name={END_ENGAGEMENT_MANAGEMENT_ID}
        onChange={toggleManagement}
        label="End Engagement Management"
        checked={engagementManaged}
      />
      {engagementManaged && (
        <div className="row">
          <div className="col-md-3">
            <TextInput
              {...timeoutFormConfig}
              onBlur={updateTimeout}
              onChange={updateTimeout}
              required={true}
              label="Engagement Duration"
              type="number"
              value={(engagementTimeoutInMin / 60).toString()}
            />
            <span className="text-input-unit">hours</span>
          </div>
          <div className="col-md-3">
            <FormGroup inline={true} errors={timeoutWarningFormConfig.errors}>
              <Label labelFor={timeoutWarningFormConfig.id}>
                Engagement Expiry Warning
              </Label>
              <div className="row">
                <Dropdown
                  {...timeoutWarningFormConfig}
                  onChange={updateField}
                  options={timeoutWarningOptions()}
                  selected={engagementTimeoutWarningInMin}
                />
                <span className="dropdown-input-unit">minutes</span>
              </div>
            </FormGroup>
          </div>
          <div className="col-md-3 checkbox-input">
            <Checkbox
              id="allowEngagementExtension"
              name="allowEngagementExtension"
              onChange={updateAllowExtension}
              label="Enable Engagement Extensions"
              checked={!!maxEngagementExtensionInMin}
            />
          </div>
          <div
            className={classNames('col-md-3', {
              disabled: !maxEngagementExtensionInMin,
            })}
          >
            <FormGroup inline={true} errors={maxExtensionFormConfig.errors}>
              <Label labelFor={EXTENSION_HRS_ID}>
                Maximum Engagement Extension
              </Label>
              <div className="row">
                <Dropdown
                  id={EXTENSION_HRS_ID}
                  name={EXTENSION_HRS_ID}
                  onChange={updateExtension}
                  options={extensionHrsOptions()}
                  selected={extensionHrs}
                  disabled={!maxEngagementExtensionInMin}
                />
                <span className="dropdown-input-unit">hours</span>
                <Dropdown
                  id={EXTENSION_MINS_ID}
                  name={EXTENSION_MINS_ID}
                  onChange={updateExtension}
                  options={extensionMinsOptions()}
                  selected={extensionMins}
                  disabled={
                    !maxEngagementExtensionInMin ||
                    extensionIsMax(maxEngagementExtensionInMin)
                  }
                />
                <span
                  className={classNames('dropdown-input-unit', {
                    disabled: extensionIsMax(maxEngagementExtensionInMin),
                  })}
                >
                  minutes
                </span>
              </div>
            </FormGroup>
          </div>
        </div>
      )}
    </div>
  );
}
