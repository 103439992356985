import { connect } from 'react-redux';
import Edit from '.';
import { editActions } from '../../../redux/admin/edit/actions';
import { IApplicationState } from '../../../redux/types';
import {
  getDisableSave,
  getDisablePublish,
  getTempExpo,
} from '../../../redux/admin/edit/selectors';

const mapStateToProps = (state: IApplicationState) => ({
  expo: getTempExpo(state),
  disablePublish: getDisablePublish(state),
  disableSave: getDisableSave(state),
});

const mapDispatchToProps = {
  fetchExpo: editActions.fetchExpo,
  updateGeneralFormValidity: editActions.updateGeneralFormValidity,
  saveExpo: editActions.saveExpo,
  onFieldUpdate: editActions.onFieldUpdate,
  reset: editActions.reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
