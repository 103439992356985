import { combineReducers } from 'redux';
import csvReducer, { ICsvContentState } from './csv';
import etagReducer from './etag';
import expoReducer, { EditExpoState } from './expo';
import tempExpoReducer, { EditTempExpoState } from './temp-expo';
import capacityAvailableReducer, {
  ICapacityAvailableState,
} from './capacity-available';
import generalFormValidReducer from './general-form-valid';

export interface IEditState {
  capacity: ICapacityAvailableState;
  csv: ICsvContentState;
  etag: string;
  expo: EditExpoState;
  generalFormValid: boolean;
  tempExpo: EditTempExpoState;
}

export default combineReducers<IEditState>({
  capacity: capacityAvailableReducer,
  csv: csvReducer,
  etag: etagReducer,
  expo: expoReducer,
  generalFormValid: generalFormValidReducer,
  tempExpo: tempExpoReducer,
});
