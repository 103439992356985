import * as React from 'react';
import './styles.scss';

interface IProps {
  children: React.ReactNode;
}

const CardContent: React.SFC<IProps> = ({ children }) => (
  <div className="card__content">{children}</div>
);

export default CardContent;
