import { Button } from '../../../pov-common-ui';
import * as React from 'react';
import ModalInner from '../modal-inner';

export interface IProps {
  closeModal: () => void;
}

export default function ExpoChanged({ closeModal }: IProps) {
  return (
    <ModalInner
      title="Another user has made changes to this Expo"
      content="Another user has saved changes to this expo, please update to the latest Expo state before saving."
      buttons={
        <Button onClick={closeModal} color="secondary">
          OK
        </Button>
      }
    />
  );
}
