export enum CiscoButtonSizes {
  DEFAULT = 'default',
  SMALL = 'small',
  LARGE = 'large',
  WIDE = 'wide',
}

export enum CiscoButtonColours {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  NEGATIVE = 'negative',
  SUCCESS = 'success',
  GRAY_GHOST = 'gray-ghost',
  WHITE = 'white',
  WHITE_GHOST = 'white-ghost',
}

export enum KeyCodes {
  ENTER = 13,
  UP = 38,
  DOWN = 40,
  ESCAPE = 27,
}
