import { connect } from 'react-redux';
import { engagementActions } from '../../../redux/public/engagements/actions';
import { expoActions } from '../../../redux/public/expos/actions';
import { IApplicationState } from '../../../redux/types';
import { getPublicExpo } from '../../../redux/public/expos/selectors';
import Expo from '.';

import {
  getEngagementUid,
  getEngagementData,
} from '../../../redux/public/engagements/selectors';

const mapStateToProps = (state: IApplicationState) => ({
  engagementUid: getEngagementUid(state),
  engagementData: getEngagementData(state),
  expo: getPublicExpo(state),
});

const mapDispatchToProps = {
  fetchEngagement: engagementActions.fetchEngagement,
  fetchExpo: expoActions.fetchExpo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Expo);
