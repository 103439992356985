import { push } from 'connected-react-router';
import moment from 'moment';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ROUTES } from '../../../../../app/common/constants';
import { ToastSeverity } from '../../../../../app/common/pov-common-ui';
import {
  getSingleExpo,
  updateExpo,
} from '../../../../../app/entities/expos/service';
import { IReturnedPayload } from '../../../../../app/entities/expos/types';
import i18n from '../../../../../i18n';
import { IActionWithPayload } from '../../../../action-helpers';
import { modalActions } from '../../../../modals/actions';
import { toastActions } from '../../../../toast/actions';
import { END_EXPO } from '../../actions';

export default function* endExpoWorker(
  action: IActionWithPayload<typeof END_EXPO, string>,
): SagaIterator {
  try {
    yield put(modalActions.modalLoading(true));
    const { data: payload, headers }: IReturnedPayload = yield call(
      getSingleExpo,
      action.payload,
    );
    const { name } = payload;
    payload.end = moment().toISOString();
    payload.noOfSeats = payload.noOfSeats.toString();

    yield call(updateExpo, payload, headers.etag);
    yield put(push(ROUTES.ADMIN));
    yield put(
      toastActions.showToast(
        i18n.t('components.modal.endExpo.messages.success.title'),
        i18n.t('components.modal.endExpo.messages.success.content', {
          expoName: name,
        }),
        ToastSeverity.SUCCESS,
      ),
    );
  } catch (error) {
    yield put(
      toastActions.showToast(
        i18n.t('components.modal.endExpo.messages.error.title'),
        error.response.data[0].message,
        ToastSeverity.DANGER,
      ),
    );
  } finally {
    yield put(modalActions.closeModal());
    yield put(modalActions.modalLoading(false));
  }
}
