import * as React from 'react';
import { sanitize } from 'dompurify';
import MarkdownIt from 'markdown-it';

interface IProps {
  copy: string;
}

export default function MarkdownFormattedCopy({ copy }: IProps) {
  const markdownRenderer = MarkdownIt('zero').enable('emphasis');

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: sanitize(markdownRenderer.render(copy)),
      }}
    />
  );
}
