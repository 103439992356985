export const FLICKER_DELAY = 150;
export const BASE_URL = process.env.REACT_APP_PATH ?? '';

export enum ROUTES {
  ACCESS_CODE = '/',
  ADMIN = '/admin',
  ADMIN_CREATE = '/admin/expo/create',
  ADMIN_EDIT = '/admin/expo/:id/edit',
  ADMIN_PREVIEW = '/admin/expo/:id/preview',
  ADMIN_VIEW_SESSION_INFO_CONTENT = '/admin/expo/:id/info',
  ADMIN_VIEW_SESSION_INFO_ENGAGEMENTS = '/admin/expo/:id/info/users',
  ADMIN_VIEW_SESSION_INFO_REBALANCE = '/admin/expo/:id/info/rebalance',
  EXPO = '/:id',
  IDP_CALLBACK = '/authenticate',
  INSTANT_DEMO = '/:id/instant-demo',
  SCHEDULED_DEMO = '/:id/scheduled-demo',
  SCHEDULED_DEMO_DETAILS = '/:id/scheduled-demo/details',
  SCHEDULED_DEMO_RESOURCES = '/:id/scheduled-demo/resources',
  SCHEDULED_DEMO_DETAILS_PHONE_NUMBERS = '/:id/scheduled-demo/details/phone-numbers',
  SCHEDULED_DEMO_DETAILS_IP_ADDRESS = '/:id/scheduled-demo/details/ip-addresses',
  SCHEDULED_DEMO_DETAILS_DNS_ADDRESS = '/:id/scheduled-demo/details/dns-addresses',
  SCHEDULED_DEMO_DETAILS_DEVICES = '/:id/scheduled-demo/details/devices',
  PAGE_NOT_FOUND_404 = '/page-not-found',
  RESOURCE_NOT_FOUND_404 = '/resource-not-found',
  RESOURCE_NOT_FOUND_404_USER = '/resource-not-found-user',
  FORBIDDEN_403 = '/forbidden',
}

export enum LOCAL_STORAGE_KEYS {
  EXPO_UID = 'expo-uid',
  ENGAGEMENT_ID = 'engagement-id',
}

export const DATA_CENTERS = ['RTP', 'SJC', 'SNG', 'SYD', 'LON'];

export enum DEMO_TYPES {
  INSTANT = 'INSTANT',
  SCHEDULED = 'SCHEDULED',
}

export enum BG_OVERRIDES {
  CISCO_LIVE = 'cisco-live-override',
  CISCO_GSX = 'cisco-gsx-override',
}
