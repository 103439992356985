import { ErrorPage } from '../common/pov-common-ui';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { ROUTES } from '../common/constants';
import { MAIN_BACKGROUND_IMAGE } from '../common/utilities/imageAssets';

export default function FourOhThree({
  history,
}: RouteComponentProps): React.ReactElement<typeof ErrorPage> {
  return (
    <ErrorPage
      onClick={() => history.push(ROUTES.ACCESS_CODE)}
      backgroundImageUrl={MAIN_BACKGROUND_IMAGE}
      subHeading="You don't have access to eXpo Admin."
      icon={<span className="Logo__title">eXpo</span>}
      backButtonText="Back to eXpo"
    />
  );
}
