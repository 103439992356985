import { Button, Checkbox, TextInput } from '../../../../pov-common-ui';
import * as React from 'react';
import {
  emailValidation,
  requireCheckedBox,
} from '../../../../form/validation';
import useFormValidation, {
  Validation,
} from '../../../../hooks/useFormValidation';
import './styles.scss';
import TermsLabel from './terms-label';

export type InitialValues = Readonly<typeof initialValues>;

interface IProps {
  onButtonClick: (values: IStartEngagementPayload) => void;
}

const initialValues = {
  email: '',
  termsAndConditionsAccepted: false,
};

export const validation: Validation<InitialValues> = {
  email: [emailValidation('Please enter a valid email address')],
  termsAndConditionsAccepted: [requireCheckedBox('Please accept terms')],
};

export default function ExploreDemoForm({ onButtonClick }: IProps) {
  const { form, updateField, valid, values } = useFormValidation(
    initialValues,
    validation,
  );

  const onSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      onButtonClick(values);
    },
    [values, onButtonClick],
  );

  const onCheck = React.useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      updateField('termsAndConditionsAccepted', e.currentTarget.checked);
    },
    [updateField],
  );

  return (
    <div className="explore-demo-form">
      <form onSubmit={onSubmit}>
        <div className="explore-demo-form__content">
          <TextInput
            {...form.email}
            label="To continue please enter your email address"
          />
          <Checkbox
            {...form.termsAndConditionsAccepted}
            label={<TermsLabel />}
            value=""
            onChange={onCheck}
            checked={form.termsAndConditionsAccepted.value}
          />
        </div>
        <Button
          id="launch-button"
          disabled={!valid}
          color="primary"
          type="submit"
        >
          Continue
        </Button>
      </form>
    </div>
  );
}
