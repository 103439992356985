import { connect } from 'react-redux';
import { Authenticate } from '.';

import { swapAccessCode } from '../../redux/authenticated/actions';

const mapDispatchToProps = {
  swapAccessCode,
};

export const AuthenticateContainer = connect(
  null,
  mapDispatchToProps,
)(Authenticate);
