import * as React from 'react';
import { Card } from '../../../common/pov-common-ui';
import { LinkButton } from '../../../common/components/link-button';
import Logo from '../../../common/components/logo';
import { ROUTES } from '../../../common/constants';
import { WHY_EXPO_TABLE_DATA } from '../data';
import './styles.scss';

export default function WhyExpo() {
  return (
    <div className="row why-expo dbl-margin-bottom">
      <Logo />
      <h1 className="display-4">What are you waiting for?</h1>

      <Card className="why-expo__card">
        <p>
          eXpo in a new tool in the dCloud product family that allows you to
          build customised demo portals for your events.
        </p>

        <table className="table table--loose">
          <tbody>
            {WHY_EXPO_TABLE_DATA.map((data) => (
              <tr key={data}>
                <td>
                  <div>{data}</div>
                  <span className="icon-check" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <LinkButton color="primary" to={ROUTES.ADMIN_CREATE}>
          Create an expo
        </LinkButton>
      </Card>
    </div>
  );
}
