import { Button, IconButton } from '../../../pov-common-ui';
import * as React from 'react';
import './styles.scss';
import { copyTextToClipboard } from '../../../utilities/helpers';

export interface IProps {
  closeModal: () => void;
  accessCode: number;
  minutesRemaining: number;
}

export const minutesRemainingText = (minutesRemaining: number) =>
  `This code is valid for ${minutesRemaining} minute${
    minutesRemaining > 1 ? 's' : ''
  }`;

export default function AccessCode({
  accessCode,
  minutesRemaining,
  closeModal,
}: IProps) {
  return (
    <div className="access-code-modal">
      <h1 className="display-3">Access Code</h1>
      <p className="access-code-modal__time">
        {minutesRemainingText(minutesRemaining)}
      </p>
      <h3 className="base-margin-bottom access-code-modal__code">
        {accessCode}
        <IconButton
          icon="icon-recent-apps"
          color="primary"
          className="access-code-modal__copy-link"
          onClick={() => copyTextToClipboard(`${accessCode}`)}
        />
      </h3>
      <Button color="primary" id="close-access-code" onClick={closeModal}>
        Close
      </Button>
    </div>
  );
}
