import * as React from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../../../common/constants';
import { anyConnectColumns } from './columns/anyconnect-columns';
import { phoneNumberColumns } from './columns/phone-number-columns';
import { ipAddressColumns } from './columns/ip-address-columns';
import { dnsColumns } from './columns/dns-columns';
import { deviceColumns } from './columns/devices-columns';
import { DataGrid } from '../../../../common/pov-common-ui';
import './styles.scss';

export interface IProps {
  details: ISessionDetails;
  uid: string;
}

export default function DetailsPanel({ details, uid }: IProps) {
  return (
    <>
      <ul role="tablist" className="tabs session-tab-pills">
        {details.anyConnects.length > 0 && (
          <li>
            <NavLink
              exact={true}
              to={ROUTES.SCHEDULED_DEMO_DETAILS.replace(':id', uid)}
              activeClassName="active"
            >
              Anyconnect
            </NavLink>
          </li>
        )}
        {details.ipAddresses.length > 0 && (
          <li>
            <NavLink
              exact={true}
              to={ROUTES.SCHEDULED_DEMO_DETAILS_IP_ADDRESS.replace(':id', uid)}
              activeClassName="active"
            >
              Public IP Address
            </NavLink>
          </li>
        )}
        {details.dnsAddresses.length > 0 && (
          <li>
            <NavLink
              exact={true}
              to={ROUTES.SCHEDULED_DEMO_DETAILS_DNS_ADDRESS.replace(':id', uid)}
              activeClassName="active"
            >
              DNS Address
            </NavLink>
          </li>
        )}
        {details.phoneNumbers.length > 0 && (
          <li>
            <NavLink
              exact={true}
              to={ROUTES.SCHEDULED_DEMO_DETAILS_PHONE_NUMBERS.replace(
                ':id',
                uid,
              )}
              activeClassName="active"
            >
              Phone Numbers
            </NavLink>
          </li>
        )}

        {details.devices.length > 0 && (
          <li>
            <NavLink
              exact={true}
              to={ROUTES.SCHEDULED_DEMO_DETAILS_DEVICES.replace(':id', uid)}
              activeClassName="active"
            >
              Devices
            </NavLink>
          </li>
        )}
      </ul>

      <Switch>
        {details.anyConnects.length > 0 && (
          <Route
            exact={true}
            path={ROUTES.SCHEDULED_DEMO_DETAILS.replace(':id', uid)}
            component={() => (
              <div className="session-detail__resources">
                <h3 className="session-detail-heading">
                  AnyConnect Credentials
                </h3>
                <DataGrid
                  data={details.anyConnects}
                  columns={anyConnectColumns}
                  sortable={true}
                  minRows={1}
                  pageSize={500}
                />
              </div>
            )}
          />
        )}
        {details.phoneNumbers.length > 0 && (
          <Route
            exact={true}
            path={ROUTES.SCHEDULED_DEMO_DETAILS_PHONE_NUMBERS.replace(
              ':id',
              uid,
            )}
            component={() => (
              <div className="session-detail__resources">
                <h3 className="session-detail-heading">Phone Numbers</h3>
                <DataGrid
                  data={details.phoneNumbers}
                  columns={phoneNumberColumns}
                  sortable={true}
                  minRows={1}
                  pageSize={500}
                />
              </div>
            )}
          />
        )}
        {details.ipAddresses.length > 0 && (
          <Route
            exact={true}
            path={ROUTES.SCHEDULED_DEMO_DETAILS_IP_ADDRESS.replace(':id', uid)}
            component={() => (
              <div className="session-detail__resources">
                <h3 className="session-detail-heading">Public IP Addresses</h3>
                <DataGrid
                  data={details.ipAddresses}
                  columns={ipAddressColumns}
                  sortable={true}
                  minRows={1}
                  pageSize={500}
                />
              </div>
            )}
          />
        )}
        {details.dnsAddresses.length > 0 && (
          <Route
            exact={true}
            path={ROUTES.SCHEDULED_DEMO_DETAILS_DNS_ADDRESS.replace(':id', uid)}
            component={() => (
              <div className="session-detail__resources">
                <h3 className="session-detail-heading">DNS Addresses</h3>
                <DataGrid
                  data={details.dnsAddresses}
                  columns={dnsColumns}
                  sortable={true}
                  minRows={1}
                  pageSize={500}
                />
              </div>
            )}
          />
        )}
        {details.devices.length > 0 && (
          <Route
            exact={true}
            path={ROUTES.SCHEDULED_DEMO_DETAILS_DEVICES.replace(':id', uid)}
            component={() => (
              <div className="session-detail__resources">
                <h3 className="session-detail-heading">Devices</h3>
                <DataGrid
                  data={details.devices}
                  columns={deviceColumns}
                  sortable={true}
                  minRows={1}
                  pageSize={500}
                />
              </div>
            )}
          />
        )}
      </Switch>
    </>
  );
}
