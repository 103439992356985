import * as React from 'react';
import { Loader } from '../../Loader';
import { KeyCodes } from '../../constants';
import './styles.scss';

export interface IModalProps {
  loading?: boolean;
  size?: 'small' | 'large';
  isOpen: boolean;
  closeModal(): void;
}

export interface IInjectedModalProps {
  closeModal(): void;
}

export const withModal = <P extends IInjectedModalProps>(
  WrappedComponent: React.ComponentType<P>,
) => {
  return class WithModal extends React.PureComponent<
    OmitAndSubtract<P, IInjectedModalProps> & IModalProps
  > {
    public static defaultProps: Partial<IModalProps> = {
      loading: false,
      size: 'small',
    };

    public componentDidUpdate(prevProps: IModalProps) {
      if (!prevProps.isOpen && this.props.isOpen) {
        document.addEventListener('keydown', this.closeOnEscape);
      }

      if (prevProps.isOpen && !this.props.isOpen) {
        document.removeEventListener('keydown', this.closeOnEscape);
      }
    }

    // tslint:disable-next-line:no-any
    public closeOnEscape = (evt: any) => {
      if (evt.keyCode === KeyCodes.ESCAPE) {
        this.props.closeModal();
      }
    };

    public render() {
      // tslint:disable-next-line:no-any
      const { closeModal, loading, isOpen, size, ...props } = this.props as any;

      if (!isOpen) return null;

      return (
        <React.Fragment>
          <div className="modal-backdrop" />
          <div className="cui--animated">
            <div className={`modal modal--${size}`}>
              <div className="modal__dialog">
                {loading && (
                  <div className="modal__loading">
                    <Loader />
                  </div>
                )}
                <div className="modal__content">
                  <button
                    className="modal__close"
                    title="Close"
                    onClick={closeModal}
                  >
                    <span className="icon-close" />
                  </button>
                  <WrappedComponent {...props} closeModal={closeModal} />
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  };
};
