import * as React from 'react';
import { connect } from 'react-redux';
import { FullScreenLoader } from '../loader';
import { IApplicationState } from '../../../../redux/types';
import { getShowHideLoader } from '../../../../redux/ui/loader/selectors';

interface IProps {
  show: boolean;
}

export function GlobalLoader({ show }: IProps) {
  if (!show) return null;

  return (
    <div className="global-loader">
      <FullScreenLoader />
    </div>
  );
}

const mapStateToProps = (state: IApplicationState) => ({
  show: getShowHideLoader(state),
});

export default connect(mapStateToProps)(GlobalLoader);
