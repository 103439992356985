import { call, put } from 'redux-saga/effects';
import { IActionWithPayload } from '../../../../action-helpers';
import { infoActions, GET_EXPO_ENGAGEMENTS } from '../../actions';
import { getSingleExpoEngagements } from '../../../../../app/entities/expos/service';
import { SagaIterator } from 'redux-saga';

type Action = IActionWithPayload<typeof GET_EXPO_ENGAGEMENTS, string>;

export default function* getExpoEngagementsWorker(
  action: Action,
): SagaIterator {
  yield put(infoActions.showLoader());

  try {
    const response = yield call(getSingleExpoEngagements, action.payload);
    yield put(infoActions.getExpoEngagementsSuccess(response));
  } catch (e) {
    throw e;
  } finally {
    yield put(infoActions.hideLoader());
  }
}
