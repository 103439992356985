import { Button } from '../../../common/pov-common-ui';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import CategoryCard from '../../../common/components/category-card';
import Layout from '../../../common/components/layout';
import NavBar from '../../../common/components/nav-bar';
import { IExpoWithCategories } from '../../../entities/expos/types';
import './styles.scss';

export interface IProps {
  endDemo: () => void;
  engagementData?: IDemoEngagement;
  expo: IExpoWithCategories | null;
  openResourcesModal: (demo: IInstantDemo) => void;
}

export default function InstantDemo({
  endDemo,
  engagementData,
  expo,
  openResourcesModal,
}: IProps) {
  if (!engagementData) {
    return <Redirect to={ROUTES.EXPO.replace(':id', expo!.uid)} />;
  }

  const demo = engagementData.demo as IInstantDemo;

  return (
    <div className="u-with-sticky-header">
      <div className="instant-demo-screen">
        <NavBar
          right={
            <Button color="primary" id="end-demo-engagement" onClick={endDemo}>
              Logout and End Session
            </Button>
          }
        />
        <Layout url={expo!.backgroundImage}>
          <CategoryCard title={demo.name}>
            <a
              id="open-demo-btn"
              className="open-demo-btn btn btn--default btn--secondary"
              href={demo.launchUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Launch
            </a>
            <Button
              id="demo-resources-btn"
              color="secondary"
              onClick={() => openResourcesModal(demo)}
            >
              Resources
            </Button>
          </CategoryCard>
        </Layout>
      </div>
    </div>
  );
}
