import { createAction } from '../../../action-helpers';
import { ActionsUnion } from '../../../action-types';

export const SEND_ACCESS_CODE = 'PUBLIC/LANDING_PAGE/SEND_ACCESS_CODE';
export const SEND_ACCESS_CODE_SUCCESS =
  'PUBLIC/LANDING_PAGE/SEND_ACCESS_CODE_SUCCESS';
export const SEND_ACCESS_CODE_ERROR =
  'PUBLIC/LANDING_PAGE/SEND_ACCESS_CODE_ERROR';

export const sendAccessCode = (accessCode: string) =>
  createAction(SEND_ACCESS_CODE, accessCode);

export const sendAccessCodeErrors = () => createAction(SEND_ACCESS_CODE_ERROR);

export const sendAccessCodeSuccess = () =>
  createAction(SEND_ACCESS_CODE_SUCCESS);

export const landingPageActions = {
  sendAccessCode,
  sendAccessCodeErrors,
  sendAccessCodeSuccess,
};

export type Actions = ActionsUnion<typeof landingPageActions>;
