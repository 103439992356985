import { Columns, IColumn } from '../../../../../common/pov-common-ui';
import * as React from 'react';
import Cell from '../../../../../common/components/table-cell';
import { orderColumnByMap } from '../../../utilities';

const detailColumns = (data: ICapacityDetail[]): Columns<ICapacityDetail> =>
  orderColumnByMap(
    [
      'RESOURCE',
      'TYPE',
      'PER_SESSION',
      'TOTAL',
      'DC_REQUESTED',
      'DC_AVAILABLE',
    ],
    Object.keys(data[0]).map(
      (key) =>
        ({
          Header: key.replace('_', ' '),
          Cell: (cellInfo) => <Cell {...cellInfo.original[key]} />,
          accessor: `${key}.value`,
          minWidth: key === 'RESOURCE' ? 100 : 50,
        } as IColumn<ICapacityDetail>),
    ),
  );

export default detailColumns;
