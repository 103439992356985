import { Button } from '../../../pov-common-ui';
import * as React from 'react';
import ModalInner from '../modal-inner';

export interface IProps {
  closeModal: () => void;
  unpublishExpo: (uid: string) => void;
  uid: string;
}

function UnpublishExpo({ unpublishExpo, closeModal, uid }: IProps) {
  return (
    <ModalInner
      title="Unpublish"
      content="Are you sure you want to unpublish this eXpo? All demo's will need to be rescheduled once unpublished."
      buttons={
        <>
          <Button
            onClick={() => unpublishExpo(uid)}
            color="primary"
            id={`unpublish-modal-unpublish-btn-${uid}`}
          >
            Unpublish
          </Button>
          <Button
            onClick={closeModal}
            color="secondary"
            id={`unpublish-modal-cancel-btn-${uid}`}
          >
            Cancel
          </Button>
        </>
      }
    />
  );
}

export default UnpublishExpo;
