import * as React from 'react';

export default function TermsLabel() {
  return (
    <>
      I agree to the{' '}
      <a
        href="https://dcloud-docs.cisco.com/c/r/dcloud-docs/sites/en_us/dcloud-help/using-dcloud/cisco-dcloud-user-agreement.html"
        rel="noopener noreferrer"
        target="_blank"
      >
        terms &amp; conditions
      </a>
    </>
  );
}
