import * as React from 'react';
import { Button } from '../../../../common/pov-common-ui';
import { LinkButton } from '../../../../common/components/link-button';
import { useTranslation } from 'react-i18next';

export interface IProps {
  onAccessCodeClick: (uid: string) => void;
  onUnpublishButtonClick: (uid: string) => void;
  showAccessCodeBtn: boolean;
  showManageButton: boolean;
  showEditButton: boolean;
  showUnpublishButton: boolean;
  uid: string;
}

export function CardButtons({
  onAccessCodeClick,
  onUnpublishButtonClick,
  showAccessCodeBtn,
  showManageButton,
  showEditButton,
  showUnpublishButton,
  uid,
}: IProps) {
  const { t } = useTranslation();
  return (
    <>
      {showEditButton && (
        <LinkButton
          color="primary"
          id={`admin_expo_${uid}_edit`}
          to={`/admin/expo/${uid}/edit`}
        >
          {t('cardButtons.edit')}
        </LinkButton>
      )}
      {showUnpublishButton && (
        <Button
          id={`admin_expo_${uid}_unpublish`}
          color="primary"
          onClick={() => onUnpublishButtonClick(uid)}
        >
          {t('cardButtons.unpublish')}
        </Button>
      )}
      {showManageButton && (
        <LinkButton
          color="primary"
          id={`admin_expo_${uid}_manage`}
          to={`/admin/expo/${uid}/info`}
        >
          {t('cardButtons.manage')}
        </LinkButton>
      )}
      {showAccessCodeBtn && (
        <Button
          id={`admin_expo_${uid}_access_code`}
          color="secondary"
          onClick={() => onAccessCodeClick(uid)}
        >
          {t('cardButtons.accessCode')}
        </Button>
      )}
    </>
  );
}
