export enum ExpoDateFilters {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum ExpoStates {
  ALL = 'ALL',
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export enum ExpoStatesText {
  ALL = 'All',
  COMPLETE = 'Complete',
  IN_PROGRESS = 'In Progress',
  PUBLISHED = 'Published',
  UNPUBLISHED = 'Unpublished',
}

export enum EngagementTimeoutValues {
  DEFAULT_HOURS = 8,
  MAX_HOURS = 120,
  MIN_HOURS = 1,
  EXTENSION_MAX_MINS = 4 * 60,
  EXTENSION_MIN_MINS = 60,
  WARNING_MAX_MINS = 60,
  WARNING_MIN_MINS = 15,
}

export enum ExpoEventTypes {
  ciscoGsx = 'Cisco GSX',
  ciscoLive = 'Cisco Live',
  dcloudCafe = 'dCloud Cafe',
  sevt = 'SEVT',
  testDrive = 'Test Drive',
  webexOne = 'WebexOne',
}

export enum ExpoSurveyTypes {
  COMPLETE_MOU = 'Complete MOU',
}

const ExpoPaths = {};
let expoPathsSet = false;

export const ExpoEventPaths = (type: string) => {
  if (expoPathsSet) {
    return ExpoPaths[type];
  }

  Object.keys(ExpoEventTypes).forEach((key) => {
    const formattedKey = ExpoEventTypes[key].toLowerCase().replace(' ', '-');
    ExpoPaths[formattedKey] = formattedKey;
  });

  expoPathsSet = true;

  return ExpoEventPaths[type];
};
