import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import FourOhThree from './403';
import FourOhFour from './404';
import { AuthenticateContainer } from './Authenticate/container';
import { ROUTES } from './common/constants';
import LandingPage from './public/landing-page/container';
import Expo from './public/expo/container';
import ConnectedAdmin from './admin/container';
import AuthenticatedGuard from '../auth/components';

export default function Router() {
  return (
    <Switch>
      <Route
        path={ROUTES.IDP_CALLBACK}
        exact={true}
        render={() => <AuthenticateContainer />}
      />
      <Route
        path={ROUTES.ADMIN}
        render={() => (
          <AuthenticatedGuard>
            <ConnectedAdmin />
          </AuthenticatedGuard>
        )}
      />
      <Route path={ROUTES.ACCESS_CODE} component={LandingPage} exact={true} />
      <Route
        path={ROUTES.PAGE_NOT_FOUND_404}
        component={FourOhFour}
        exact={true}
      />
      <Route
        path={ROUTES.RESOURCE_NOT_FOUND_404}
        component={FourOhFour}
        exact={true}
      />
      <Route
        path={ROUTES.RESOURCE_NOT_FOUND_404_USER}
        component={FourOhFour}
        exact={true}
      />
      <Route path={ROUTES.FORBIDDEN_403} component={FourOhThree} exact={true} />
      <Route path={ROUTES.EXPO} component={Expo} />
      <Redirect to={ROUTES.PAGE_NOT_FOUND_404} />
    </Switch>
  );
}
