import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { storageDelete } from '../../../../../app/common/storage';
import {
  LOCAL_STORAGE_KEYS,
  ROUTES,
} from '../../../../../app/common/constants';
import { endExpoDemoEngagement } from '../../../../../app/entities/expos/service';
import { getEngagementData } from '../../selectors';
import { engagementActions } from '../../actions';
import { globalUiActions } from '../../../../ui/loader/actions';
import { getPublicExpo } from '../../../expos/selectors';
import { IExpoWithCategories } from '../../../../../app/entities/expos/types';

export function* endDemoEngagementWorker(): SagaIterator {
  try {
    const engagement: IDemoEngagement = yield select(getEngagementData);
    const expo: IExpoWithCategories = yield select(getPublicExpo);
    yield put(globalUiActions.showLoader());
    yield call(storageDelete, LOCAL_STORAGE_KEYS.ENGAGEMENT_ID);
    yield call(storageDelete, LOCAL_STORAGE_KEYS.EXPO_UID);
    yield call(endExpoDemoEngagement, engagement.uid);
    yield put(engagementActions.endEngagementSuccess());
    yield put(push(ROUTES.EXPO.replace(':id', expo.uid)));
  } catch (e) {
    if (e.response.status === 404) {
      yield put(push(ROUTES.PAGE_NOT_FOUND_404));
    }
  } finally {
    yield put(globalUiActions.hideLoader());
  }
}
