import * as React from 'react';
import Tab from '../Tab/Tab';

export interface IProps {
  items: React.ReactNode;
  active: number;
}

class TabPanels extends React.Component<IProps> {
  public render(): React.ReactNode {
    const { items, active } = this.props;
    return (
      <div className="tab-content">
        {React.Children.map(
          items,
          // tslint:disable-next-line:no-any
          (child: any, index: number): React.ReactNode =>
            child ? (
              <Tab active={index === active} title={child.props.title}>
                {child.props.children}
              </Tab>
            ) : null,
        )}
      </div>
    );
  }
}

export default TabPanels;
