import { SagaIterator } from 'redux-saga';
import { call, delay, put } from 'redux-saga/effects';
import { FLICKER_DELAY } from '../../../../../app/common/constants';
import { modalActions } from '../../../../modals/actions';
import { getAccessCode } from '../../../../../app/entities/expos/service';
import { IAccessCode } from '../../../../../app/entities/expos/types';
import { exposActions, FETCH_ACCESS_CODE } from '../../actions';
import { IActionWithPayload } from '../../../../action-helpers';
import { MODAL_NAMES } from '../../../../../app/common/components/modal/constants';

export default function* fetchAccessCodeWorker(
  action: IActionWithPayload<typeof FETCH_ACCESS_CODE, string>,
): SagaIterator {
  try {
    const response: IAccessCode = yield call(getAccessCode, action.payload);
    yield delay(FLICKER_DELAY);
    yield put(exposActions.fetchAccessCodeSuccess(response));
    const { accessCode, minutesRemaining } = response;
    yield put(
      modalActions.openModal(MODAL_NAMES.ACCESS_CODE, {
        accessCode: accessCode,
        minutesRemaining: minutesRemaining,
      }),
    );
  } catch (e) {
    throw e;
  }
}
