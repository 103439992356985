import classnames from 'classnames';
import * as React from 'react';

export interface IProps {
  name: string;
  id: string;
  disabled?: boolean;
  label: string | React.ReactNode;
  checked?: boolean;
  required?: boolean;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  value?: string;
}

const defaultProps = {
  checked: false,
  required: false,
  value: '',
};

const Checkbox: React.SFC<IProps> = ({
  checked,
  disabled,
  id,
  label,
  name,
  onChange,
  required,
  value,
}) => {
  const classes = classnames('checkbox', {
    disabled,
  });

  return (
    <label className={classes}>
      <input
        aria-labelledby={id}
        type="checkbox"
        checked={checked}
        name={name}
        onChange={onChange}
        disabled={disabled}
        value={value}
      />
      <span
        className="checkbox__input"
        role="checkbox"
        aria-checked={checked}
      />
      <span className="checkbox__label hidden-xs" id={id}>
        {label}
        {required && <sup>*</sup>}
      </span>
    </label>
  );
};

Checkbox.defaultProps = defaultProps;

export default Checkbox;
