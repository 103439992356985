import classNames from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

type TColors = 'primary' | 'secondary' | 'white' | 'white-ghost';

export interface IProps {
  children: string;
  color?: TColors;
  id: string;
  isDefault?: boolean;
  to: string;
  onClick?: () => void;
  target?: '_blank' | '_self' | '_top' | '_parent';
}

const defaultProps: Partial<IProps> = {
  color: 'primary',
  isDefault: false,
  onClick: () => void 0,
};

export function LinkButton({
  children,
  color,
  id,
  isDefault,
  to,
  onClick,
  target,
}: IProps) {
  const classes = classNames('btn', `btn--${color}`, {
    'btn--default': isDefault,
  });

  if (target) {
    return (
      <a className={classes} href={to} id={id} target={target}>
        {children}
      </a>
    );
  }

  return (
    <Link className={classes} id={id} to={to} onClick={onClick}>
      {children}
    </Link>
  );
}

LinkButton.defaultProps = defaultProps;
