import { createAction } from '../../../action-helpers';
import { ActionsUnion } from '../../../action-types';

export const SHOW_LOADER = 'UI/LOADER/SHOW';
export const HIDE_LOADER = 'UI/LOADER/HIDE';

const showLoader = () => createAction(SHOW_LOADER);
const hideLoader = () => createAction(HIDE_LOADER);

export const globalUiActions = {
  showLoader,
  hideLoader,
};

export type Actions = ActionsUnion<typeof globalUiActions>;
