import { Button } from '../../../pov-common-ui';
import * as React from 'react';
import ModalInner from '../modal-inner';

export interface IProps {
  errors: IFieldError[];
  title?: string;
  closeModal: () => void;
}

function GenericError({ closeModal, errors, title }: IProps) {
  const generateErrors = () => {
    if (errors.length) {
      return errors.map((error) => (
        <div key={error.logref} className="error">
          {error.message}
          {error.logref && `- ref:${error.logref}`}
        </div>
      ));
    }
    return <div className="error">Something has gone wrong.</div>;
  };

  return (
    <div className="generic-error-modal">
      <ModalInner
        title={title ? title : 'Error'}
        content={<div className="error-container">{generateErrors()}</div>}
        buttons={
          <Button
            onClick={closeModal}
            id="generic-error-modal_ok"
            color="primary"
          >
            OK
          </Button>
        }
      />
    </div>
  );
}

export default GenericError;
