import * as React from 'react';
import { connect } from 'react-redux';
import ExploreDemoForm from './explore-demo-form';
import { engagementActions } from '../../../../../redux/public/engagements/actions';
import './styles.scss';

interface IProps {
  demo: IDemo;
  expoUid: string;
  startEngagement: typeof engagementActions.createEngagement;
}

export function ExploreDemo({ demo, expoUid, startEngagement }: IProps) {
  return (
    <div className="explore-demo">
      <div className="modal__header">
        <h1 className="modal__title">{demo.name}</h1>
      </div>
      <div
        className="modal__body"
        dangerouslySetInnerHTML={{ __html: demo.description }}
      />
      <ExploreDemoForm
        onButtonClick={(values: IStartEngagementPayload) =>
          startEngagement(demo, expoUid, values)
        }
      />
    </div>
  );
}

const mapDispatchToProps = {
  startEngagement: engagementActions.createEngagement,
};

export default connect(null, mapDispatchToProps)(ExploreDemo);
