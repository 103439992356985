import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import history from '../history';
import rootReducer from './reducers';
import sagas from './sagas';
import { IApplicationState } from './types';
import { configureKapuaAxiosInterceptors } from '../api/kapua';

const sagaMiddleware = createSagaMiddleware();
const enhancers = [routerMiddleware(history), sagaMiddleware];
const composeEnhancers = composeWithDevTools({});
const composedEnhancers = composeEnhancers(applyMiddleware(...enhancers));

const store: Store<IApplicationState> = createStore(
  rootReducer(history),
  {},
  composedEnhancers,
);
configureKapuaAxiosInterceptors(store);

sagaMiddleware.run(sagas);

export default store;
