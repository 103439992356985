import {
  VALIDATE_CSV_SUCCESS,
  FETCH_EXPO_SUCCESS,
  Actions,
  VALIDATE_CSV,
  VALIDATE_CSV_ERROR,
  RESET,
  VALIDATE_CSV_SUCCESS_WITH_ERRORS,
  SET_IS_VALIDATING,
} from '../../actions';

export interface ICsvContentState {
  demoContent?: FormattedDemoContent;
  errors: string[];
  successfulCsvValue?: string;
  valid: boolean;
  validating: boolean;
}

export const initialState: ICsvContentState = {
  errors: [],
  valid: false,
  validating: false,
};

export default function csvReducer(
  state: ICsvContentState = initialState,
  action: Actions,
): ICsvContentState {
  switch (action.type) {
    case FETCH_EXPO_SUCCESS:
      return {
        ...state,
        successfulCsvValue: action.payload.expo.csvContent,
      };

    case VALIDATE_CSV:
      return {
        ...state,
        errors: [],
        validating: true,
        demoContent: undefined,
      };

    case VALIDATE_CSV_SUCCESS:
      return {
        ...state,
        valid: true,
        validating: false,
        demoContent: action.payload.content,
        successfulCsvValue: action.payload.csvValue,
      };

    case VALIDATE_CSV_SUCCESS_WITH_ERRORS:
      return {
        ...state,
        valid: false,
        validating: false,
        demoContent: action.payload,
      };

    case VALIDATE_CSV_ERROR:
      return {
        ...state,
        validating: false,
        errors: action.payload,
      };

    case SET_IS_VALIDATING:
      return {
        ...state,
        validating: action.payload,
      };

    case RESET:
      return initialState;

    default:
      return state;
  }
}
