import classNames from 'classnames';
import * as React from 'react';
import { Loader } from '../Loader';
import './styles.scss';

type TonClick = (e: React.MouseEvent<HTMLButtonElement>) => void;

interface IProps {
  children: React.ReactNode | React.ReactNodeArray;
  className?: string;
  showCloseButton?: boolean;
  onCloseClick?: TonClick;
  darkTheme?: boolean;
  loading?: boolean;
}

class Card extends React.PureComponent<IProps> {
  public static defaultProps: Partial<IProps> = {
    className: '',
    darkTheme: false,
    loading: false,
    onCloseClick: () => void 0,
    showCloseButton: false,
  };

  constructor(props: IProps) {
    super(props);
    this.renderCloseBtn = this.renderCloseBtn.bind(this);
  }

  public renderCloseBtn() {
    return (
      <button className="card__close" onClick={this.props.onCloseClick}>
        <span className="icon-close" />
      </button>
    );
  }

  public render() {
    const { darkTheme, children, loading, showCloseButton, className } =
      this.props;

    const classes = classNames('card', className, {
      'card--dark': darkTheme,
    });

    const loaderClasses = classNames('card__loader', {
      'card__loader--active': loading,
    });

    return (
      <div className={classes}>
        <div className={loaderClasses}>
          <Loader showLoader={loading} />
        </div>
        {showCloseButton && this.renderCloseBtn()}
        {children}
      </div>
    );
  }
}

export default Card;
