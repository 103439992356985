import { Columns } from '../../../../../common/pov-common-ui';
import * as React from 'react';
import Cell from '../../../../../common/components/table-cell';

export const phoneNumberColumns: Columns<IPhoneNumberColumn> = [
  {
    Header: 'External (DID)',
    Cell: (cellInfo) => (
      <Cell canCopy={true} value={cellInfo.original.external} />
    ),
    accessor: 'external',
  },
  {
    Header: 'Internal (DN)',
    Cell: (cellInfo) => (
      <Cell canCopy={true} value={cellInfo.original.internal} />
    ),
    accessor: 'internal',
  },
  {
    Header: 'Description',
    Cell: (cellInfo) => <Cell value={cellInfo.original.description} />,
    accessor: 'description',
  },
];
