import * as React from 'react';
import './styles.scss';
import { Feature } from 'dcloud-feature-flags-provider';

export default function Footer() {
  return (
    <div className="expo-footer">
      <a
        href="https://dcloud2.cisco.com/support/"
        rel="noopener noreferrer"
        target="_blank"
      >
        Support
      </a>
      <div className="logo-container">
        <span className="icon-cisco" />
        <div className="copyright-text">
          <p>
            Copyright &#169; {new Date().getFullYear()} Cisco Systems Inc.
            <br />
            All rights reserved.
            <Feature name={'test-feature-toggle'}>
              <br />
              <small>Test Feature Toggle</small>
            </Feature>
          </p>
        </div>
      </div>
    </div>
  );
}
