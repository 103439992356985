import * as React from 'react';

interface IProps {
  sessionId: string;
  onChange: (targetState: ITargetState) => void;
  inputTargetState: ITargetState;
}

export default function TargetInput({
  inputTargetState,
  onChange,
  sessionId,
}: IProps) {
  const updateTargetNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value || '0';

    if (!/^\d+$/.test(value)) return;

    onChange({
      ...inputTargetState,
      [sessionId]: parseInt(value, 10),
    });
  };

  return inputTargetState[sessionId] !== undefined ? (
    <input
      className="target-input"
      type="text"
      value={inputTargetState[sessionId]}
      onChange={(e) => updateTargetNumber(e)}
    />
  ) : null;
}
