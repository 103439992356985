import { ToastSeverity } from '../../../../../app/common/pov-common-ui';
import { SagaIterator } from 'redux-saga';
import { call, delay, put } from 'redux-saga/effects';
import { FLICKER_DELAY, ROUTES } from '../../../../../app/common/constants';
import { toastActions } from '../../../../toast/actions';
import { modalActions } from '../../../../modals/actions';
import { publications } from '../../../../../app/entities/expos/service';
import { exposActions, PUBLISH_EXPO } from '../../actions';
import { IActionWithPayload } from '../../../../action-helpers';
import { push } from 'connected-react-router';

export default function* publishExpoWorker(
  action: IActionWithPayload<typeof PUBLISH_EXPO, string>,
): SagaIterator {
  try {
    yield put(modalActions.modalLoading(true));
    yield call(publications, action.payload);
    yield delay(FLICKER_DELAY);
    yield put(exposActions.publishExpoSuccess(action.payload));
    yield put(push(ROUTES.ADMIN));
    yield put(
      toastActions.showToast(
        'Success',
        'eXpo Published',
        ToastSeverity.SUCCESS,
      ),
    );
  } catch (e) {
    throw e;
  } finally {
    yield put(modalActions.closeModal());
    yield put(modalActions.modalLoading(false));
  }
}
