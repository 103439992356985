import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { expoActions, FETCH_EXPO } from '../../actions';
import { getPublicExpo } from '../../../../../app/entities/expos/service';
import { IActionWithPayload } from '../../../../action-helpers';
import {
  IExpoWithCategories,
  IPublicExpoResponse,
} from '../../../../../app/entities/expos/types';
import { formatDemosIntoCategories } from '../../../../../app/public/expo/utilities/utilities';
import { push } from 'connected-react-router';
import { ROUTES } from '../../../../../app/common/constants';
import { AxiosError } from 'axios';
import { mapFromPublicExpoResponse } from '../../../../../app/entities/expos/utilities';

type Action = IActionWithPayload<typeof FETCH_EXPO, string>;

export default function* fetchExpoWorker(action: Action): SagaIterator {
  try {
    const response: IPublicExpoResponse = yield call(
      getPublicExpo,
      action.payload,
    );

    const expo: IExpoWithCategories = yield call(
      formatDemosIntoCategories,
      mapFromPublicExpoResponse(response),
    );
    yield put(expoActions.fetchExpoSuccess(expo));
  } catch (e) {
    const error: AxiosError = e;

    if (error.response!.status === 404) {
      yield put(push(ROUTES.RESOURCE_NOT_FOUND_404_USER));
    }
    throw error;
  }
}
