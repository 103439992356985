import { Columns } from '../../../common/pov-common-ui';
import * as React from 'react';
import Cell from '../../../common/components/table-cell';

interface IResourceColumn {
  documentLink: string;
  name: string;
}

export const resourceColumns: Columns<IResourceColumn> = [
  {
    Header: 'Name',
    Cell: (cellInfo) => (
      <Cell
        newTab={true}
        link={cellInfo.original.documentLink}
        value={cellInfo.original.name}
      />
    ),
    accessor: 'name',
  },
];
