import * as React from 'react';
import { Columns } from '../../../../common/pov-common-ui';
import Cell from '../../../../common/components/table-cell';
import TargetInput from '../target-input';

export const targetColumns = (
  updateState: (targetState: ITargetState) => void,
  inputTargetState: ITargetState,
): Columns<IDemoDetails> => [
  {
    Header: 'Demo ID',
    Cell: (cellInfo) => <Cell value={cellInfo.original.dCloudId} />,
    accessor: 'dCloudId',
    minWidth: 30,
  },
  {
    Header: 'Name',
    Cell: (cellInfo) => <Cell value={cellInfo.original.name} />,
    accessor: 'name',
    minWidth: 80,
  },
  {
    Header: 'Target',
    Cell: (cellInfo) => (
      <TargetInput
        sessionId={cellInfo.original.uid}
        onChange={updateState}
        inputTargetState={inputTargetState}
      />
    ),
    accessor: 'targetSessions',
    minWidth: 20,
  },
  {
    Header: 'Running',
    Cell: (cellInfo) => <Cell value={`${cellInfo.original.runningSessions}`} />,
    accessor: 'runningSessions',
    minWidth: 20,
  },
  {
    Header: 'In Use',
    Cell: (cellInfo) => (
      <Cell value={`${cellInfo.original.activeEngagements}`} />
    ),
    accessor: 'activeEngagements',
    minWidth: 20,
  },
  {
    Header: 'Scheduled',
    Cell: (cellInfo) => (
      <Cell value={`${cellInfo.original.scheduledSessions}`} />
    ),
    accessor: 'scheduledSessions',
    minWidth: 22,
  },
  {
    Header: 'Starting',
    Cell: (cellInfo) => (
      <Cell value={`${cellInfo.original.startingSessions}`} />
    ),
    accessor: 'startingSessions',
    minWidth: 20,
  },
  {
    Header: 'Stopping',
    Cell: (cellInfo) => (
      <Cell value={`${cellInfo.original.stoppingSessions}`} />
    ),
    accessor: 'stoppingSessions',
    minWidth: 20,
  },
  {
    Header: 'Stopped',
    Cell: (cellInfo) => <Cell value={`${cellInfo.original.stoppedSessions}`} />,
    accessor: 'stoppedSessions',
    minWidth: 20,
  },
  {
    Header: 'Error',
    Cell: (cellInfo) => <Cell value={`${cellInfo.original.errorSessions}`} />,
    accessor: 'errorSessions',
    minWidth: 20,
  },
];
