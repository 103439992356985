import * as React from 'react';
import DemoExploreItem from '../../../../common/components/demo-explore-item';

interface IProps {
  demos: IDemo[];
  onExploreClick: (demo: IDemo) => void;
}

export default function Demos({ demos, onExploreClick }: IProps) {
  return (
    <>
      {demos.map((demo) => (
        <DemoExploreItem
          key={demo.uid}
          onExploreClick={() => {
            onExploreClick(demo);
          }}
          id={demo.uid}
          title={demo.name}
          thumbnail={demo.thumbnail}
        />
      ))}
    </>
  );
}
