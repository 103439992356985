import * as React from 'react';
import { Columns, ThreeDotDropDown } from '../../../../common/pov-common-ui';
import Cell from '../../../../common/components/table-cell';

const formatRunTime = (time: number) =>
  time < 60 ? `${time.toFixed()} secs` : `${(time / 60).toFixed()} mins`;

export function engagementColumns(
  showEndEngagementModal: (engagementId: string) => void,
): Columns<IEngagementDetails> {
  return [
    {
      Header: 'User',
      Cell: (cellInfo) => <Cell value={cellInfo.original.user} />,
      accessor: 'user',
      minWidth: 30,
    },
    {
      Header: 'DC',
      Cell: (cellInfo) => <Cell value={cellInfo.original.dataCenter} />,
      accessor: 'dataCenter',
      minWidth: 20,
    },
    {
      Header: 'Demo ID',
      Cell: (cellInfo) => <Cell value={cellInfo.original.dCloudDemoId} />,
      accessor: 'dCloudDemoId',
      minWidth: 20,
    },
    {
      Header: 'Name',
      Cell: (cellInfo) => <Cell value={cellInfo.original.demoName} />,
      accessor: 'demoName',
      minWidth: 60,
    },
    {
      Header: 'Run Time',
      Cell: (cellInfo) => (
        <Cell value={formatRunTime(cellInfo.original.runningSeconds)} />
      ),
      accessor: 'runningSessions',
      minWidth: 30,
    },
    {
      Header: 'Session ID',
      Cell: (cellInfo) => (
        <Cell value={`${cellInfo.original.dCloudSessionId}` || '-'} />
      ),
      accessor: 'dCloudSessionId',
      minWidth: 30,
    },
    {
      Header: '',
      Cell: (cellInfo) => (
        <ThreeDotDropDown
          options={[
            {
              name: 'End Engagement',
              onSelect: () => showEndEngagementModal(cellInfo.original.uid),
            },
          ]}
        />
      ),
      accessor: 'end',
      minWidth: 30,
    },
  ];
}
