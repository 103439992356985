import {
  IErrors,
  IInputs,
  ITouched,
  IValidation,
  TValidationFunction,
} from './types';

// tslint:disable-next-line:no-any
export function checkAnyObjValuesSet(obj: any): boolean {
  return Object.keys(obj).some(
    (k) => obj[k] !== undefined && obj[k] !== null && obj[k] !== '',
  );
}

export function checkInputHasValue<T>(value: T): boolean {
  if (
    typeof value === 'object' &&
    !(value instanceof Array) &&
    value !== null
  ) {
    return checkAnyObjValuesSet(value);
  }

  if (value instanceof Array) {
    return !!value.length;
  }

  return !!value;
}

export function setupTouched<I>(inputs: I, touched = false): ITouched {
  return Object.keys(inputs).reduce(
    (obj: ITouched, key: keyof IValidation): ITouched => ({
      ...obj,
      [key]: touched || checkInputHasValue(inputs[key]),
    }),
    {},
  );
}

export const setupErrors = (validation: IValidation): IErrors =>
  Object.keys(validation).reduce(
    (obj: IErrors, key: keyof IValidation): IErrors => ({
      ...obj,
      [key]: [],
    }),
    {},
  );

export const validate = (
  value: string,
  inputs: IInputs,
  validation: TValidationFunction[],
): string[] => validation.map((fn) => fn(value, inputs)).filter((v) => !!v);

export const evaluateErrors = (
  inputs: IInputs,
  validation: IValidation,
  touched: ITouched,
): IErrors =>
  Object.keys(inputs).reduce((obj: IErrors, key: string): IErrors => {
    const shouldValidate: boolean | TValidationFunction[] =
      validation[key] && touched[key];

    return {
      ...obj,
      [key]: shouldValidate
        ? validate(inputs[key], inputs, validation[key])
        : [],
    };
  }, {});

export const formHasErrors = (
  inputs: IInputs,
  validation: IValidation,
): boolean => {
  const errors = Object.keys(inputs).reduce(
    (arr: string[], key: keyof IInputs): string[] => {
      if (validation[key]) {
        return [...arr, ...validate(inputs[key], inputs, validation[key])];
      }

      return arr;
    },
    [],
  );

  return !errors.length;
};
