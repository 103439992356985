import { Button, Card, CardHeader } from '../../common/pov-common-ui';
import * as React from 'react';
import Footer from '../../common/components/footer';
import NavBar from '../../common/components/nav-bar';
import Topbar from '../../common/components/top-bar';
import AccessCodeForm from './access-code-form';
import { EXPO_TUTORIALS, TOP_DCLOUD_DEMOS } from './data';
import DemoOfTheMonth from './demo-of-the-month';
import LandingPageItem from './landing-page-item';
import WhyExpo from './why-expo';
import { Redirect } from 'react-router';
import { BASE_URL, ROUTES } from '../../common/constants';
import { landingPageActions } from '../../../redux/public/landing-page/actions';
import './styles.scss';

export interface IProps {
  engagementUid: string | null;
  engagementExpoUid: string | null;
  errors: string[];
  loading: boolean;
  onSubmit: typeof landingPageActions.sendAccessCode;
}

export default function LandingPage({
  engagementUid,
  engagementExpoUid,
  errors,
  loading,
  onSubmit,
}: IProps) {
  if (engagementUid && engagementExpoUid) {
    return <Redirect to={ROUTES.EXPO.replace(':id', engagementExpoUid)} />;
  }

  return (
    <div className="landing-page u-with-sticky-header">
      <NavBar />

      <div className="container layout__container">
        <Topbar />

        <div className="row row--no-wrap">
          <DemoOfTheMonth />

          <div className="col-lg-4 flex-column">
            <AccessCodeForm
              errors={errors}
              onSubmit={onSubmit}
              loading={loading}
            />

            <Card className="how-to-tile">
              <CardHeader title="How to" alignTitle="center" />
              <p>
                {`Learn more about eXpo and get started with our 'how to' guide`}
              </p>
              <Button color="primary">
                <a
                  href={`${BASE_URL}/assets/user-guide.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View
                </a>
              </Button>
            </Card>
          </div>
        </div>

        <div className="row row--no-wrap">
          <Card className="col-lg-6 top-dcloud-demos">
            <CardHeader title="Top dCloud Demos" alignTitle="center" />
            {TOP_DCLOUD_DEMOS.map((item) => (
              <LandingPageItem {...item} key={item.title} />
            ))}
          </Card>

          <div className="col-lg-6 expo-videos">
            {EXPO_TUTORIALS.map((item) => (
              <div key={item.title} className="card">
                <LandingPageItem {...item} />
              </div>
            ))}
          </div>
        </div>

        <WhyExpo />
      </div>

      <Footer />
    </div>
  );
}
