import classNames from 'classnames';
import * as React from 'react';
import { FormGroup } from '../FormGroup';
import './styles.scss';

export interface IProps extends ISharedInputProps {
  errors?: string[];
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  rows?: number;
  maxCharCount?: number;
  value?: string;
}

const defaultProps = {
  errors: [],
  hideLabel: false,
  required: false,
  rows: 5,
};

export function calculateCharacterLength(max: number, value = ''): number {
  const len: number = value.length;
  if (!len) return max;
  if (len > max) return 0;
  return max - len;
}

const TextArea: React.SFC<IProps> = ({
  errors,
  hideLabel,
  id,
  label,
  name,
  maxCharCount,
  onChange,
  placeholder,
  required,
  rows,
  value,
}) => {
  const classes = classNames('sc-text-area', {
    'sc-text-area__with-count': !!maxCharCount,
  });

  return (
    <FormGroup className={classes} withFormGroupText={true} errors={errors}>
      <textarea
        value={value}
        name={name}
        id={id}
        rows={rows}
        placeholder={placeholder}
        onBlur={onChange}
        onChange={onChange}
        className="textarea"
      />
      <label
        htmlFor={name}
        className={classNames('text-area__label', { 'sr-only': hideLabel })}
      >
        {label}
        {required && <span>*</span>}
      </label>
      {maxCharCount && (
        <p className="sc-text-area__count">
          *{calculateCharacterLength(maxCharCount, value)} Characters remaining
        </p>
      )}
    </FormGroup>
  );
};

TextArea.defaultProps = defaultProps;

export default TextArea;
