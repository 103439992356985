import { Actions, UPDATE_GENERAL_FORM_VALIDITY, RESET } from '../../actions';

export default function generalFormValidReducer(
  state = false,
  action: Actions,
): boolean {
  switch (action.type) {
    case UPDATE_GENERAL_FORM_VALIDITY:
      return action.payload;

    case RESET:
      return false;

    default:
      return state;
  }
}
