import classNames from 'classnames';
import * as React from 'react';
import './styles.scss';

export interface IProps {
  alignTitle?: 'left' | 'center' | 'right';
  right?: React.ReactNode;
  smallTitle?: boolean;
  subTitle?: string;
  title: string;
  titleAction?: React.ReactNode;
}

const CardHeader: React.SFC<IProps> = ({
  alignTitle = 'left',
  title,
  right,
  subTitle = '',
  titleAction,
  smallTitle = false,
}) => {
  const classes = classNames('card__header', {
    [`card__header--${alignTitle}`]: alignTitle !== 'left',
  });

  const titleClasses = classNames('card__title', {
    'card__title--small': smallTitle,
    'card__title--withSub': !!subTitle,
  });

  return (
    <header className={classes}>
      <div className="card__header__content">
        <h2 className={titleClasses}>
          {title} {titleAction}
        </h2>
        {subTitle && <h3 className="card__subTitle">{subTitle}</h3>}
      </div>
      {right && right}
    </header>
  );
};

export default CardHeader;
