import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { endExpoDemoEngagement } from '../../../../../app/entities/expos/service';
import { infoActions, END_ENGAGEMENT } from '../../actions';
import { globalUiActions } from '../../../../ui/loader/actions';
import { IActionWithPayload } from '../../../../action-helpers';
import { toastActions } from '../../../../toast/actions';
import { ToastSeverity } from '../../../../../app/common/pov-common-ui';
import { modalActions } from '../../../../modals/actions';
type Action = IActionWithPayload<typeof END_ENGAGEMENT, string>;

export function* endDemoEngagementWorker(action: Action): SagaIterator {
  try {
    yield put(globalUiActions.showLoader());
    yield call(endExpoDemoEngagement, action.payload);
    yield put(infoActions.endEngagementSuccess(action.payload));
    yield put(
      toastActions.showToast(
        'Success',
        'Engagement ended',
        ToastSeverity.SUCCESS,
      ),
    );
  } catch (e) {
    if (e.response.status === 404) {
      yield put(infoActions.endEngagementSuccess(action.payload));
      yield put(
        toastActions.showToast(
          'Success',
          'Engagement ended',
          ToastSeverity.SUCCESS,
        ),
      );
    }
  } finally {
    yield put(globalUiActions.hideLoader());
    yield put(modalActions.closeModal());
  }
}
