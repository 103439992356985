import { call, put } from 'redux-saga/effects';
import { IActionWithPayload } from '../../../../action-helpers';
import { infoActions, GET_EXPO_DEMOS } from '../../actions';
import {
  getDataCenters,
  getSingleExpoDemos,
} from '../../../../../app/entities/expos/service';
import { SagaIterator } from 'redux-saga';
import { datacentersActions } from '../../../../../../src/redux/admin/datacenters/actions';
import { IDataCenter } from '../../../../../../src/app/entities/expos/types';

type Action = IActionWithPayload<typeof GET_EXPO_DEMOS, string>;

export default function* getExpoDemosWorker(action: Action): SagaIterator {
  yield put(infoActions.showLoader());

  try {
    const expoDemosResponse: IDemoDetails[] = yield call(
      getSingleExpoDemos,
      action.payload,
    );
    const dataCentersResponse: IDataCenter[] = yield call(getDataCenters);

    yield put(infoActions.getExpoDemosSuccess(expoDemosResponse));
    yield put(datacentersActions.fetchDatacentersSuccess(dataCentersResponse));
  } catch (e) {
    throw e;
  } finally {
    yield put(infoActions.hideLoader());
  }
}
