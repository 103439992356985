import { createAction } from '../../../action-helpers';
import { ActionsUnion } from '../../../action-types';

export const SHOW_LOADER = 'ADMIN/EXPO_INFO/SHOW_LOADER';
export const HIDE_LOADER = 'ADMIN/EXPO_INFO/HIDE_LOADER';
export const SET_FILTER = 'ADMIN/EXPO_INFO/SET_FILTER';
export const GET_EXPO_DEMOS = 'ADMIN/EXPO_INFO/GET_EXPO_DEMOS';
export const GET_EXPO_DEMOS_SUCCESS = 'ADMIN/EXPO_INFO/GET_EXPO_DEMOS_SUCCESS';
export const GET_EXPO_ENGAGEMENTS = 'ADMIN/EXPO_INFO/GET_EXPO_ENGAGEMENTS';
export const END_ENGAGEMENT = 'ADMIN/EXPO_INFO/END';
export const END_ENGAGEMENT_SUCCESS = 'ADMIN/EXPO_INFO/END_SUCCESS';

export const GET_EXPO_ENGAGEMENTS_SUCCESS =
  'ADMIN/EXPO_INFO/GET_EXPO_ENGAGEMENTS_SUCCESS';

export const APPLY_REBALANCE_CHANGES =
  'ADMIN/EXPO_INFO/APPLY_REBALANCE_CHANGES';

export const SET_TARGET_STATE = 'ADMIN/EXPO_INFO/SET_TARGET_STATE';
export const UPDATE_TARGET_COUNT_SUCCESS =
  'ADMIN/EXPO_INFO/UPDATE_TARGET_COUNT_SUCCESS';

export const SET_IS_REBALANCE_TAB_VISIBLE =
  'ADMIN/EXPOS/SET_IS_REBALANCE_TAB_VISIBLE';

const showLoader = () => createAction(SHOW_LOADER);
const hideLoader = () => createAction(HIDE_LOADER);
const setFilter = (filter: IRebalanceFilterValues) =>
  createAction(SET_FILTER, filter);
const setTargetState = (targetState: ITargetState) =>
  createAction(SET_TARGET_STATE, targetState);
const updateTargetCountSuccess = () =>
  createAction(UPDATE_TARGET_COUNT_SUCCESS);
const getExpoDemos = (uid: string) => createAction(GET_EXPO_DEMOS, uid);
const getExpoDemosSuccess = (demos: IDemoDetails[]) =>
  createAction(GET_EXPO_DEMOS_SUCCESS, demos);
const applyRebalanceChanges = (expoUid: string) =>
  createAction(APPLY_REBALANCE_CHANGES, expoUid);

const getExpoEngagements = (uid: string) =>
  createAction(GET_EXPO_ENGAGEMENTS, uid);

const getExpoEngagementsSuccess = (engagements: IEngagementDetails[]) =>
  createAction(GET_EXPO_ENGAGEMENTS_SUCCESS, engagements);

const endEngagement = (engagementId: string) =>
  createAction(END_ENGAGEMENT, engagementId);

const endEngagementSuccess = (engagementId: string) =>
  createAction(END_ENGAGEMENT_SUCCESS, engagementId);

const setIsRebalanceTabVisible = (isRebalanceTabVisible: boolean | undefined) =>
  createAction(SET_IS_REBALANCE_TAB_VISIBLE, isRebalanceTabVisible);

export const infoActions = {
  applyRebalanceChanges,
  getExpoDemos,
  getExpoDemosSuccess,
  getExpoEngagements,
  getExpoEngagementsSuccess,
  hideLoader,
  showLoader,
  setFilter,
  setTargetState,
  updateTargetCountSuccess,
  endEngagement,
  endEngagementSuccess,
  setIsRebalanceTabVisible,
};

export type Actions = ActionsUnion<typeof infoActions>;
