import { Dropdown } from '../../../common/pov-common-ui';
import * as React from 'react';
import { LinkButton } from '../../../common/components/link-button';
import { ExpoDateFilters, ExpoStates } from '../../../entities/expos/constants';
import { expoFilters } from '../../../entities/expos/defaults';

const SORT_BY_FILTERS = [
  { name: 'Date descending', value: ExpoDateFilters.DESC },
  { name: 'Date ascending', value: ExpoDateFilters.ASC },
];

interface IProps {
  filterBy: ExpoStates;
  sortBy: ExpoDateFilters;
  setSortBy: (sortBy: ExpoDateFilters) => void;
  setFilterBy: (sortBy: ExpoStates) => void;
}

export default function Filters(props: IProps) {
  const onFilter = React.useCallback(
    (name: string, value: ExpoStates) => {
      props.setFilterBy(value);
    },
    [props],
  );

  const sortBy = React.useCallback(
    (name: string, value: ExpoDateFilters) => {
      props.setSortBy(value);
    },
    [props],
  );

  return (
    <div id="filters">
      <LinkButton
        color="white-ghost"
        id="admin_create_expo"
        to="/admin/expo/create"
      >
        Create a new eXpo
      </LinkButton>
      <div className="menu-page__controls">
        <Dropdown
          id="admin_filter_expos"
          name="admin_filter_expos"
          onChange={onFilter}
          selected={props.filterBy}
          options={expoFilters}
        />
        <Dropdown
          id="admin_sort-by_expos"
          name="admin_sort-by_expos"
          onChange={sortBy}
          selected={props.sortBy}
          options={SORT_BY_FILTERS}
        />
      </div>
    </div>
  );
}
