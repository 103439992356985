import { connect } from 'react-redux';
import {
  getDemosWithDataCenterConnectivity,
  getScheduledDemos,
  getFilterBy,
  getRebalanceDCFilters,
  getInputTargetState,
  getRebalanceContentPoolFilters,
  getIsRebalanceTabVisible,
} from '../../../../../redux/admin/info/selectors';
import { infoActions } from '../../../../../redux/admin/info/actions';
import Rebalance from '.';
import { IApplicationState } from '../../../../../redux/types';
import { getDataCenters } from '../../../../../../src/redux/admin/datacenters/selectors';

const mapStateToProps = (state: IApplicationState) => ({
  demos: getDemosWithDataCenterConnectivity(state),
  filterBy: getFilterBy(state),
  scheduledDemos: getScheduledDemos(state),
  dcFilters: getRebalanceDCFilters(state),
  contentPoolFiltersMap: getRebalanceContentPoolFilters(state),
  inputTargetState: getInputTargetState(state),
  isRebalanceTabVisible: getIsRebalanceTabVisible(state),
  dataCenters: getDataCenters(state),
});

const mapDispatchToProps = {
  setFilter: infoActions.setFilter,
  setTargetState: infoActions.setTargetState,
  setIsRebalanceTabVisible: infoActions.setIsRebalanceTabVisible,
};

export default connect(mapStateToProps, mapDispatchToProps)(Rebalance);
