import { IDataCenter } from '../../../../../../../src/app/entities/expos/types';

export const determineDataCenterConnectivity = (
  dataCenters: IDataCenter[],
  dataCenterName: string,
  dataCenterConnectivity: DataCenterConnectivity,
): boolean | undefined => {
  if (dataCenters.length === 0) {
    return undefined;
  }

  const result = dataCenters.filter(
    (dataCenter) => dataCenter.code === dataCenterName,
  );

  if (result.length < 1) {
    return undefined;
  }

  return result[0].connectivity === dataCenterConnectivity;
};
