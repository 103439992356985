import { SagaIterator } from 'redux-saga';
import { delay, put } from 'redux-saga/effects';
import { toastActions } from '../actions';

export const TOAST_TIMEOUT = 3000;

export default function* toastWorker(): SagaIterator {
  try {
    yield delay(TOAST_TIMEOUT);
    yield put(toastActions.hideToast());
  } catch (e) {
    return;
  }
}
