import React, { ReactNode, useEffect } from 'react';
import { FullScreenLoader } from '../../app/common/components/loader';
import { AuthStatusValues } from '..';
import { startAuth } from '../../redux/authenticated/actions';
import { IApplicationState } from '../../redux/types';
import { connect, ConnectedProps } from 'react-redux';
import { getAuthStatus } from '../../redux/authenticated/selectors';

interface AuthenticatedGuardProps {
  children: ReactNode;
}

const mapDispatch = {
  startAuth,
};

const mapState = (state: IApplicationState) => ({
  authStatus: getAuthStatus(state),
});

export const connector = connect(mapState, mapDispatch);

type connectedProps = ConnectedProps<typeof connector>;

type AuthenticatedGuardConnectedProps = connectedProps;

export const AuthenticatedGuard = ({
  authStatus,
  startAuth,
  children,
}: AuthenticatedGuardConnectedProps & AuthenticatedGuardProps): JSX.Element => {
  useEffect(() => {
    if (authStatus === AuthStatusValues.NotAuthenticated) {
      startAuth();
    }
  }, [startAuth, authStatus]);

  return (
    <>
      {authStatus === AuthStatusValues.NotAuthenticated ? (
        <FullScreenLoader />
      ) : (
        children
      )}
    </>
  );
};
