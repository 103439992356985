import * as React from 'react';
import { BG_OVERRIDES } from '../../constants';
import './styles.scss';
import { imageAsset } from '../../utilities/imageAssets';
import { ReactElement } from 'react';

interface IProps {
  src: string;
}

const { CISCO_LIVE, CISCO_GSX } = BG_OVERRIDES;

const BACKGROUND_CISCO_LIVE = imageAsset('cisco-live/background-4.png');
const BACKGROUND_CISCO_GSX = imageAsset('cisco-gsx/background-5.png');

const backgroundStyleMap = new Map<string, string[]>();
backgroundStyleMap.set(BACKGROUND_CISCO_LIVE, [CISCO_LIVE]);
backgroundStyleMap.set(BACKGROUND_CISCO_GSX, [CISCO_GSX]);

export default function BackgroundImage({ src }: IProps): ReactElement {
  const [loaded, setLoaded] = React.useState(false);
  const [highQualitySrc, setHighQualitySource] = React.useState('');
  const ref = React.useRef(null);

  React.useEffect(() => {
    const bodyClasses = backgroundStyleMap.get(src);
    setLoaded(false);
    setHighQualitySource('');

    if (bodyClasses) {
      document.body.classList.add(...bodyClasses);
    }

    return () => {
      if (bodyClasses) {
        document.body.classList.remove(...bodyClasses);
      }
    };
  }, [src]);

  function onLoad() {
    if (!loaded) {
      if (backgroundStyleMap.has(src)) {
        document.body.classList.add(...backgroundStyleMap.get(src));
      }

      loadHighQualityIfPossible();
      setLoaded(true);

      const bgImage = ref.current! as HTMLDivElement;
      bgImage.classList.add('background-image__loaded');
    }
  }

  function onLoadHighQuality(highSrc: string) {
    setHighQualitySource(highSrc);
  }

  function generateHighQualityFilename(srcToModify: string): string {
    const splitImagePath = srcToModify.split('/');
    const nonMutatedSplitImageArray = [...splitImagePath];
    const fileNameWithExtension = splitImagePath[splitImagePath.length - 1];
    const fileNameExtensionSplit = fileNameWithExtension.split('.');
    const fileName = fileNameExtensionSplit[0];
    const extension = fileNameExtensionSplit[1];
    nonMutatedSplitImageArray.pop();

    const joinedFilePath = nonMutatedSplitImageArray.join('/');
    return `${joinedFilePath}/${fileName}_high.${extension}`;
  }

  function loadHighQualityIfPossible() {
    const highQualityFilename = generateHighQualityFilename(src);

    const highQualityImageObject = new Image();

    highQualityImageObject.onload = () => {
      onLoadHighQuality(highQualityFilename);
    };

    highQualityImageObject.src = highQualityFilename;
  }

  return (
    <div
      ref={ref}
      className="background-image"
      style={{
        backgroundImage: `url(${highQualitySrc || src})`,
      }}
    >
      {highQualitySrc ? (
        <img
          onLoad={onLoad}
          alt="Expo"
          src={highQualitySrc}
          style={{
            display: 'none',
          }}
        />
      ) : (
        <img
          onLoad={onLoad}
          alt="Expo"
          src={src}
          style={{
            display: 'none',
          }}
        />
      )}
    </div>
  );
}
