import { connect } from 'react-redux';
import Landing from '.';
import {
  getEngagementExpoUid,
  getEngagementUid,
} from '../../../redux/public/engagements/selectors';
import { IApplicationState } from '../../../redux/types';
import { landingPageActions } from '../../../redux/public/landing-page/actions';
import { getLandingPageState } from '../../../redux/public/landing-page/selectors';

const mapStateToProps = (state: IApplicationState) => ({
  ...getLandingPageState(state),
  engagementUid: getEngagementUid(state),
  engagementExpoUid: getEngagementExpoUid(state),
});

const mapDispatchToProps = {
  onSubmit: landingPageActions.sendAccessCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
