import { Button } from '../../../pov-common-ui';
import * as React from 'react';
import ModalInner from '../modal-inner';
import MarkdownFormattedCopy from '../../markdown-formatted-copy';

export interface IProps {
  content: string;
  closeModal: () => void;
}

export default function GetStarted({ closeModal, content }: IProps) {
  return (
    <ModalInner
      title="Getting Started"
      content={<MarkdownFormattedCopy copy={content} />}
      buttons={
        <Button onClick={closeModal} id="get-started-modal_ok" color="primary">
          OK
        </Button>
      }
    />
  );
}
