import classNames from 'classnames';
import * as React from 'react';
import Option from './Option';

export interface IThreeDotOption {
  name: string;
  onSelect: (name: string) => void;
}

type DefaultProps = Partial<Readonly<typeof defaultProps>>;

export type Props = {
  options: IThreeDotOption[];
} & DefaultProps;

interface IState {
  isOpen: boolean;
}

const defaultProps = {
  align: 'center' as 'left' | 'center',
  disabled: false,
  id: '',
};

export default class ThreeDotDropdown extends React.PureComponent<
  Props,
  IState
> {
  public static defaultProps = defaultProps;

  public _component: React.RefObject<HTMLDivElement> = React.createRef();

  public state = {
    isOpen: false,
  };

  public componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  public handleClickOutside = (e: MouseEvent) => {
    const { current } = this._component;
    const { isOpen } = this.state;

    if (isOpen && current && !current.contains(e.target as Node)) {
      this.setState({ isOpen: false });
    }
  };

  public handleOnToggle = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  public render() {
    const { align, options } = this.props;
    const { isOpen } = this.state;

    const classes = classNames('dropdown dropdown--tail', {
      active: isOpen,
      'dropdown--center': align === 'center',
      'dropdown--left': align === 'left',
    });

    return (
      <div className={classes} ref={this._component}>
        <span onClick={this.handleOnToggle} className="icon-more icon-medium" />
        <div className="dropdown__menu">
          {options.map((option) => (
            <Option {...option} key={option.name} />
          ))}
        </div>
      </div>
    );
  }
}
