import * as React from 'react';
import './styles.scss';

interface IProps {
  left?: React.ReactNode;
  right?: React.ReactNode;
}

const defaultProps = {
  left: undefined,
  right: undefined,
};

const CardFooter: React.SFC<IProps> = ({ left, right }) => (
  <footer className="card__footer">
    {left && left}
    {right && <div className="card__footer__right">{right}</div>}
  </footer>
);

CardFooter.defaultProps = defaultProps;

export default CardFooter;
