import { createAction } from '../../../action-helpers';
import { ActionsUnion } from '../../../action-types';
import { IExpoBase, ISavedExpo } from '../../../../app/entities/expos/types';

interface IFetchExpoSuccessPayload {
  expo: ISavedExpo;
  etag: string;
}

export const CHECK_CAPACITY = 'ADMIN/EDIT/CHECK_CAPACITY';
export const CHECK_CAPACITY_SUCCESS = 'ADMIN/EDIT/CHECK_CAPACITY_SUCCESS';
export const CHECK_CAPACITY_FAILED = 'ADMIN/EDIT/CHECK_CAPACITY_FAILED';
export const FETCH_EXPO = 'ADMIN/EDIT/FETCH';
export const FETCH_EXPO_SUCCESS = 'ADMIN/EDIT/FETCH_SUCCESS';
export const SAVE_EXPO = 'ADMIN/EDIT/SAVE';
export const SAVE_EXPO_SUCCESS = 'ADMIN/EDIT/SAVE_SUCCESS';
export const VALIDATE_CSV = 'ADMIN/EDIT/VALIDATE_CSV';
export const VALIDATE_CSV_SUCCESS = 'ADMIN/EDIT/VALIDATE_CSV_SUCCESS';
export const VALIDATE_CSV_SUCCESS_WITH_ERRORS =
  'ADMIN/EDIT/VALIDATE_CSV_SUCCESS_WITH_ERRORS';
export const VALIDATE_CSV_ERROR = 'ADMIN/EDIT/VALIDATE_CSV_ERROR';
export const SET_IS_VALIDATING = 'ADMIN/EDIT/SET_IS_VALIDATING';
export const SET_CSV_VALUE = 'ADMIN/EDIT/SET_CSV_VALUE';
export const RESET = 'ADMIN/EDIT/RESET';
export const UPDATE_TEMP_EXPO_FIELD = 'ADMIN/EDIT/UPDATE_TEMP_EXPO_FIELD';

export const UPDATE_GENERAL_FORM_VALIDITY =
  'ADMIN/EDIT/UPDATE_GENERAL_FORM_VALIDITY';

const updateGeneralFormValidity = (valid: boolean) =>
  createAction(UPDATE_GENERAL_FORM_VALIDITY, valid);

const fetchExpo = (uid: string) => createAction(FETCH_EXPO, uid);

const fetchExpoSuccess = (payload: IFetchExpoSuccessPayload) =>
  createAction(FETCH_EXPO_SUCCESS, payload);

const onFieldUpdate = (expo: IExpoBase) =>
  createAction(UPDATE_TEMP_EXPO_FIELD, expo);

const saveExpo = () => createAction(SAVE_EXPO);

const saveExpoSuccess = (expo: ISavedExpo) =>
  createAction(SAVE_EXPO_SUCCESS, expo);

const validateCsv = () => createAction(VALIDATE_CSV);

const validateCsvSuccess = (payload: {
  content: IFormattedDemo[];
  csvValue?: string;
}) => createAction(VALIDATE_CSV_SUCCESS, payload);

const validateCsvSuccessWithContentErrors = (demoContent: IFormattedDemo[]) =>
  createAction(VALIDATE_CSV_SUCCESS_WITH_ERRORS, demoContent);

const validateCsvError = (errors: string[]) =>
  createAction(VALIDATE_CSV_ERROR, errors);

const setIsValidating = (isValidating: boolean) =>
  createAction(SET_IS_VALIDATING, isValidating);

const setCsvValue = (value: string) => createAction(SET_CSV_VALUE, value);

const checkCapacity = () => createAction(CHECK_CAPACITY);

const checkCapacitySuccess = (tableContent: IFormattedCapacityContent[]) =>
  createAction(CHECK_CAPACITY_SUCCESS, tableContent);

const checkCapacityFailed = (tableContent: IFormattedCapacityContent[]) =>
  createAction(CHECK_CAPACITY_FAILED, tableContent);

const reset = () => createAction(RESET);

export const editActions = {
  checkCapacity,
  checkCapacitySuccess,
  checkCapacityFailed,
  fetchExpo,
  fetchExpoSuccess,
  updateGeneralFormValidity,
  onFieldUpdate,
  saveExpo,
  saveExpoSuccess,
  setCsvValue,
  validateCsv,
  validateCsvError,
  validateCsvSuccess,
  validateCsvSuccessWithContentErrors,
  reset,
  setIsValidating,
};

export type Actions = ActionsUnion<typeof editActions>;
