import { createSelector } from 'reselect';
import { IApplicationState } from '../../../types';
import { getDataCenters } from '../../datacenters/selectors';
import { IExpoInfoState } from '../reducer';
import { IDataCenter } from '../../../../app/entities/expos/types';
import {
  determineConnectivity,
  getFilterValueWithConnectivity,
} from './utils/utils';

export const getExpoInfoState = (state: IApplicationState) =>
  state.admin.expoInfo;

export const getFilterBy = (state: IApplicationState) =>
  getExpoInfoState(state).filterBy;

export const getInputTargetState = (state: IApplicationState) =>
  getExpoInfoState(state).inputTargetState;

export const getInitialTotalTargetSessions = (state: IApplicationState) =>
  getExpoInfoState(state).initialTotalTargetSessions;

export const getTotalTargetSessions = (state: IApplicationState) =>
  getExpoInfoState(state).totalTargetSessions;

export const getTargetInputIsDirty = (state: IApplicationState) =>
  getExpoInfoState(state).targetInputIsDirty;

export const getDemos = (state: IApplicationState) =>
  getExpoInfoState(state).demos;

export const getDemosWithDataCenterConnectivity = createSelector(
  [getExpoInfoState, getDataCenters],
  (expoInfoState: IExpoInfoState, dataCentersState: IDataCenter[]) =>
    expoInfoState.demos.map((demo) => {
      const dataCenterConnectivity = dataCentersState.find(
        (dataCenter) => dataCenter.code === demo.dataCenter,
      )?.connectivity;

      return {
        ...demo,
        dataCenterConnectivity,
      };
    }),
);

export const getLoading = (state: IApplicationState) =>
  getExpoInfoState(state).loading;

export const getDemosPartitionedByDataCenter = (state: IApplicationState) => {
  const partitionedDemos = new Map<string, IDemoDetails[]>();

  getExpoInfoState(state)
    .demos.filter((demo) => demo.type !== 'INSTANT')
    .forEach((demo) => {
      if (partitionedDemos.has(demo.dataCenter)) {
        partitionedDemos.get(demo.dataCenter)?.push(demo);
      } else {
        partitionedDemos.set(demo.dataCenter, [demo]);
      }
    });

  return partitionedDemos;
};

export const getRebalanceDCFilters = createSelector(
  getDemosPartitionedByDataCenter,
  getDataCenters,
  (partitionedDemos: Map<string, IDemoDetails[]>, dataCenters: IDataCenter[]) =>
    [...partitionedDemos.keys()].map((name) => {
      const connectivity = determineConnectivity(dataCenters, name);

      return getFilterValueWithConnectivity(name, undefined, connectivity);
    }),
);

const getFilterValue = (name: string, value: string = name) => {
  return {
    name,
    value,
  };
};

export const getRebalanceContentPoolFilters = createSelector(
  getDemosPartitionedByDataCenter,
  (partitionedDemos: Map<string, IDemoDetails[]>) => {
    const contentPoolsMap = new Map();

    partitionedDemos.forEach((demos, dataCenter) => {
      contentPoolsMap.set(dataCenter, [
        ...new Map(
          demos.map(({ contentPool }) => [
            contentPool.id,
            getFilterValue(contentPool.name, contentPool.id),
          ]),
        ).values(),
      ]);
    });
    return contentPoolsMap;
  },
);

export const getEngagements = (state: IApplicationState) =>
  getExpoInfoState(state).engagements;

export const getRunningEngagements = (state: IApplicationState) =>
  getEngagements(state).filter((engagement) => !engagement.end);

// TODO -> Add demo types to a enum
export const getScheduledDemos = createSelector(
  getDemos,
  (demos: IDemoDetails[]) => demos.filter((demo) => demo.type !== 'INSTANT'),
);

export const getIsRebalanceTabVisible = (state: IApplicationState) =>
  getExpoInfoState(state).isRebalanceTabVisible;
