import { connect } from 'react-redux';
import Engagements, { IProps } from './';
import { infoActions } from '../../../../../redux/admin/info/actions';
import { IApplicationState } from '../../../../../redux/types';
import { getRunningEngagements } from '../../../../../redux/admin/info/selectors';
import { modalActions } from '../../../../../redux/modals/actions';
import { MODAL_NAMES } from '../../../../common/components/modal/constants';
import { Dispatch } from 'redux';

const mapStateToProps = (state: IApplicationState) => ({
  engagements: getRunningEngagements(state),
});

export const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: Partial<IProps>,
) => ({
  fetchEngagements: () =>
    dispatch(infoActions.getExpoEngagements(ownProps.expoUid!)),
  showEndEngagementModal: (engagementId: string) =>
    dispatch(
      modalActions.openModal(MODAL_NAMES.END_ENGAGEMENT, { engagementId }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Engagements);
