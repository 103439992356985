import {
  Button,
  DataGrid,
  Tab,
  Tabs,
} from '../../../../../common/pov-common-ui';
import * as React from 'react';
import columns from './columns';
import './styles.scss';
import TabLabel from './tab-label';
import CapacityDetail from '../capacity-detail';

export interface IProps {
  data: FormattedDemoContent;
  showCheckCapacity: boolean;
  isCapacityChecked: boolean;
  disableCheckCapacity: boolean;
  capacityDetail: IFormattedCapacityContent[];
  checkCapacity: () => void;
}

export interface IState {
  currentTab: string;
}

function setInitialState(props: IProps): IState {
  return {
    currentTab: props.data[0].name,
  };
}

export default class DemoContentTable extends React.PureComponent<IProps> {
  public state = setInitialState(this.props);

  public calculateSessionNumber = () => {
    const { currentTab } = this.state;
    const { data } = this.props;
    const currentData = data.find((item) => item.name === currentTab);

    return currentData!.demos.reduce(
      (num, item) => num + (Number(item.SESSIONS.value) || 0),
      0,
    );
  };

  public onTabChange = (title: string): void => {
    this.setState({ currentTab: title });
  };

  public render() {
    const { currentTab } = this.state;
    const {
      checkCapacity,
      data,
      disableCheckCapacity,
      showCheckCapacity,
      isCapacityChecked,
      capacityDetail,
    } = this.props;

    return (
      <>
        {isCapacityChecked ? (
          <CapacityDetail data={capacityDetail} />
        ) : (
          <Tabs onTabChange={this.onTabChange} id="demo-content">
            {data.map(({ name, errors, demos }) => (
              <Tab
                title={<TabLabel errors={errors}>{name}</TabLabel>}
                key={name}
              >
                <DataGrid
                  columns={columns}
                  data={demos}
                  sortable={true}
                  minRows={1}
                  pageSize={500}
                />
              </Tab>
            ))}
          </Tabs>
        )}
        <div className="demo-content-table__footer">
          {showCheckCapacity && (
            <Button
              color="primary"
              id="check-capacity"
              disabled={disableCheckCapacity}
              onClick={checkCapacity}
            >
              Check Capacity
            </Button>
          )}

          {!isCapacityChecked && (
            <p id="session-amount">
              Sessions in {currentTab}: {this.calculateSessionNumber()}
            </p>
          )}
        </div>
      </>
    );
  }
}
