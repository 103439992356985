import * as React from 'react';
import NotificationBubble from '../../../../../../common/components/notification-bubble';

export enum Classes {
  TEXT_DANGER = 'text-danger',
}

export interface IProps {
  errors?: number;
  children: string;
}

export default function TabLabel({ errors, children }: IProps): JSX.Element {
  const hasErrors = !!errors;
  return (
    <span className={`${hasErrors ? Classes.TEXT_DANGER : ''}`}>
      {children}{' '}
      {hasErrors && <NotificationBubble amount={errors ? errors : 0} />}
    </span>
  );
}
