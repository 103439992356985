import { useEffect } from 'react';
import { IDataCenter } from '../../../../../../../src/app/entities/expos/types';
import { IActionWithPayload } from '../../../../../../../src/redux/action-helpers';

export type UseRebalanceTabVisibility = {
  dataCenters: IDataCenter[];
  determineDataCenterConnectivity: (
    dataCenters: IDataCenter[],
    dataCenterName: string,
    dataCenterConnectivity: DataCenterConnectivity,
  ) => boolean | undefined;
  filterBy: IRebalanceFilterValues;
  setIsRebalanceTabVisible: (
    isRebalanceTabVisible: boolean | undefined,
  ) => IActionWithPayload<
    'ADMIN/EXPOS/SET_IS_REBALANCE_TAB_VISIBLE',
    boolean | undefined
  >;
};

export function useRebalanceTabVisibility({
  dataCenters,
  determineDataCenterConnectivity,
  filterBy,
  setIsRebalanceTabVisible,
}: UseRebalanceTabVisibility): void {
  useEffect(() => {
    let result: boolean | undefined = false;

    if (dataCenters.length > 0) {
      result = determineDataCenterConnectivity(
        dataCenters,
        filterBy.dataCenter,
        'CONNECTED',
      );
    }

    setIsRebalanceTabVisible(result);
  }, [
    dataCenters,
    determineDataCenterConnectivity,
    filterBy.dataCenter,
    setIsRebalanceTabVisible,
  ]);
}
