import axios from 'axios';
import { cloudSSOEndpoint } from './paths';

const cloudSSO = axios.create({
  baseURL: cloudSSOEndpoint,
});

if (process.env.NODE_ENV === 'development') {
  cloudSSO.defaults.withCredentials = true;
}

export default cloudSSO;
