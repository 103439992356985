import { Columns } from '../../../../../common/pov-common-ui';
import * as React from 'react';
import Cell from '../../../../../common/components/table-cell';

export const deviceColumns: Columns<IDeviceColumn> = [
  {
    Header: 'Device Name',
    Cell: (cellInfo) => <Cell value={cellInfo.original.name} />,
    accessor: 'name',
  },
  {
    Header: 'Device Type',
    Cell: (cellInfo) => <Cell value={cellInfo.original.type} />,
    accessor: 'type',
  },
];
