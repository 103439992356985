import { combineReducers } from 'redux';
import globalLoaderReducer, { GlobalLoaderState } from './loader/reducer';

export interface IUIState {
  loader: GlobalLoaderState;
}

export default combineReducers<IUIState>({
  loader: globalLoaderReducer,
});
