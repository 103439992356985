import moment from 'moment';
import * as React from 'react';
import ExpoLogo from '../expo-logo';
import './styles.scss';
import { Button } from '../../pov-common-ui';
import { LinkButton } from '../link-button';

export interface IGettingStartedRowButton {
  id: string;
  name: string;
  url: string;
}

export interface IProps {
  additionalButtons: IGettingStartedRowButton[];
  end: DateType;
  location: string;
  onGetStartedClick: () => void;
  start: DateType;
  type: string;
}

export default function LogoGettingStartedRow({
  additionalButtons,
  end,
  location,
  start,
  type,
  onGetStartedClick,
}: IProps) {
  const formattedStart: string = moment(start!).format('MMMM DD, YYYY');
  const formattedEnd: string = moment(end!).format('MMMM DD, YYYY');

  return (
    <div className="row logo-getting-started">
      <div className="col-md-6">
        <ExpoLogo expoName={type} />
        <p className="logo-getting-started__date-location">
          {formattedStart} - {formattedEnd} - {location}
        </p>
      </div>
      <div className="col-md-6 logo-getting-started__right">
        {additionalButtons.map((button) => (
          <LinkButton
            color="white-ghost"
            id={button.id}
            key={button.id}
            target="_blank"
            to={button.url}
          >
            {button.name}
          </LinkButton>
        ))}
        <Button
          color="white-ghost"
          onClick={onGetStartedClick}
          id="get-started"
        >
          Get Started
        </Button>
      </div>
    </div>
  );
}
