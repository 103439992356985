import * as React from 'react';
import { DataGrid } from '../../../../common/pov-common-ui';
import { contentColumns } from './columns';

interface IProps {
  demos: IDemoDetails[];
}

export default function Content({ demos }: IProps) {
  return (
    <DataGrid
      columns={contentColumns}
      data={demos}
      sortable={true}
      minRows={1}
      pageSize={500}
    />
  );
}
