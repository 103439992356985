import { Button } from '../../../pov-common-ui';
import * as React from 'react';
import ModalInner from '../modal-inner';

export interface IProps {
  closeModal: () => void;
  adminEndEngagement: () => void;
  engagementId: string;
}

function EndDemoEngagementModal({
  adminEndEngagement,
  closeModal,
  engagementId,
}: IProps) {
  return (
    <ModalInner
      title="End Engagement"
      content="Are you sure you want to end this engagement?"
      buttons={
        <>
          <Button
            onClick={adminEndEngagement}
            color="primary"
            id={`end-engagement-modal-btn-${engagementId}`}
          >
            End
          </Button>
          <Button
            onClick={closeModal}
            color="secondary"
            id={`end-engagement-modal-close-btn`}
          >
            Cancel
          </Button>
        </>
      }
    />
  );
}

export default EndDemoEngagementModal;
