import { connect } from 'react-redux';
import Info from '.';
import { IApplicationState } from '../../../redux/types';
import { getSingleExpo } from '../../../redux/admin/expos/selectors';
import { infoActions } from '../../../redux/admin/info/actions';
import { modalActions } from '../../../redux/modals/actions';
import { RouteComponentProps } from 'react-router-dom';

const mapStateToProps = (
  state: IApplicationState,
  ownProps: RouteComponentProps<{ id: string }>,
) => ({
  expo: getSingleExpo(state, ownProps.match.params.id),
});

const mapDispatchToProps = {
  fetchDemos: infoActions.getExpoDemos,
  openModal: modalActions.openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Info);
