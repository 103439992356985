import * as React from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';

function DCloudLogo() {
  const { t } = useTranslation();
  return (
    <p className="dcloud-logo">
      <span>{t('dCloudLogo.topLine')}</span>
      {t('dCloudLogo.bottomLine')}
    </p>
  );
}

export default DCloudLogo;
