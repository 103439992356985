import classNames from 'classnames';
import * as React from 'react';
import { CiscoButtonSizes } from '../constants';
import { TUpdateFormFn } from '../hocs/withFormValidation/types';
import { Button } from '../Buttons/Button';
import './styles.scss';

export interface IOption {
  name: string;
  value: string | number;
  thumb?: string;
}

export interface IProps {
  align?: 'left' | 'center';
  disabled?: boolean;
  id?: string;
  name: string;
  onChange: TUpdateFormFn;
  options: IOption[];
  selected: string | number;
  hideSelectedState?: boolean;
  fixedHeight?: boolean;
}

interface IState {
  isOpen: boolean;
}

class Dropdown extends React.PureComponent<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    align: 'center',
    disabled: false,
    hideSelectedState: false,
  };
  public _component: React.RefObject<HTMLDivElement> = React.createRef();

  public state = {
    isOpen: false,
  };

  public onSelectOption = (value: string | number) => {
    const { name } = this.props;
    this.props.onChange(name, value);
    this.setState(() => ({ isOpen: false }));
  };

  public handleOnToggle = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  public componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  public handleClickOutside = (e: MouseEvent) => {
    const { current } = this._component;
    const { isOpen } = this.state;

    if (isOpen && current && !current.contains(e.target as Node)) {
      this.setState({ isOpen: false });
    }
  };

  public renderOptions = () => {
    const { options, hideSelectedState, selected } = this.props;

    return options.map((option) => (
      <a
        className={classNames({
          selected: !hideSelectedState && selected === option.value,
          thumb: option.thumb,
        })}
        href={`#${option.name}`}
        key={option.value}
        onClick={() => this.onSelectOption(option.value)}
        role="button"
      >
        {option.thumb && (
          <div className="dropdown-thumb-container">
            <img src={option.thumb} alt={option.name} />
          </div>
        )}
        {option.name}
      </a>
    ));
  };

  public render() {
    const { id, disabled, selected, align, options, fixedHeight } = this.props;
    const { isOpen } = this.state;
    const option = options.find((opt) => opt.value === selected);

    const classes = classNames('dropdown', {
      active: isOpen,
      'dropdown--center': align === 'center',
      'dropdown--disabled': disabled,
      'dropdown--fixedHeight': fixedHeight,
      'dropdown--left': align === 'left',
    });

    return (
      <div className={classes} ref={this._component}>
        <Button
          id={id}
          disabled={disabled}
          onClick={this.handleOnToggle}
          size={CiscoButtonSizes.SMALL}
        >
          {option!.name}
        </Button>
        <div className="dropdown__menu">{this.renderOptions()}</div>
      </div>
    );
  }
}

export default Dropdown;
