import {
  IAccessCodeModalProps,
  IAnyConnectModalProps,
  IApiErrorModalProps,
  IDemoModalProps,
  IModalProps,
} from '../../app/common/components/modal/modal-types';
import { createAction } from '../action-helpers';
import { ActionsUnion } from '../action-types';
import { MODAL_NAMES } from '../../app/common/components/modal/constants';

export const OPEN_MODAL = 'MODAL/OPEN_MODAL';
export const CLOSE_MODAL = 'MODAL/SET_CLOSE';
export const MODAL_LOADING = 'MODAL/LOADING';

export const modalActions = {
  openModal: (
    modalName: MODAL_NAMES,
    modalProps?:
      | IModalProps
      | IAccessCodeModalProps
      | IApiErrorModalProps
      | IAnyConnectModalProps
      | IDemoModalProps,
  ) =>
    createAction(OPEN_MODAL, {
      name: modalName,
      props: modalProps,
    }),
  closeModal: () => createAction(CLOSE_MODAL),
  modalLoading: (loading: boolean) => createAction(MODAL_LOADING, loading),
};

export type Actions = ActionsUnion<typeof modalActions>;
