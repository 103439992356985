import classnames from 'classnames';
import * as React from 'react';

export interface IProps {
  active?: boolean;
  children: React.ReactNode | string;
  title: string | React.ReactNode;
}

const Tab: React.SFC<IProps> = ({ active, children }) => (
  <div
    role="tabpanel"
    aria-hidden={!active}
    className={classnames('tab-pane', { active })}
  >
    {children}
  </div>
);

export default Tab;
