import { createAction } from '../../action-helpers';
import { ActionsUnion } from '../../action-types';
import { ISurveyType } from '../../../app/entities/expos/types';

export const FETCH_SURVEY_TYPES = 'ADMIN/METADATA/FETCH_SURVEY_TYPES';
export const FETCH_SURVEY_TYPES_SUCCESS =
  'ADMIN/METADATA/FETCH_SURVEY_TYPES_SUCCESS';

export const fetchSurveyTypes = () => createAction(FETCH_SURVEY_TYPES);
export const fetchSurveyTypesSuccess = (surveyTypes: ISurveyType[]) =>
  createAction(FETCH_SURVEY_TYPES_SUCCESS, surveyTypes);

export const metadataActions = {
  fetchSurveyTypes,
  fetchSurveyTypesSuccess,
};

export type Actions = ActionsUnion<typeof metadataActions>;
