import * as React from 'react';
import { LinkButton } from '../../../../common/components/link-button';

export default function NoExpos() {
  return (
    <div className="no-expos">
      <h3>You have not created an eXpo yet.</h3>
      <LinkButton
        color="white-ghost"
        id="admin_create_expo_no_expos"
        to="/admin/expo/create"
      >
        Create a new eXpo
      </LinkButton>
    </div>
  );
}
