import classnames from 'classnames';
import * as React from 'react';

export enum ToastPadding {
  COMPRESSED = 'compressed',
  REGULAR = 'regular',
  LOOSE = 'loose',
}

export enum ToastSeverity {
  SUCCESS = 'success',
  INFORMATION = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
}
export interface IProps {
  className?: string;
  title: string;
  message: string;
  padding?: ToastPadding;
  severity?: ToastSeverity;
}

const defaultProps: Partial<IProps> = {
  className: '',
  padding: ToastPadding.REGULAR,
  severity: ToastSeverity.INFORMATION,
};

export const determineIconOutlineClass = (severity: string): string => {
  switch (severity) {
    case ToastSeverity.SUCCESS:
      return 'check';
    case ToastSeverity.DANGER:
      return 'error';
    default:
      return severity;
  }
};

export const classes = (severity: string): string =>
  classnames(
    'toast__icon',
    `text-${severity}`,
    `icon-${determineIconOutlineClass(severity)}-outline`,
  );

const Toast: React.SFC<IProps> = (props) => {
  const { className, padding, title, message, severity } = props;
  return (
    <div className={`toast toast--${padding} ${className}`}>
      <div className={classes(severity!)} />
      <div className="toast__body">
        <div className="toast__title">{title}</div>
        <div className="toast__message">{message}</div>
      </div>
    </div>
  );
};

Toast.defaultProps = defaultProps;
export default Toast;
