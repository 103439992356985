import { useState, useEffect } from 'react';

const POST_EXTENSION_WARNING_SECONDS = 910; // Default 15 minutes warning time + 10 second buffer

type EngagementTimerState = {
  expiring: boolean;
  handleAcceptOffer: () => void;
  handleRejectOffer: () => void;
  info: string;
  showExtension: boolean;
  offerText: string;
  title: EngagementTimerTitles;
  timeLeftInSeconds: number;
};

export interface IEngagementTimerProps {
  engagementManaged: boolean;
  expiring: boolean;
  extended: boolean;
  extendEngagement: () => void;
  handleAcceptOffer: () => void;
  handleRejectOffer: () => void;
  info: string;
  maxEngagementExtensionInMin: number;
  offerText: string;
  showExtension: boolean;
  timeLeftInSeconds: number;
  timeoutInSeconds: number;
  title: string;
  warningInSeconds: number;
}

export enum EngagementTimerTitles {
  CURRENT = 'Current Session',
  EXPIRED = 'Your Session Has Expired',
  EXPIRING = 'Your Current Session is Due to Expire',
}

const getTitle = (
  timeLeftInSeconds: number,
  expiring: boolean,
): EngagementTimerTitles => {
  if (timeLeftInSeconds <= 0) return EngagementTimerTitles.EXPIRED;
  if (expiring) return EngagementTimerTitles.EXPIRING;
  return EngagementTimerTitles.CURRENT;
};

const getInfo = (timeLeftInSeconds: number): string => {
  if (timeLeftInSeconds <= 0) return '';
  const hours = Math.floor(timeLeftInSeconds / 3600);
  const minutes = Math.floor((timeLeftInSeconds % 3600) / 60);
  return !hours ? `${minutes}m remaining` : `${hours}h ${minutes}m remaining`;
};

const getOfferText = (extensionMinutes: number): string => {
  const text = 'Extend your session by';
  const hours = Math.floor(extensionMinutes / 60);
  const minutes = Math.floor(extensionMinutes % 60);
  if (!hours) return `${text} ${minutes} minutes?`;
  return !minutes
    ? `${text} ${hours} hours?`
    : `${text} ${hours} hours and ${minutes} minutes?`;
};

const getShowExtension = (
  engagementManaged: boolean,
  expiring: boolean,
  extended: boolean,
  extensionMinutes: number,
  timeLeftInSeconds: number,
  userResponded: boolean,
) => {
  const aboutToEnd = timeLeftInSeconds - 1 <= 0;
  const canExtend =
    extensionMinutes > 0 &&
    expiring &&
    !userResponded &&
    !extended &&
    engagementManaged;

  return aboutToEnd ? false : canExtend;
};

export default function useEngagementTimerState(
  engagementManaged: boolean,
  extended: boolean,
  extendEngagement: () => void,
  extensionMinutes: number,
  timeoutSeconds: number,
  warningSeconds: number,
): EngagementTimerState {
  const [expiring, setExpiring] = useState(false);
  const [timeLeftInSeconds, setTimeLeftInSeconds] = useState(timeoutSeconds);
  const [timeUntilWarningInSeconds, setTimeUntilWarningInSeconds] =
    useState(warningSeconds);
  const [userResponded, setUserResponded] = useState(false);

  useEffect(() => {
    setTimeLeftInSeconds(timeoutSeconds);
    setTimeUntilWarningInSeconds(warningSeconds);
  }, [timeoutSeconds, warningSeconds]);
  useEffect(() => {
    setExpiring(
      extended
        ? timeLeftInSeconds - POST_EXTENSION_WARNING_SECONDS <= 0
        : timeUntilWarningInSeconds <= 0,
    );
    const interval = setInterval(() => {
      setTimeUntilWarningInSeconds(timeUntilWarningInSeconds - 1);
      setTimeLeftInSeconds(timeLeftInSeconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [extended, timeLeftInSeconds, timeUntilWarningInSeconds]);

  const handleAcceptOffer = (): void => {
    extendEngagement();
    setUserResponded(true);
  };

  const handleRejectOffer = (): void => {
    setUserResponded(true);
  };

  return {
    expiring,
    handleAcceptOffer,
    handleRejectOffer,
    info: getInfo(timeLeftInSeconds),
    showExtension: getShowExtension(
      engagementManaged,
      expiring,
      extended,
      extensionMinutes,
      timeLeftInSeconds,
      userResponded,
    ),
    offerText: getOfferText(extensionMinutes),
    title: getTitle(timeLeftInSeconds, expiring),
    timeLeftInSeconds,
  };
}
