import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { getExpoByAccessCode } from '../../../../../app/entities/expos/service';
import { IActionWithPayload } from '../../../../action-helpers';
import { SEND_ACCESS_CODE, landingPageActions } from '../../actions';

type Action = IActionWithPayload<typeof SEND_ACCESS_CODE, string>;

export default function* sendAccessCodeWorker(action: Action): SagaIterator {
  try {
    const expoId = yield call(getExpoByAccessCode, action.payload);
    yield put(push(`/${expoId}`));
    yield put(landingPageActions.sendAccessCodeSuccess());
  } catch (e) {
    yield put(landingPageActions.sendAccessCodeErrors());
  }
}
