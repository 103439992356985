import React from 'react';
import ReactTimezone, { Timezone, timezones } from 'react-timezone';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label } from '../../pov-common-ui';
import './styles.scss';

export type TimezoneInputProps = {
  disabled?: boolean;
  errors: string[];
  onChange: (timezone: Timezone) => void;
  value?: Timezone;
};

const TimezoneInput = ({
  disabled = false,
  errors,
  value = {
    name: '',
    label: '',
  },
  onChange,
}: TimezoneInputProps) => {
  const { t } = useTranslation();
  const handleChange = (name: string) => {
    const { offset }: Timezone = timezones.find((tz) => tz.name === name)!;
    onChange({ name, offset });
  };

  return (
    <FormGroup errors={errors}>
      <Label required={true} labelFor="timezone">
        {t('components.timezoneInput.timezone')}
      </Label>
      <ReactTimezone
        inputProps={{
          id: 'timezone',
          name: 'timezone',
          disabled,
        }}
        onChange={handleChange}
        value={value!.name}
        className="timezone-input"
      />
    </FormGroup>
  );
};

export default TimezoneInput;
