import {
  EngagementTimeoutValues,
  ExpoEventTypes,
  ExpoStates,
  ExpoStatesText,
} from './constants';
import { IEventType, IExpoBase, IExpoFilter } from './types';
import { imageAsset } from '../../common/utilities/imageAssets';

export const initialExpo: IExpoBase = {
  backgroundImage: '',
  description: '',
  end: null,
  gettingStarted: '',
  location: '',
  name: '',
  noOfSeats: '',
  start: null,
  engagementManaged: true,
  engagementTimeoutInMin: EngagementTimeoutValues.DEFAULT_HOURS * 60,
  engagementTimeoutWarningInMin: EngagementTimeoutValues.WARNING_MAX_MINS,
  maxEngagementExtensionInMin: undefined,
  feedbackSurveyManagement: false,
  feedbackName: undefined,
  feedbackOption: undefined,
  feedbackUrl: undefined,
  timezone: {
    label: '',
    name: '',
  },
  type: '',
};

export const expoEvents: IEventType[] = [
  {
    name: ExpoEventTypes.ciscoLive,
    value: ExpoEventTypes.ciscoLive,
    images: [
      {
        name: 'Image 1',
        value: imageAsset('cisco-live/background.png'),
      },
      {
        name: 'Image 2',
        value: imageAsset('cisco-live/background-2.png'),
      },
      {
        name: 'Image 3',
        value: imageAsset('cisco-live/background-3.png'),
      },
      {
        name: 'Image 4',
        value: imageAsset('cisco-live/background-4.png'),
      },
      {
        name: 'Image 5',
        value: imageAsset('cisco-live/background-5.png'),
      },
    ],
  },
  {
    name: ExpoEventTypes.dcloudCafe,
    value: ExpoEventTypes.dcloudCafe,
    images: [
      {
        name: 'Image 1',
        value: imageAsset('dcloud-cafe/background.png'),
      },
      {
        name: 'Image 2',
        value: imageAsset('dcloud-cafe/background-2.png'),
      },
      {
        name: 'Image 3',
        value: imageAsset('dcloud-cafe/background-3.png'),
      },
    ],
  },
  {
    name: ExpoEventTypes.ciscoGsx,
    altNames: ['cisco-gsx'],
    value: ExpoEventTypes.ciscoGsx,
    images: [
      {
        name: 'Image 1',
        value: imageAsset('cisco-gsx/background.png'),
      },
      {
        name: 'Image 2',
        value: imageAsset('cisco-gsx/background-2.png'),
      },
      {
        name: 'Image 3',
        value: imageAsset('cisco-gsx/background-3.png'),
      },
      {
        name: 'Image 4',
        value: imageAsset('cisco-gsx/background-4.png'),
      },
      {
        name: 'Image 5',
        value: imageAsset('cisco-gsx/background-5.png'),
      },
    ],
  },
  {
    name: ExpoEventTypes.sevt,
    value: ExpoEventTypes.sevt,
    images: [
      { name: 'Image 1', value: imageAsset('sevt/background.png') },
      { name: 'Image 2', value: imageAsset('sevt/background-2.png') },
      { name: 'Image 3', value: imageAsset('sevt/background-3.png') },
    ],
  },
  {
    name: ExpoEventTypes.testDrive,
    value: ExpoEventTypes.testDrive,
    images: [
      {
        name: 'Image 1',
        value: imageAsset('test-drive/background-1.jpg'),
      },
      {
        name: 'Image 2',
        value: imageAsset('test-drive/background-2.jpg'),
      },
      {
        name: 'Image 3',
        value: imageAsset('test-drive/background-3.jpg'),
      },
      {
        name: 'Image 4',
        value: imageAsset('test-drive/background-4.jpg'),
      },
      {
        name: 'Image 5',
        value: imageAsset('test-drive/background-5.jpg'),
      },
      {
        name: 'Image 6',
        value: imageAsset('test-drive/background-6.jpg'),
      },
      {
        name: 'Image 7',
        value: imageAsset('test-drive/background-7.jpg'),
      },
    ],
  },
  {
    name: ExpoEventTypes.webexOne,
    value: ExpoEventTypes.webexOne,
    images: [],
  },
];

export const expoFilters: IExpoFilter[] = [
  { name: ExpoStatesText.ALL, value: ExpoStates.ALL },
  { name: ExpoStatesText.PUBLISHED, value: ExpoStates.PUBLISHED },
  { name: ExpoStatesText.IN_PROGRESS, value: ExpoStates.IN_PROGRESS },
  { name: ExpoStatesText.UNPUBLISHED, value: ExpoStates.UNPUBLISHED },
  { name: ExpoStatesText.COMPLETE, value: ExpoStates.COMPLETE },
];
