import React, { useEffect } from 'react';
import { FullScreenLoader } from '../common/components/loader';
import { SwapAccessCode } from '../../redux/authenticated/types';

interface IAuthenticateProps {
  swapAccessCode: () => SwapAccessCode;
}

export function Authenticate({
  swapAccessCode,
}: IAuthenticateProps): JSX.Element {
  useEffect(() => {
    swapAccessCode();
  }, [swapAccessCode]);

  return <FullScreenLoader />;
}
