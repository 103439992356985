import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Store } from 'redux';
import { IApplicationState } from '../../redux/types';
import configuredErrorHandler from './error-handling';
import { getAccessToken } from '../../auth';

export const returnResponseData = <T>(response: AxiosResponse<T>) =>
  response.data;

const instance = axios.create({
  baseURL: window.__EXPO_CONFIG__.API_URL || '/not-defined',
});

export function configureKapuaAxiosInterceptors(
  store: Store<IApplicationState>,
) {
  instance.interceptors.response.use(undefined, configuredErrorHandler(store));

  instance.interceptors.request.use(
    (request: AxiosRequestConfig) => {
      const token = getAccessToken();
      request.headers.Authorization = `Bearer ${token}`;
      return request;
    },
    (error) => Promise.reject(error),
  );
}

export default instance;
