import { createSelector } from 'reselect';
import { IApplicationState } from '../../../types';
import { IEditState } from '../reducer';
import { EditTempExpoState } from '../reducer/temp-expo';
import { ISavedExpo } from '../../../../app/entities/expos/types';
import { ICsvContentState } from '../reducer/csv';
import {
  isEqualObj,
  trimLineBreak,
} from '../../../../app/common/utilities/helpers';

export const getEditState = (state: IApplicationState): IEditState =>
  state.admin.edit;

export const getTempExpo = (state: IApplicationState): EditTempExpoState =>
  getEditState(state).tempExpo;

export const getExpo = (state: IApplicationState): ISavedExpo | null =>
  getEditState(state).expo;

export const getCsvState = (state: IApplicationState): ICsvContentState =>
  getEditState(state).csv;

export const getEtag = (state: IApplicationState): string =>
  getEditState(state).etag;

export const getCsvContentMatchSuccessful = createSelector(
  getTempExpo,
  getCsvState,
  (expo: EditTempExpoState, csv: ICsvContentState): boolean => {
    if (!expo) return false;
    return (
      trimLineBreak(expo.csvContent) === trimLineBreak(csv.successfulCsvValue)
    );
  },
);

export const getDisableValidate = createSelector(
  getEditState,
  (state: IEditState): boolean => {
    if (!state.expo || !state.tempExpo) {
      return true;
    }

    if (!state.csv.valid) {
      return false;
    }

    return state.csv.successfulCsvValue === state.tempExpo.csvContent;
  },
);

export const getDisableSave = createSelector(
  getEditState,
  getCsvContentMatchSuccessful,
  (state: IEditState, csvContentMatch: boolean): boolean => {
    if (!state.expo || !state.tempExpo) return true;

    const tempExpoMatchesLoaded = isEqualObj(state.expo, state.tempExpo);

    if (!state.generalFormValid || tempExpoMatchesLoaded || !csvContentMatch) {
      return true;
    }

    return false;
  },
);

export const getCsvValue = createSelector(
  getTempExpo,
  (expo: EditTempExpoState): string => {
    if (!expo) return '';
    return expo.csvContent ?? '';
  },
);

export const getDisablePublish = createSelector(
  getEditState,
  (state: IEditState): boolean => {
    if (!state.expo || !state.tempExpo) return true;

    if (
      !state.capacity.capacityAvailable ||
      !state.generalFormValid ||
      !isEqualObj(state.expo, state.tempExpo)
    ) {
      return true;
    }

    return false;
  },
);

export const getDisableCheckCapacity = createSelector(
  getEditState,
  (state: IEditState): boolean => {
    if (
      !isEqualObj(state.expo, state.tempExpo) ||
      !state.capacity.capacityAvailable ||
      !state.csv.valid ||
      state.csv.successfulCsvValue !== state.tempExpo?.csvContent
    ) {
      return true;
    }

    return false;
  },
);

export const isCapacityChecked = (state: IApplicationState): boolean =>
  getEditState(state).capacity.capacityChecked;

export const getCapacityDetail = (
  state: IApplicationState,
): IFormattedCapacityContent[] => getEditState(state).capacity.capacityDetail;
