import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers, Reducer, CombinedState, AnyAction } from 'redux';
import modalsReducer from './modals/reducer';
import toastReducer from '../redux/toast/reducer/reducer';
import publicReducer from '../redux/public/reducer';
import uiReducer from '../redux/ui/reducer';
import adminReducer from './admin/reducer';
import { authReducer } from './authenticated/reducer';
import { IApplicationState } from './types';

export default (
  history: History,
): Reducer<CombinedState<IApplicationState>, AnyAction> =>
  combineReducers<IApplicationState>({
    authStatus: authReducer,
    admin: adminReducer,
    public: publicReducer,
    modals: modalsReducer,
    router: connectRouter(history),
    toast: toastReducer,
    ui: uiReducer,
  });
