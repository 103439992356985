import { buildUrl } from './utilities/helpers';
import { FeatureFlagsConfig } from 'dcloud-feature-flags-provider';
import { toastActions } from './redux/toast/actions';
import { ToastSeverity } from './app/common/pov-common-ui';
import store from './redux/store';

const featureFlagsConfig: FeatureFlagsConfig = {
  url: buildUrl(window.__EXPO_CONFIG__.API_URL, '/public/feature-toggles'),
  onError: () => {
    store.dispatch(
      toastActions.showToast(
        'Application Features',
        'Unable to fetch eXpo Feature Toggles, some features may be hidden.',
        ToastSeverity.WARNING,
      ),
    );
  },
};

export default featureFlagsConfig;
