import * as React from 'react';
import { convertToDashed } from '../../utilities/helpers';
import './styles.scss';
import { imageAsset } from '../../utilities/imageAssets';
import { ReactElement } from 'react';

interface IProps {
  expoName: string;
}

export default function ExpoLogo({ expoName }: IProps): ReactElement {
  const name: string = convertToDashed(expoName);

  return (
    <div className={`expo-logo expo-logo--${name}`}>
      <img src={imageAsset(`${name}/logo.png`)} alt={expoName} />
    </div>
  );
}
