import * as React from 'react';
import { Redirect, NavLink, Switch, Route } from 'react-router-dom';
import { Button, DataGrid } from '../../../common/pov-common-ui';
import { ROUTES } from '../../../common/constants';
import CategoryCard from '../../../common/components/category-card';
import Layout from '../../../common/components/layout';
import NavBar from '../../../common/components/nav-bar';
import DetailsPanel from './details';
import EngagementTimer from '../../../common/components/engagement-timer';
import useEngagementTimerState, {
  EngagementTimerTitles,
  IEngagementTimerProps,
} from '../../../common/components/engagement-timer/state';
import { IExpoWithCategories } from '../../../entities/expos/types';
import { columns } from './columns';
import { resourceColumns } from './resource-columns';
import './styles.scss';
import SessionInfo from './session-info';

export interface IProps {
  endDemo: () => void;
  engagementData?: IDemoEngagement;
  expo: IExpoWithCategories | null;
  extendEngagement: () => void;
  openAnyConnectModal: (demo: IScheduledDemo) => void;
  openResourcesModal: (demo: IScheduledDemo) => void;
}

export default function ScheduledDemo({
  endDemo,
  engagementData,
  expo,
  extendEngagement,
}: IProps): JSX.Element {
  const demo = engagementData?.demo as IScheduledDemo;
  const engagementManaged = engagementData?.engagementManaged as boolean;
  const extended = engagementData?.extended as boolean;
  const extensionMinutes =
    engagementData?.maxEngagementExtensionInMin as number;
  const timeoutSeconds = engagementData?.timeoutInSeconds as number;
  const warningSeconds = engagementData?.warningInSeconds as number;

  const { timeLeftInSeconds, ...timerProps }: Partial<IEngagementTimerProps> =
    useEngagementTimerState(
      engagementManaged,
      extended,
      extendEngagement,
      extensionMinutes,
      timeoutSeconds,
      warningSeconds,
    );

  const viewSessionUrl = engagementData?._links.viewSession?.href ?? '';
  const sessionId = demo?.session.dCloudSessionId?.toString() ?? '';

  return !engagementData ? (
    <Redirect to={ROUTES.EXPO.replace(':id', expo!.uid)} />
  ) : (
    <div className="u-with-sticky-header">
      <div className="scheduled-demo-screen">
        <NavBar
          right={
            <Button color="primary" id="end-demo-engagement" onClick={endDemo}>
              {timeLeftInSeconds <= 0
                ? 'Return to eXpo Homepage'
                : 'Logout and End Session'}
            </Button>
          }
        />
        {timeLeftInSeconds > 0 && <EngagementTimer {...timerProps} />}
        <Layout url={expo!.backgroundImage}>
          <CategoryCard title={demo.name}>
            {timeLeftInSeconds <= 0 ? (
              <div className="session-expired">
                <p>{EngagementTimerTitles.EXPIRED}</p>
                <Button
                  color="primary"
                  id="leave-ended-engagement"
                  onClick={endDemo}
                >
                  Ok
                </Button>
              </div>
            ) : (
              <>
                <SessionInfo id={sessionId} viewUrl={viewSessionUrl} />
                <ul
                  role="tablist"
                  className="tabs info-tabs session-detail-tabs"
                >
                  <li>
                    <NavLink
                      exact={true}
                      to={ROUTES.SCHEDULED_DEMO.replace(':id', expo!.uid)}
                      activeClassName="active"
                    >
                      Network
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={ROUTES.SCHEDULED_DEMO_DETAILS.replace(
                        ':id',
                        expo!.uid,
                      )}
                      activeClassName="active"
                    >
                      Details
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact={true}
                      to={ROUTES.SCHEDULED_DEMO_RESOURCES.replace(
                        ':id',
                        expo!.uid,
                      )}
                      activeClassName="active"
                    >
                      Resources
                    </NavLink>
                  </li>
                </ul>

                <Switch>
                  <Route
                    exact={true}
                    path={ROUTES.SCHEDULED_DEMO.replace(':id', expo!.uid)}
                    render={() => (
                      <DataGrid
                        data={demo.session.networks}
                        columns={columns}
                        sortable={true}
                        minRows={1}
                        pageSize={500}
                      />
                    )}
                  />
                  <Route
                    path={ROUTES.SCHEDULED_DEMO_DETAILS.replace(
                      ':id',
                      expo!.uid,
                    )}
                    component={() => (
                      <DetailsPanel
                        uid={expo!.uid}
                        details={demo.session.details}
                      />
                    )}
                  />
                  <Route
                    exact={true}
                    path={ROUTES.SCHEDULED_DEMO_RESOURCES.replace(
                      ':id',
                      expo!.uid,
                    )}
                    component={() => (
                      <div className="session-detail__resources">
                        <h3 className="session-detail-heading">
                          Documentation
                        </h3>
                        <DataGrid
                          data={demo.resources}
                          columns={resourceColumns}
                          sortable={true}
                          minRows={1}
                          pageSize={500}
                        />
                      </div>
                    )}
                  />
                </Switch>
              </>
            )}
          </CategoryCard>
        </Layout>
      </div>
    </div>
  );
}
