import { Button } from '../../pov-common-ui';
import * as React from 'react';
import DemoItem from '../demo-item';

export interface IProps {
  id: string;
  onExploreClick: () => void;
  thumbnail: string;
  title: string;
}

function DemoExploreItem(props: IProps) {
  return (
    <DemoItem
      id={props.id}
      thumbnail={props.thumbnail}
      content={<h2 className="demo-item__title">{props.title}</h2>}
      buttons={
        <Button
          id={`${props.id}_explore`}
          color="secondary"
          onClick={props.onExploreClick}
        >
          Explore
        </Button>
      }
    />
  );
}

export default DemoExploreItem;
