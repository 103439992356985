import { SagaIterator } from 'redux-saga';
import { select, call, put } from 'redux-saga/effects';
import { getEngagementUid } from '../../selectors';
import { extendEngagement } from '../../../../../app/entities/expos/service';
import { engagementActions } from '../../actions';
import { globalUiActions } from '../../../../../redux/ui/loader/actions';
import { modalActions } from '../../../../../redux/modals/actions';
import { MODAL_NAMES } from '../../../../../app/common/components/modal/constants';

export function* extendEngagementWorker(): SagaIterator {
  try {
    const uid = yield select(getEngagementUid);
    yield put(globalUiActions.showLoader());
    const extend = yield call(extendEngagement, uid);
    if (extend.status === 201) {
      yield put(engagementActions.fetchEngagement());
    }
  } catch (e) {
    put(
      modalActions.openModal(MODAL_NAMES.GENERIC_ERROR, {
        errors: [
          {
            message: e[0].message,
          },
        ],
        title: 'Extension Error',
      }),
    );
  } finally {
    yield put(globalUiActions.hideLoader());
  }
}
