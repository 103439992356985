import * as React from 'react';
import CategoryCard from '../../../../common/components/category-card';
import Demos from '../demos';

interface IProps {
  categories: ICategorizedDemos[];
  onExploreClick: (demo: IDemo) => void;
}

export default function Categories({ categories, onExploreClick }: IProps) {
  return (
    <div className="masonry expo-preview__masonry">
      {categories.map((category) => (
        <CategoryCard title={category.name} key={category.name}>
          <Demos demos={category.demos} onExploreClick={onExploreClick} />
        </CategoryCard>
      ))}
    </div>
  );
}
