import { Button, TextArea } from '../../../../../common/pov-common-ui';
import * as React from 'react';

type DefaultProps = Partial<typeof defaultProps>;

export type Props = {
  csvContent?: string;
  csvErrors: string[];
  disableValidate: boolean;
  setCsvValue: (value: string) => void;
  validate: () => void;
} & DefaultProps;

const defaultProps = {
  csvContent: '',
};

export default function AddContentForm({
  csvContent,
  csvErrors,
  disableValidate,
  setCsvValue,
  validate,
}: Props) {
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    validate();
  };

  return (
    <form className="content-card__form" onSubmit={onSubmit}>
      <TextArea
        errors={csvErrors}
        label="Add Content"
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setCsvValue(e.target.value);
        }}
        name="addContent"
        id="addContent"
        value={csvContent}
      />
      <div className="help-block text-muted">
        <span>
          To add content please use the following syntax, putting each entry on
          a new line. <br />
          For SCHEDULED: fields representing DEMO_ID, DATACENTER, CATEGORY,
          DEMO_TYPE(S), SESSIONS, CONTENT POOL (Optional) e.g. 3442356, RTP,
          Security, S, 30, My Selected Pool <br />
          For INSTANT: fields representing HANDLE, DATACENTER, CATEGORY,
          DEMO_TYPE(I) e.g. cisco-duo-v1, RTP, Security, I
        </span>
      </div>
      <Button
        color="primary"
        id="validate"
        type="submit"
        disabled={disableValidate}
      >
        Validate
      </Button>
    </form>
  );
}
