import { ConnectedRouter } from 'connected-react-router';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import App from './app';
import history from './history';
import store from './redux/store';
import { FeatureFlagsProvider } from 'dcloud-feature-flags-provider';
import featureFlagsConfig from './feature-flags';
import './styles/cisco-ui/dist/css/cui-standard.css';
import './styles/main.scss';
import i18n from './i18n';

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <I18nextProvider i18n={i18n}>
        <FeatureFlagsProvider config={featureFlagsConfig}>
          <App />
        </FeatureFlagsProvider>
      </I18nextProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root') as HTMLElement,
);
