import { Button } from '../../../pov-common-ui';
import * as React from 'react';
import ModalInner from '../modal-inner';

export interface IProps {
  closeModal: () => void;
  deleteExpo: (uid: string) => void;
  uid: string;
}

function DeleteExpo({ deleteExpo, closeModal, uid }: IProps) {
  return (
    <ModalInner
      title="Delete"
      content="Are you sure you want to Delete this eXpo"
      buttons={
        <>
          <Button
            onClick={() => deleteExpo(uid)}
            color="primary"
            id={`delete-modal-delete-btn-${uid}`}
          >
            Delete
          </Button>
          <Button
            onClick={closeModal}
            color="secondary"
            id={`delete-modal-cancel-btn-${uid}`}
          >
            Cancel
          </Button>
        </>
      }
    />
  );
}

export default DeleteExpo;
