import { HIDE_LOADER, SHOW_LOADER, Actions } from '../actions';

export type GlobalLoaderState = Readonly<typeof initialState>;

export const initialState = {
  show: false,
};

export default function globalLoaderReducer(
  state: GlobalLoaderState = initialState,
  action: Actions,
) {
  if (action.type === SHOW_LOADER) {
    return { ...state, show: true };
  }

  if (action.type === HIDE_LOADER) {
    return { ...state, show: false };
  }

  return state;
}
