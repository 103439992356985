import moment from 'moment';
import {
  Card,
  CardHeader,
  DateRangePicker,
  Dropdown,
  FormGroup,
  Label,
  TextArea,
  TextInput,
} from '../../../common/pov-common-ui';
import * as React from 'react';
import { Timezone } from 'react-timezone';
import TimezoneInput from '../../../common/components/time-zone-input';
import { isPublishedExpo } from '../../../common/utilities/expo';
import { ExpoEventTypes, ExpoStates } from '../../../entities/expos/constants';
import { IExpoBase } from '../../../entities/expos/types';
import EngagementDurationControls from '../engagement-duration-controls';
import {
  backgroundImagesOptions,
  CUSTOM_IMAGE_VALUE,
  expoTypeOptions,
} from '../../../entities/expos/utilities';
import useGeneralFormState from './state';
import { editActions } from '../../../../redux/admin/edit/actions';
import { createActions } from '../../../../redux/admin/create/actions';
import './styles.scss';
import { useCallback, useState } from 'react';
import FeedbackSurveyManagement from '../feedback-survey-management/container';

export type GeneralFormProps = {
  expoState?: keyof typeof ExpoStates | undefined;
  initialValues: IExpoBase;
  isNewExpo: boolean;
  updateFields:
    | typeof editActions.onFieldUpdate
    | typeof createActions.updateExpo;
  onFormValidityChange: (valid: boolean) => void;
};

export default function GeneralForm({
  expoState,
  initialValues,
  isNewExpo,
  onFormValidityChange,
  updateFields,
}: GeneralFormProps) {
  const {
    form,
    updateDateFields,
    updateExpoType,
    updateField,
    updateBackgroundImage,
    values,
  } = useGeneralFormState(initialValues, onFormValidityChange, updateFields);

  const backgroundImageSelectValue = useCallback(
    (backgroundImage: string): string => {
      const images = backgroundImagesOptions(form.type.value as ExpoEventTypes);
      if (images.find((image) => image.value === backgroundImage)) {
        return backgroundImage;
      }
      return CUSTOM_IMAGE_VALUE;
    },
    [form.type],
  );

  const [backgroundImageSelect, setBackgroundImageSelect] = useState<string>(
    backgroundImageSelectValue(values.backgroundImage),
  );
  const [showCustomImageField, setShowCustomImageField] = useState<boolean>(
    backgroundImageSelect === CUSTOM_IMAGE_VALUE,
  );

  const setBackgroundImageSelectFormState = (image: string): void => {
    setBackgroundImageSelect(image);
    setShowCustomImageField(image === CUSTOM_IMAGE_VALUE);
  };

  const updateSelectedExpoType = (
    name: string,
    value: ExpoEventTypes,
  ): void => {
    updateExpoType(name, value);
    setBackgroundImageSelectFormState('');
  };

  const updateSelectedBackgroundImage = (name: string, value: string): void => {
    updateBackgroundImage(name, value);
    const selectedValue = backgroundImageSelectValue(value);
    setBackgroundImageSelectFormState(selectedValue);
  };

  const isPublished = isPublishedExpo(expoState);

  return (
    <div className="general-form">
      <Card>
        <CardHeader title="General" alignTitle="center" />
        <div className="section">
          <div className="row">
            <div className="col-md-4">
              <TextInput {...form.name} label="Name" required={true} />
            </div>
            <div className="col-md-2">
              <FormGroup inline={true} errors={form.type.errors}>
                <Label labelFor="type">Type</Label>
                <Dropdown
                  {...form.type}
                  onChange={updateSelectedExpoType}
                  options={expoTypeOptions()}
                  selected={values.type ?? ''}
                />
              </FormGroup>
            </div>
            <div className="col-md-2">
              <FormGroup
                inline={true}
                errors={
                  values.type && !showCustomImageField
                    ? form.backgroundImage.errors
                    : []
                }
              >
                <Label labelFor="backgroundImageSelect">Background Image</Label>
                <Dropdown
                  id="backgroundImageSelect"
                  name="backgroundImageSelect"
                  disabled={!values.type}
                  onChange={updateSelectedBackgroundImage}
                  options={backgroundImagesOptions(
                    form.type.value as ExpoEventTypes,
                  )}
                  selected={backgroundImageSelect ?? ''}
                  fixedHeight={true}
                />
              </FormGroup>
            </div>
            <div className="col-md-4">
              <FormGroup errors={[...form.end.errors, ...form.start.errors]}>
                <Label labelFor="dates">Dates</Label>
                <DateRangePicker
                  disabled={isPublished}
                  endDate={values.end ? moment(values.end) : null}
                  endDateName="end"
                  onDateRangeChange={updateDateFields}
                  startDate={values.start ? moment(values.start) : null}
                  startDateName="start"
                />
              </FormGroup>
            </div>
          </div>
          {showCustomImageField && (
            <div className="row">
              <div className="col-md-12">
                <TextInput
                  {...form.backgroundImage}
                  label="Custom Background Image URL"
                  required={true}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-4">
              <TextInput {...form.description} label="Description" />
            </div>
            <div className="col-md-2">
              <TextInput
                {...form.noOfSeats}
                disabled={isPublished}
                required={true}
                label="Seats"
                type="number"
                value={form.noOfSeats.value as string}
              />
            </div>
            <div className="col-md-2">
              <TextInput {...form.location} label="Location" required={true} />
            </div>
            <div className="col-md-4">
              <TimezoneInput
                {...form.timezone}
                disabled={isPublished}
                onChange={(timezone: Timezone) => {
                  updateField('timezone', timezone);
                }}
              />
            </div>
          </div>
          <EngagementDurationControls
            isNewExpo={isNewExpo}
            maxExtensionFormConfig={form.maxEngagementExtensionInMin}
            timeoutFormConfig={form.engagementTimeoutInMin}
            timeoutWarningFormConfig={form.engagementTimeoutWarningInMin}
            updateField={updateField}
            values={values}
          />
          <FeedbackSurveyManagement
            fieldOption={form.feedbackOption}
            fieldUrl={form.feedbackUrl}
            updateField={updateField}
            values={values}
          />
        </div>
        <div className="row">
          <div className="col-sm-12">
            <TextArea
              {...form.gettingStarted}
              label="Getting Started"
              maxCharCount={500}
              required={true}
              rows={5}
            />
          </div>
        </div>
      </Card>
    </div>
  );
}
