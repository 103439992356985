import * as React from 'react';
import Layout from '../../../common/components/layout';
import LogoGettingStartedRow, {
  IGettingStartedRowButton,
} from '../../../common/components/logo-getting-started-row';
import Categories from './categories';
import InProgress from './in-progress';
import { IExpoWithCategories } from '../../../entities/expos/types';
import './styles.scss';

export interface IProps {
  expo: IExpoWithCategories | null;
  endDemo: () => void;
  engagementData?: IDemoEngagement;
  engagementUid: string | null;
  openGettingStartedModel: (content: string) => void;
  openExploreDemoModal: (demo: IDemo, expoUid: string) => void;
}

export default function ExpoMain({
  expo,
  endDemo,
  engagementData,
  engagementUid,
  openGettingStartedModel,
  openExploreDemoModal,
}: IProps) {
  if (!expo) return null;

  const additionalButtons: IGettingStartedRowButton[] = [];
  if (
    expo.feedbackSurveyManagement &&
    expo.feedbackOption &&
    expo.feedbackName &&
    expo.feedbackUrl
  ) {
    additionalButtons.push({
      id: expo.feedbackOption.toLowerCase(),
      name: expo.feedbackName,
      url: expo.feedbackUrl,
    });
  }

  return (
    <Layout url={expo.backgroundImage}>
      <LogoGettingStartedRow
        additionalButtons={additionalButtons}
        end={expo.end}
        onGetStartedClick={() => openGettingStartedModel(expo.gettingStarted)}
        type={expo.type}
        start={expo.start}
        location={expo.location}
      />
      {engagementUid && engagementData ? (
        <InProgress
          endDemo={endDemo}
          demoName={engagementData.demo.name}
          demoType={engagementData.demo.type}
          expoId={expo.uid}
        />
      ) : (
        <Categories
          categories={expo.categories}
          onExploreClick={(demo: IDemo) => openExploreDemoModal(demo, expo.uid)}
        />
      )}
    </Layout>
  );
}
