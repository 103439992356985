import classnames from 'classnames';
import * as React from 'react';
import ReactTable from 'react-table';
import type { SortingRule, SubComponentFunction } from 'react-table';
import 'react-table/react-table.css';
import './override.styles.scss';
import './styles.scss';

export enum GridPadding {
  regular = 'regular',
  compressed = 'compressed',
  loose = 'loose',
}

interface IOptionalProps {
  SubComponent: SubComponentFunction;
}

type DefaultProps = Partial<Readonly<typeof defaultProps>> &
  Partial<IOptionalProps>;

export type Props<T, C> = {
  data: T;
  columns: C;
} & DefaultProps;

const defaultProps = {
  defaultSorted: [] as SortingRule[],
  gridPadding: GridPadding.regular,
  minRows: 10,
  noDataText: 'No data to display',
  pageSize: 10,
  resizable: false,
  showPageSizeOptions: false,
  showPagination: false,
  sortable: true,
};

// tslint:disable-next-line:no-any
export default function DataGrid<T extends any[], C extends any[]>({
  columns,
  defaultSorted,
  data,
  minRows,
  noDataText,
  gridPadding,
  pageSize,
  resizable,
  showPageSizeOptions,
  showPagination,
  sortable,
  SubComponent,
}: Props<T, C>) {
  const classes = classnames('expandable-table', gridPadding);

  return (
    <ReactTable
      className={classes}
      defaultSorted={defaultSorted}
      minRows={minRows}
      noDataText={noDataText}
      data={data}
      columns={columns}
      pageSize={pageSize}
      showPageSizeOptions={showPageSizeOptions}
      showPagination={showPagination}
      sortable={sortable}
      resizable={resizable}
      SubComponent={SubComponent}
    />
  );
}

DataGrid.defaultProps = defaultProps;
