import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ExpoStates } from '../../entities/expos/constants';
import { isExpoTypeInvalid } from '../../entities/expos/utilities';
import Header from '../components/header';
import GeneralForm from '../components/general-form';
import Layout from '../../common/components/layout';
import Content from './components/content/container';
import { editActions } from '../../../redux/admin/edit/actions';
import { ISavedExpo, IExpoBase } from '../../entities/expos/types';
import { returnGeneralFormFields } from './utilities';
import { MAIN_BACKGROUND_IMAGE } from '../../common/utilities/imageAssets';
import { ReactElement } from 'react';

export interface IProps extends RouteComponentProps<{ id: string }> {
  disablePublish: boolean;
  disableSave: boolean;
  expo: ISavedExpo | null;
  fetchExpo: typeof editActions.fetchExpo;
  onFieldUpdate: typeof editActions.onFieldUpdate;
  updateGeneralFormValidity: typeof editActions.updateGeneralFormValidity;
  reset: typeof editActions.reset;
  saveExpo: typeof editActions.saveExpo;
}

export default function Edit({
  expo,
  disablePublish,
  disableSave,
  fetchExpo,
  match,
  onFieldUpdate,
  reset,
  saveExpo,
  updateGeneralFormValidity,
}: IProps): ReactElement | null {
  const generalFormValues: IExpoBase = React.useMemo(
    () => returnGeneralFormFields(expo),
    [expo],
  );

  const { id } = match.params;

  React.useEffect(() => {
    fetchExpo(id);
  }, [id, fetchExpo]);

  React.useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  if (!expo) {
    return null;
  }

  const expoState: keyof typeof ExpoStates = expo.state;
  const isExpoUnpublished = expo.state === ExpoStates.UNPUBLISHED;

  if (isExpoTypeInvalid(generalFormValues.type)) {
    generalFormValues.type = '';
    generalFormValues.backgroundImage = '';
  }

  return (
    <div data-testid="edit">
      <Header
        onSave={saveExpo}
        disableSave={disableSave}
        disablePublish={disablePublish}
        showPublish={!!expo._links.publish}
        uid={id}
      />
      <Layout
        url={
          isExpoTypeInvalid(generalFormValues.type)
            ? MAIN_BACKGROUND_IMAGE
            : expo.backgroundImage
        }
      >
        <GeneralForm
          expoState={expoState}
          initialValues={generalFormValues}
          isNewExpo={false}
          onFormValidityChange={updateGeneralFormValidity}
          updateFields={onFieldUpdate}
        />
        {isExpoUnpublished && (
          <Content showCheckCapacity={!!expo._links.capacity} />
        )}
      </Layout>
    </div>
  );
}
