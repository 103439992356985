import { SagaIterator } from 'redux-saga';
import { ToastSeverity } from '../../../../../app/common/pov-common-ui';
import { call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { createExpo } from '../../../../../app/entities/expos/service';
import { ROUTES } from '../../../../../app/common/constants';
import { createActions } from '../../actions';
import { toastActions } from '../../../../toast/actions';
import { globalUiActions } from '../../../../ui/loader/actions';
import { getExpo } from '../../selectors';
import {
  IExpoBase,
  IExpoCreateRequest,
  IExpoResponse,
} from '../../../../../app/entities/expos/types';
import { mapToExpoCreateRequest } from '../../../../../app/entities/expos/utilities';

export const CREATED_TOAST_MESSAGE = 'eXpo created';

export default function* createExpoWorker(): SagaIterator {
  try {
    yield put(globalUiActions.showLoader());
    const expo: IExpoBase = yield select(getExpo);
    const payload: IExpoCreateRequest = mapToExpoCreateRequest(expo);
    const response: IExpoResponse = yield call(createExpo, payload);
    yield put(push(ROUTES.ADMIN_EDIT.replace(':id', response.uid)));
    yield put(createActions.createExpoSuccess());
    yield put(
      toastActions.showToast(
        'Success',
        CREATED_TOAST_MESSAGE,
        ToastSeverity.SUCCESS,
      ),
    );
  } catch (e) {
    throw e;
  } finally {
    yield put(globalUiActions.hideLoader());
  }
}
