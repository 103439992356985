import * as React from 'react';
import { DataGrid } from '../../../../common/pov-common-ui';
import { engagementColumns } from './columns';

export interface IProps {
  engagements: IEngagementDetails[];
  expoUid: string;
  fetchEngagements: () => void;
  showEndEngagementModal: (engagementId: string) => void;
}

export default function Engagements({
  engagements,
  fetchEngagements,
  expoUid,
  showEndEngagementModal,
}: IProps) {
  React.useEffect(() => {
    fetchEngagements();
  }, [expoUid, fetchEngagements]);

  return (
    <DataGrid
      columns={engagementColumns(showEndEngagementModal)}
      data={engagements}
      sortable={true}
      minRows={1}
      pageSize={500}
    />
  );
}
