import moment from 'moment/moment';

export const convertToDashed = (str: string): string =>
  str.toLowerCase().replace(' ', '-');

export const deepClone = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

export const stringifyNumberValues = <T>(obj: T) => {
  const items = Object.keys(obj).map((key) => {
    let value = obj[key];

    if (typeof value === 'number') {
      value = String(value);
    }

    return { [key]: value };
  });

  return Object.assign({}, ...items) as T;
};

export const strEquals = (str1 = '', str2 = ''): boolean => str1 === str2;

export const isEqualObj = <O1, O2>(obj1: O1, obj2: O2) =>
  JSON.stringify(obj1) === JSON.stringify(obj2);

export const trimLineBreak = (str = ''): string => str.replace(/\r?\n|\r/g, '');

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const copyTextToClipboard = (text: string) => {
  const el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const convertDateToString = (date: moment.Moment | null) =>
  date ? date.toISOString() : '';
