import * as React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import Modal from './common/components/modal/modal';
import ExpoToast from './common/components/expo-toast';
import GlobalLoader from './common/components/global-loader';
import Router from './router';
import './styles.scss';

export function App() {
  return (
    <div className="App">
      <Router />
      <Modal />
      <ExpoToast />
      <GlobalLoader />
    </div>
  );
}

export default compose<typeof App>(withRouter)(App);
