import React, { ReactElement } from 'react';
import { Dropdown, Label } from '../../../../../../common/pov-common-ui';

export type ContentPoolDropdownProps = {
  onChange: (dropdownName: string, contentPoolId: string) => void;
  selected: string | number | undefined;
  options: IOption[] | undefined;
  isVisible: boolean | undefined;
};

export default function ContentPoolDropdown({
  onChange,
  selected,
  options,
  isVisible,
}: ContentPoolDropdownProps): ReactElement {
  if (!isVisible) {
    return <></>;
  }

  return (
    <>
      <Label labelFor="admin_cp_filter_to_rebalance" required={false}>
        Content Pool
      </Label>
      <Dropdown
        id="admin_cp_filter_to_rebalance"
        name="admin_cp_filter_to_rebalance"
        onChange={onChange}
        selected={selected}
        options={options}
      />
    </>
  );
}
