import * as React from 'react';
import './styles.scss';

export function Loader(): JSX.Element {
  return (
    <div className="loading-spinner loading-spinner--indigo">
      <div className="wrapper">
        <div className="wheel" />
      </div>
    </div>
  );
}

export function CenteredLoader(): JSX.Element {
  return (
    <div className="centered-loader">
      <Loader />
    </div>
  );
}

export function FullScreenLoader(): JSX.Element {
  return (
    <div className="full-screen-loader">
      <CenteredLoader />
    </div>
  );
}
