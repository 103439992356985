import moment from 'moment';
import { useCallback, useEffect } from 'react';
import useFormValidation from '../../../../common/hooks/useFormValidation';
import { ExpoEventTypes } from '../../../../entities/expos/constants';
import { IExpoBase } from '../../../../entities/expos/types';
import { CUSTOM_IMAGE_VALUE } from '../../../../entities/expos/utilities';
import validation from '../validation';
import { editActions } from '../../../../../redux/admin/edit/actions';
import { createActions } from '../../../../../redux/admin/create/actions';

export interface IDates {
  [key: string]: string | null;
}

export function formatExpoDate(
  date: string | null,
  method: 'startOf' | 'endOf' = 'startOf',
): string {
  if (!date) return '';
  return moment(new Date(date))
    .utc(true)
    [method]('day')
    .toISOString()
    .split('.')[0];
}

export default function useGeneralFormState(
  initialValues: IExpoBase,
  onFormValidityChange: (valid: boolean) => void,
  updateExpo:
    | typeof editActions.onFieldUpdate
    | typeof createActions.updateExpo,
) {
  const { form, updateField, updateMultipleFields, values, valid } =
    useFormValidation(initialValues, validation, updateExpo);

  useEffect(() => {
    onFormValidityChange(valid);
  }, [valid, onFormValidityChange]);

  const updateDateFields = (dates: IDates) => {
    const start = formatExpoDate(dates.start);
    const end = formatExpoDate(dates.end, 'endOf');
    updateMultipleFields({ end, start });
  };

  const updateExpoType = useCallback(
    (name: string, value: ExpoEventTypes) => {
      const newValues: Partial<IExpoBase> = {
        [name]: value,
      };

      if (values.backgroundImage) {
        newValues.backgroundImage = '';
      }

      updateMultipleFields(newValues);
    },
    [values, updateMultipleFields],
  );

  const updateBackgroundImage = useCallback(
    (name: string, value: string) => {
      if (value === CUSTOM_IMAGE_VALUE) {
        updateField('backgroundImage', '');
      } else {
        updateField('backgroundImage', value);
      }
    },
    [updateField],
  );

  return {
    form,
    updateDateFields,
    updateExpoType,
    updateField,
    updateBackgroundImage,
    values,
  };
}
