import { IAccessCode, ISavedExpo } from '../../../../app/entities/expos/types';
import {
  Actions,
  DELETE_EXPO_SUCCESS,
  FETCH_ACCESS_CODE_SUCCESS,
  FETCH_EXPOS,
  FETCH_EXPOS_SUCCESS,
  RESET,
  SET_FILTER,
  SET_SORT,
  UNPUBLISH_EXPO_SUCCESS,
} from '../actions';
import {
  ExpoDateFilters,
  ExpoStates,
} from '../../../../app/entities/expos/constants';

export type ExposState = typeof initialState;

export const initialState = {
  fetched: false,
  sortBy: ExpoDateFilters.DESC,
  filterBy: ExpoStates.ALL,
  items: [] as ISavedExpo[],
  loading: false,
  accessCode: {} as IAccessCode,
};

export default function exposReducer(
  state: ExposState = initialState,
  action: Actions,
): ExposState {
  switch (action.type) {
    case FETCH_ACCESS_CODE_SUCCESS:
      return { ...state, accessCode: action.payload };

    case FETCH_EXPOS:
      return { ...state, loading: true };

    case DELETE_EXPO_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.filter((item) => item.uid !== action.payload),
      };

    case FETCH_EXPOS_SUCCESS:
      return { ...state, fetched: true, loading: false, items: action.payload };

    case RESET:
      return {
        ...state,
        sortBy: ExpoDateFilters.DESC,
        filterBy: ExpoStates.ALL,
      };

    case SET_FILTER:
      return { ...state, filterBy: action.payload };

    case SET_SORT:
      return { ...state, sortBy: action.payload };

    case UNPUBLISH_EXPO_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.uid === action.payload) {
            // eslint-disable-next-line no-unused-vars
            const { unpublish, ...links } = item._links;

            return {
              ...item,
              state: ExpoStates.UNPUBLISHED,
              _links: links,
            };
          }

          return item;
        }),
      };

    default:
      return state;
  }
}
