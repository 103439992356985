import { Button } from '../../../pov-common-ui';
import * as React from 'react';
import ModalInner from '../modal-inner';
import { useTranslation } from 'react-i18next';

export interface IProps {
  closeModal: () => void;
  publishExpo: (uid: string) => void;
  uid: string;
}

function PublishExpo({ publishExpo, closeModal, uid }: IProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <ModalInner
      title={t('publishExpo.title')}
      content={
        <>
          <p className="text-italic">{t('publishExpo.content.note')}</p>
          <p>{t('publishExpo.content.question')}</p>
        </>
      }
      buttons={
        <>
          <Button
            onClick={() => publishExpo(uid)}
            color="primary"
            id={`publish-modal-publish-btn-${uid}`}
          >
            {t('publishExpo.publish')}
          </Button>
          <Button
            onClick={closeModal}
            color="secondary"
            id={`publish-modal-cancel-btn-${uid}`}
          >
            {t('publishExpo.cancel')}
          </Button>
        </>
      }
    />
  );
}

export default PublishExpo;
