import { DataGrid, Tab, Tabs } from '../../../../../common/pov-common-ui';
import * as React from 'react';
import columns from './columns';
import './styles.scss';
import TabLabel from './tab-label';
import summaryColumns from './summaryColumns';
import detailColumns from './detailColumns';

export interface IProps {
  data: IFormattedCapacityContent[];
}

export interface IState {
  currentTab: string;
}

function setInitialState(props: IProps): IState {
  return {
    currentTab: props.data[0].name,
  };
}

export default class CapacityDetail extends React.PureComponent<IProps> {
  public state = setInitialState(this.props);

  public onTabChange = (title: string): void => {
    this.setState({ currentTab: title });
  };

  public render() {
    const { data } = this.props;

    return (
      <>
        <Tabs onTabChange={this.onTabChange} id="capacity-detail-table">
          {data.map((dataCenter: IFormattedCapacityContent) => (
            <Tab
              title={
                <TabLabel errors={dataCenter.errorCount}>
                  {dataCenter.name}
                </TabLabel>
              }
              key={dataCenter.name}
            >
              {dataCenter.summary.length && (
                <div className="capacity-summary-table">
                  <DataGrid
                    columns={summaryColumns(dataCenter.summary)}
                    data={dataCenter.summary}
                    sortable={true}
                    minRows={1}
                    pageSize={500}
                  />
                </div>
              )}
              <DataGrid
                columns={columns(dataCenter.demos)}
                data={dataCenter.demos}
                SubComponent={(row) =>
                  !!row.original.details.length && (
                    <div className="detail-sub-table">
                      <DataGrid
                        columns={detailColumns(row.original.details)}
                        data={row.original.details}
                        sortable={true}
                        minRows={1}
                        pageSize={500}
                      />
                    </div>
                  )
                }
                sortable={true}
                minRows={1}
                pageSize={500}
              />
            </Tab>
          ))}
        </Tabs>
      </>
    );
  }
}
