import * as React from 'react';
import ExpoLoadingCard from '../expo-loading-card';
import './styles.scss';

export default function ExposLoading() {
  return (
    <div className="row expos-loading">
      <ExpoLoadingCard uniquekey="loader-1" />
      <ExpoLoadingCard uniquekey="loader-2" />
      <ExpoLoadingCard uniquekey="loader-3" />
      <ExpoLoadingCard uniquekey="loader-4" />
    </div>
  );
}
