import { IApplicationState } from '../../../types';
import { getPublicState } from '../../selectors';
import { IEngagementsState } from '../reducer';

export const getEngagementsState = (
  state: IApplicationState,
): IEngagementsState => getPublicState(state).engagements;

export const getEngagementUid = (state: IApplicationState): string | null =>
  getEngagementsState(state).uid;

export const getEngagementData = (
  state: IApplicationState,
): IDemoEngagement | undefined => getEngagementsState(state).data;

export const getEngagementExpoUid = (state: IApplicationState): string | null =>
  getEngagementsState(state).expoUid;
