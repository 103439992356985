import * as React from 'react';
import { targetColumns } from './columns';
import { infoActions } from '../../../../../redux/admin/info/actions';
import { Dropdown, Label } from '../../../../common/pov-common-ui';
import RebalanceControls from '../rebalance-controls/container';
import { IDataCenter } from '../../../../../../src/app/entities/expos/types';
import RebalanceTabContent from './components/RebalanceTabContent/RebalanceTabContent';
import { determineDataCenterConnectivity } from './utils/utils';
import { useRebalanceTabVisibility } from './hooks/use-rebalance-tab-visibility';
import ContentPoolDropdown from './components/ContentPoolDropdown/ContentPoolDropdown';

interface IFilter {
  name: string;
  value: string;
}

interface IProps {
  demos: IDemoDetails[];
  scheduledDemos: IDemoDetails[];
  filterBy: IRebalanceFilterValues;
  setFilter: typeof infoActions.setFilter;
  setTargetState: typeof infoActions.setTargetState;
  dcFilters: IFilter[];
  contentPoolFiltersMap: Map<string, IFilter[]>;
  inputTargetState: ITargetState;
  expoUid: string;
  isRebalanceTabVisible: boolean | undefined;
  dataCenters: IDataCenter[];
  setIsRebalanceTabVisible: typeof infoActions.setIsRebalanceTabVisible;
}

export default function Rebalance({
  demos,
  scheduledDemos,
  filterBy,
  setFilter,
  dcFilters,
  contentPoolFiltersMap,
  inputTargetState,
  setTargetState,
  expoUid,
  isRebalanceTabVisible,
  dataCenters,
  setIsRebalanceTabVisible,
}: IProps) {
  const data = React.useMemo(
    () =>
      scheduledDemos.filter(
        (demo: {
          dataCenter: string;
          contentPool: IContentPool;
          type: string;
        }) =>
          demo.dataCenter === filterBy.dataCenter &&
          demo.contentPool.id === filterBy.contentPool.id,
      ),
    [filterBy, scheduledDemos],
  );

  const contentPoolFilters = React.useMemo(
    () => contentPoolFiltersMap.get(filterBy.dataCenter) ?? [],
    [filterBy.dataCenter, contentPoolFiltersMap],
  );

  const onDcDropdownChange = React.useCallback(
    (dropdownName: string, dataCenterName: string) => {
      const [defaultContentPool] =
        contentPoolFiltersMap.get(dataCenterName) ?? [];
      const { value: id = '', name = '' } = defaultContentPool;
      setFilter({
        dataCenter: dataCenterName,
        contentPool: {
          id,
          name,
        },
      });
    },

    [setFilter, contentPoolFiltersMap],
  );

  useRebalanceTabVisibility({
    dataCenters,
    determineDataCenterConnectivity,
    filterBy,
    setIsRebalanceTabVisible,
  });

  const onContentPoolDropdownChange = React.useCallback(
    (dropdownName: string, contentPoolId: string) => {
      const contentPoolName =
        contentPoolFilters.find(
          (contentPool) => contentPoolId === contentPool.value,
        )?.name ?? '';
      setFilter({
        ...filterBy,
        contentPool: {
          id: contentPoolId,
          name: contentPoolName,
        },
      });
    },
    [filterBy, setFilter, contentPoolFilters],
  );

  if (scheduledDemos.length === 0) return null;
  return (
    <div className="rebalance-page-wrapper">
      {demos.length && (
        <div className="rebalance-filter-controls">
          <Label labelFor="admin_dc_filter_to_rebalance" required={false}>
            Data Center
          </Label>
          <Dropdown
            id="admin_dc_filter_to_rebalance"
            name="admin_dc_filter_to_rebalance"
            onChange={onDcDropdownChange}
            selected={filterBy.dataCenter}
            options={dcFilters}
          />
          <ContentPoolDropdown
            onChange={onContentPoolDropdownChange}
            selected={filterBy.contentPool.id}
            options={contentPoolFilters}
            isVisible={isRebalanceTabVisible}
          />
        </div>
      )}
      <RebalanceControls isVisible={isRebalanceTabVisible} expoUid={expoUid} />
      <RebalanceTabContent
        isRebalanceTabVisible={isRebalanceTabVisible}
        columns={targetColumns(setTargetState, inputTargetState)}
        data={data}
      />
    </div>
  );
}
