import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { exposActions } from '../../../redux/admin/expos/actions';
import { modalActions } from '../../../redux/modals/actions';
import Menu from '.';
import {
  getExposFilterBy,
  getExposSortBy,
  getFilteredExpos,
  getExposLoading,
} from '../../../redux/admin/expos/selectors';
import { IApplicationState } from '../../../redux/types';
import { ExpoDateFilters, ExpoStates } from '../../entities/expos/constants';
import { MODAL_NAMES } from '../../common/components/modal/constants';

const mapStateToProps = (state: IApplicationState) => ({
  filterBy: getExposFilterBy(state),
  sortBy: getExposSortBy(state),
  expos: getFilteredExpos(state),
  loading: getExposLoading(state),
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchExpos: () => dispatch(exposActions.fetchExpos()),
  setSortBy: (sortBy: ExpoDateFilters) =>
    dispatch(exposActions.setSort(sortBy)),
  setFilterBy: (filterBy: ExpoStates) =>
    dispatch(exposActions.setFilter(filterBy)),
  onAccessCodeClick: (uid: string) =>
    dispatch(exposActions.fetchAccessCode(uid)),
  onDeleteIconClick: (uid: string) =>
    dispatch(modalActions.openModal(MODAL_NAMES.DELETE_EXPO, { uid })),
  onUnpublishButtonClick: (uid: string) =>
    dispatch(modalActions.openModal(MODAL_NAMES.UNPUBLISH_EXPO, { uid })),
  reset: () => dispatch(exposActions.reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
