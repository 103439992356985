import {
  Button,
  Card,
  CardHeader,
  required,
  TextInput,
} from '../../../common/pov-common-ui';
import * as React from 'react';
import useFormValidation, {
  Validation,
  RegexRestriction,
} from '../../../common/hooks/useFormValidation';
import { landingPageActions } from '../../../../redux/public/landing-page/actions';
import './styles.scss';

type DefaultProps = Readonly<typeof defaultProps>;

export type Props = {
  loading: boolean;
  onSubmit: typeof landingPageActions.sendAccessCode;
} & DefaultProps;

export interface IFormValues {
  accessCode: string;
}

export const initialValues: IFormValues = {
  accessCode: '',
};

export const regexRestriction: RegexRestriction<IFormValues> = {
  accessCode: /^[0-9]*$/,
};

export const validation: Validation<IFormValues> = {
  accessCode: [required('Please provide a valid access code')],
};

const defaultProps = {
  errors: [] as string[],
};

export default function AccessCodeForm({ errors, loading, onSubmit }: Props) {
  const { form, valid } = useFormValidation(
    initialValues,
    validation,
    undefined,
    regexRestriction,
  );

  return (
    <Card className="access-code-form" loading={loading}>
      <CardHeader title="View an eXpo" alignTitle="center" />
      <form
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          onSubmit(form.accessCode.value);
        }}
      >
        <TextInput
          {...form.accessCode}
          id="accessCode"
          label="Enter access code"
          placeholder="Enter access code"
          hideLabel={true}
          errors={[...form.accessCode.errors, ...errors]}
        />
        <Button id="launch-btn" type="submit" color="primary" disabled={!valid}>
          Launch
        </Button>
      </form>
    </Card>
  );
}

AccessCodeForm.defaultProps = defaultProps;
