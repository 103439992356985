import * as React from 'react';
import './styles.scss';

export interface IProps {
  className?: string;
  id: string;
  thumbnail: string;
  buttons?: React.ReactNode;
  content?: React.ReactNode;
  label?: React.ReactNode;
}

function DemoItem(props: IProps) {
  const classes = `demo-item ${props.className ?? ''}`;

  return (
    <div className={classes} id={props.id}>
      <div
        className="demo-item__thumbnail"
        style={{
          backgroundImage: `url(${props.thumbnail})`,
        }}
      />
      <div className="demo-item__content">{props.content}</div>
      {props.buttons && (
        <div className="demo-item__buttons">{props.buttons}</div>
      )}
    </div>
  );
}

export default DemoItem;
