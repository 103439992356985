import {
  Actions,
  FETCH_SURVEY_TYPES,
  FETCH_SURVEY_TYPES_SUCCESS,
} from './actions';
import { ISurveyType } from '../../../app/entities/expos/types';

export type MetadataState = {
  surveyTypes: {
    items: ISurveyType[];
    loading: boolean;
    fetched: boolean;
  };
};

export const initialState: MetadataState = {
  surveyTypes: { items: [], loading: false, fetched: false },
};

export default function metadataReducer(
  state: MetadataState = initialState,
  action: Actions,
): MetadataState {
  switch (action.type) {
    case FETCH_SURVEY_TYPES:
      return {
        ...state,
        surveyTypes: { items: [], loading: true, fetched: false },
      };
    case FETCH_SURVEY_TYPES_SUCCESS:
      return {
        ...state,
        surveyTypes: { items: action.payload, loading: false, fetched: true },
      };
    default:
      return state;
  }
}
