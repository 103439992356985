import moment from 'moment';
import {
  Card,
  CiscoButtonColours,
  CiscoButtonSizes,
  IconButton,
} from '../../../common/pov-common-ui';
import * as React from 'react';
import DemoItem from '../../../common/components/demo-item';
import { ISavedExpo } from '../../../entities/expos/types';
import { CardButtons } from './card-buttons';
import { CardLabel } from './card-label';
import { ExpoStates, ExpoEventPaths } from '../../../entities/expos/constants';
import './styles.scss';
import { expoEvents } from '../../../entities/expos/defaults';
import { imageAsset } from '../../../common/utilities/imageAssets';
import { ReactElement } from 'react';

export interface IProps {
  expo: ISavedExpo;
  onAccessCodeClick: (uid: string) => void;
  onDeleteIconClick: (uid: string) => void;
  onUnpublishButtonClick: (uid: string) => void;
}

export const interpolateEventTypes = (type: string): string => {
  const findKey = type.toLowerCase().replace(' ', '-');
  const keyMatch = ExpoEventPaths(findKey);

  if (keyMatch) return keyMatch;

  const mappedLowerCaseNames = {};
  const event = expoEvents.find((ev) => {
    const toLowerCaseName = ev.name.toLowerCase().replace(' ', '-');
    mappedLowerCaseNames[ev.name] = toLowerCaseName;
    return (
      toLowerCaseName === findKey ||
      (ev.altNames ? ev.altNames.indexOf(findKey) > -1 : false)
    );
  });

  return event ? mappedLowerCaseNames[event.name] : 'sevt';
};

export function ExpoCard({
  expo,
  onAccessCodeClick,
  onDeleteIconClick,
  onUnpublishButtonClick,
}: IProps): ReactElement {
  const start = moment(expo.start!).format('DD MMMM YYYY');

  const thumbnailUrl = `${interpolateEventTypes(expo.type)}/thumbnail.png`;

  return (
    <div className="col-lg-6 base-margin-top menu-expo-card">
      <Card>
        <DemoItem
          id={`Expo-Card-${expo.uid}`}
          thumbnail={imageAsset(thumbnailUrl)}
          buttons={
            <CardButtons
              uid={expo.uid!}
              onAccessCodeClick={onAccessCodeClick}
              onUnpublishButtonClick={onUnpublishButtonClick}
              showAccessCodeBtn={!!(expo._links && expo._links.accessCode)}
              showManageButton={expo.state === ExpoStates.IN_PROGRESS}
              showEditButton={expo.state !== ExpoStates.COMPLETE}
              showUnpublishButton={!!(expo._links && expo._links.unpublish)}
            />
          }
          content={
            <>
              <CardLabel>{expo.state!}</CardLabel>
              <h3 className="demo-item__title">
                {expo.name}
                {expo._links && expo._links.delete && (
                  <IconButton
                    icon="icon-trash"
                    onClick={() => onDeleteIconClick(expo.uid!)}
                    colour={CiscoButtonColours.WHITE}
                    size={CiscoButtonSizes.SMALL}
                    testId={`delete-icon-${expo.uid}`}
                  />
                )}
              </h3>
              <p className="demo-item__location">{expo.location}</p>
              <p className="demo-item__date">{start}</p>
              <p className="demo-item__description">{expo.description}</p>
            </>
          }
        />
      </Card>
    </div>
  );
}
