export enum ErrorCodes {
  NotSupplied = 0,
  MultipleChoices = 300,
  BadlyFormed = 400,
  NotAuthorized = 401,
  NotFound = 404,
  Forbidden = 403,
  PreconditionFailed = 412,
  UnprocessableEntity = 422,
  ServiceUnavailable = 503,
}

export const text = {
  error: 'Error',
  somethingWentWrong: 'Something went wrong. Please try again.',
};
