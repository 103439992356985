import * as React from 'react';
import './styles.scss';
import { IconButton } from '../../../../common/pov-common-ui';
import { LinkButton } from '../../../../common/components/link-button';
import { copyTextToClipboard } from '../../../../common/utilities/helpers';

export interface IProps {
  id: string;
  viewUrl?: string;
}

export default function SessionInfo({ id, viewUrl }: IProps): JSX.Element {
  const sessionId = id.toString();
  return (
    <div className="session-info pull-right">
      {sessionId && (
        <>
          <span className="session-info-label">SESSION ID:</span>
          <span id="session-info-id">{sessionId}</span>
          <IconButton
            icon="icon-recent-apps"
            className="copy-value"
            onClick={() => copyTextToClipboard(sessionId)}
          />
        </>
      )}
      {viewUrl && (
        <LinkButton
          color={'secondary'}
          id="view-session"
          target="_blank"
          to={viewUrl}
        >
          View Session
        </LinkButton>
      )}
    </div>
  );
}
