import { Button } from '../../../common/pov-common-ui';
import * as React from 'react';
import { connect } from 'react-redux';
import { LinkButton } from '../../../common/components/link-button';
import NavBar from '../../../common/components/nav-bar';
import { ROUTES } from '../../../common/constants';
import { modalActions } from '../../../../redux/modals/actions';
import { MODAL_NAMES } from '../../../common/components/modal/constants';
import { createActions } from '../../../../redux/admin/create/actions';
import { editActions } from '../../../../redux/admin/edit/actions';
import { Dispatch } from 'redux';
import { ReactElement } from 'react';

type DefaultProps = Partial<typeof defaultProps>;

export type Props = {
  onSave?: typeof createActions.createExpo | typeof editActions.saveExpo;
  showPublishModal?: () => void;
} & DefaultProps;

const defaultProps = {
  disableSave: false,
  disablePublish: false,
  showPublish: false,
  showSave: true,
  uid: '',
};

export function Header({
  disablePublish,
  disableSave,
  onSave,
  showPublish,
  showPublishModal,
  showSave,
}: Props): ReactElement {
  return (
    <NavBar
      right={
        <>
          {showPublish && showPublishModal && (
            <Button
              color="success"
              id="publish"
              disabled={disablePublish}
              onClick={() => showPublishModal()}
            >
              Publish
            </Button>
          )}
          {showSave && (
            <Button
              onClick={onSave}
              disabled={disableSave}
              id="save"
              color="secondary"
            >
              Save
            </Button>
          )}
          <LinkButton color="primary" to={ROUTES.ADMIN} id="menu">
            Home
          </LinkButton>
        </>
      }
    />
  );
}

Header.defaultProps = defaultProps;

export const mapDispatchToProps = (dispatch: Dispatch, ownProps: Props) => ({
  showPublishModal: () =>
    dispatch(
      modalActions.openModal(MODAL_NAMES.PUBLISH_EXPO, { uid: ownProps.uid }),
    ),
});

export default connect(null, mapDispatchToProps)(Header);
