import { Columns } from '../../../../../common/pov-common-ui';
import * as React from 'react';
import Cell from '../../../../../common/components/table-cell';

const columns: Columns<Demo> = [
  {
    Header: 'DEMO',
    id: 'demoColumn',
    Cell: (cellInfo) => <Cell {...cellInfo.original.DEMO} />,
    accessor: (d) => Number(d.DEMO.value),
  },
  {
    Header: 'NAME',
    Cell: (cellInfo) => <Cell {...cellInfo.original.NAME} />,
    accessor: 'NAME.value',
  },
  {
    Header: 'CATEGORY',
    Cell: (cellInfo) => <Cell {...cellInfo.original.CATEGORY} />,
    accessor: 'CATEGORY.value',
  },
  {
    Header: 'DEMO TYPE',
    Cell: (cellInfo) => <Cell {...cellInfo.original.DEMO_TYPE} />,
    accessor: 'DEMO_TYPE.value',
  },
  {
    Header: 'SESSIONS',
    id: 'sessionsColumn',
    Cell: (cellInfo) => <Cell {...cellInfo.original.SESSIONS} />,
    accessor: (s) => Number(s.SESSIONS.value),
  },
  {
    Header: 'CONTENT POOL',
    id: 'contentPoolColumn',
    Cell: (cellInfo) => <Cell {...cellInfo.original.CONTENT_POOL} />,
    accessor: (s) => Number(s.CONTENT_POOL.value),
  },
];

export default columns;
