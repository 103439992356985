import { createAction } from '../../../action-helpers';
import { ActionsUnion } from '../../../action-types';

export const CREATE_ENGAGEMENT = 'PUBLIC/ENGAGEMENTS/CREATE';
export const CREATE_ENGAGEMENT_SUCCESS = 'PUBLIC/ENGAGEMENTS/CREATE_SUCCESS';
export const END_ENGAGEMENT = 'PUBLIC/ENGAGEMENTS/END';
export const END_ENGAGEMENT_SUCCESS = 'PUBLIC/ENGAGEMENTS/END_SUCCESS';
export const EXTEND_ENGAGEMENT = 'PUBLIC/ENGAGEMENTS/EXTEND';
export const CLEAR_ENGAGEMENT = 'PUBLIC/ENGAGEMENTS/CLEAR_ENGAGEMENT';
export const FETCH_ENGAGEMENT = 'PUBLIC/ENGAGEMENTS/FETCH';
export const FETCH_ENGAGEMENT_SUCCESS = 'PUBLIC/ENGAGEMENTS/FETCH_SUCCESS';

const createEngagement = (
  demo: IDemo,
  expoUid: string,
  values: IStartEngagementPayload,
) =>
  createAction(CREATE_ENGAGEMENT, {
    demo,
    expoUid,
    values,
  });

const createEngagementSuccess = (uid: string, expoUid: string) =>
  createAction(CREATE_ENGAGEMENT_SUCCESS, { expoUid, uid });

const fetchEngagement = () => createAction(FETCH_ENGAGEMENT);

const fetchEngagementSuccess = (engagement: IDemoEngagement) =>
  createAction(FETCH_ENGAGEMENT_SUCCESS, engagement);

const endEngagement = () => createAction(END_ENGAGEMENT);
const endEngagementSuccess = () => createAction(END_ENGAGEMENT_SUCCESS);
const clearEngagement = () => createAction(CLEAR_ENGAGEMENT);
const extendEngagement = () => createAction(EXTEND_ENGAGEMENT);

export const engagementActions = {
  createEngagement,
  createEngagementSuccess,
  endEngagement,
  endEngagementSuccess,
  extendEngagement,
  fetchEngagement,
  fetchEngagementSuccess,
  clearEngagement,
};

export type Actions = ActionsUnion<typeof engagementActions>;
