import * as React from 'react';
import './styles.scss';
import DCloudLogo from '../dcloud-logo';

interface IProps {
  right?: React.ReactNode;
}

function Topbar(props: IProps) {
  return (
    <div className="top-bar">
      <DCloudLogo />
      {props.right && <div className="top-bar__right">{props.right}</div>}
    </div>
  );
}

export default Topbar;
