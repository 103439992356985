import * as React from 'react';

interface IProps {
  content: string;
  children?: React.ReactNode;
}

export default function Tooltip({ content, children }: IProps): JSX.Element {
  return (
    <span
      data-balloon={content}
      data-balloon-length="xlarge"
      data-balloon-pos="up"
      data-balloon-break={true}
    >
      {children || <span className="icon-info-circle" />}
    </span>
  );
}
