import { SagaIterator } from 'redux-saga';
import { AxiosError } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { getCsvValue } from '../../selectors';
import { postVerifyContent } from '../../../../../app/entities/expos/service';
import { editActions } from '../../actions';
import {
  contentHasErrors,
  renderDemoContent,
} from '../../../../../app/admin/edit/utilities';

export default function* validateCsvWorker(): SagaIterator {
  const csvValue: string = yield select(getCsvValue);

  try {
    const response: DemoContent = yield call(postVerifyContent, csvValue);
    const content: IFormattedDemo[] = yield call(renderDemoContent, response);
    if (contentHasErrors(content)) {
      yield put(editActions.validateCsvSuccessWithContentErrors(content));
    } else {
      yield put(
        editActions.validateCsvSuccess({
          content,
          csvValue,
        }),
      );
    }
  } catch (e) {
    const error: AxiosError = e;

    if (error.response!.status === 400) {
      const errors: string[] = (error.response!.data as IFieldError[]).map(
        (err: IFieldError) => err.message,
      );

      yield put(editActions.validateCsvError(errors));
    } else {
      yield put(editActions.setIsValidating(false));
    }
    throw error;
  }
}
