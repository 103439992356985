import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { CenteredLoader } from '../../common/components/loader';
import { ROUTES } from '../../common/constants';
import InstantDemo from './instant-demo/container';
import ExpoMain from './main/container';
import ScheduledDemo from './scheduled-demo/container';
import { engagementActions } from '../../../redux/public/engagements/actions';
import { expoActions } from '../../../redux/public/expos/actions';
import { IExpoWithCategories } from '../../entities/expos/types';
import { RouteComponentProps } from 'react-router-dom';

export interface IProps {
  expo: IExpoWithCategories | null;
  fetchEngagement: typeof engagementActions.fetchEngagement;
  fetchExpo: typeof expoActions.fetchExpo;
  engagementUid: string | null;
  engagementData?: IDemoEngagement;
}

function Expo({
  engagementUid,
  expo,
  fetchEngagement,
  fetchExpo,
  match,
  location,
}: IProps & RouteComponentProps<{ id: string }>) {
  React.useEffect(() => {
    if (engagementUid) {
      fetchEngagement();
    }
  }, [location.pathname, engagementUid, fetchEngagement]);

  React.useEffect(() => {
    fetchExpo(match.params.id);
  }, [fetchExpo, match.params.id]);

  if (!expo) {
    return <CenteredLoader />;
  }

  return (
    <Switch>
      <Route path={ROUTES.EXPO} exact={true} component={ExpoMain} />
      <Route path={ROUTES.INSTANT_DEMO} exact={true} component={InstantDemo} />
      <Route path={ROUTES.SCHEDULED_DEMO} component={ScheduledDemo} />
      <Redirect to={ROUTES.PAGE_NOT_FOUND_404} />
    </Switch>
  );
}

export default Expo;
