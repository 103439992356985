import * as React from 'react';
import { ExpoStates } from '../../../entities/expos/constants';
import { ISavedExpo } from '../../../entities/expos/types';
import { ExpoCard } from '../expo-card';
import NoExpos from './NoExpos';
import NoFilterResults from './NoFilterResults';
import './styles.scss';
import { ReactElement } from 'react';

export interface IProps {
  filterBy: ExpoStates;
  expos: ISavedExpo[];
  onAccessCodeClick: (uid: string) => void;
  onDeleteIconClick: (uid: string) => void;
  onUnpublishButtonClick: (uid: string) => void;
}

export default function Expos({
  filterBy,
  expos,
  onDeleteIconClick,
  onAccessCodeClick,
  onUnpublishButtonClick,
}: IProps): ReactElement {
  const filterApplied = filterBy !== ExpoStates.ALL;

  if (!expos.length) {
    if (filterApplied) {
      return <NoFilterResults />;
    }
    return <NoExpos />;
  }

  return (
    <div className="row" id="expos">
      {expos.map((expo: ISavedExpo) => (
        <ExpoCard
          key={expo.uid}
          expo={expo}
          onAccessCodeClick={onAccessCodeClick}
          onDeleteIconClick={onDeleteIconClick}
          onUnpublishButtonClick={onUnpublishButtonClick}
        />
      ))}
    </div>
  );
}
