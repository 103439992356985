import { ToastSeverity } from '../../app/common/pov-common-ui';
import { createAction } from '../action-helpers';
import { ActionsUnion } from '../action-types';

export const TRIGGER_SHOW_TOAST = 'TOAST/TRIGGER';
export const SHOW_TOAST = 'TOAST/SHOW';
export const HIDE_TOAST = 'TOAST/HIDE';

export const toastActions = {
  showToast: (title: string, message: string, severity: ToastSeverity) =>
    createAction(SHOW_TOAST, {
      title,
      message,
      severity,
    }),
  hideToast: () => createAction(HIDE_TOAST),
};

export type Actions = ActionsUnion<typeof toastActions>;
