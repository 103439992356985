import * as React from 'react';

import { Button } from '../Button';

export interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  ariaLabel?: string;
  children: string;
  disabled?: boolean;
  id?: string;
  onClick?(e: React.MouseEvent<HTMLElement>): void;
}

const defaultProps: Partial<IProps> = {
  ariaLabel: undefined,
  disabled: false,
  onClick: () => void 0,
  type: 'button',
};

const CancelButton: React.SFC<IProps> = (props) => (
  <Button
    ariaLabel={props.ariaLabel}
    color="secondary"
    disabled={props.disabled}
    id={props.id}
    onClick={props.onClick}
    type={props.type}
  >
    {props.children}
  </Button>
);

CancelButton.defaultProps = defaultProps;
export default CancelButton;
