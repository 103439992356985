import * as React from 'react';
import FormGroup, { IProps as FormGroupProps } from '../FormGroup/FormGroup';
import { Label } from '../Label';
import './styles.scss';

export type TextInputProps = {
  hideLabel?: boolean;
  Icon?: React.ReactNode;
  id: string;
  label: string;
} & React.ComponentPropsWithoutRef<'input'> &
  Omit<FormGroupProps, 'children'>;

const TextInput: React.FC<TextInputProps> = ({
  compressed = false,
  errors = [],
  hideLabel = false,
  Icon,
  inline = false,
  inlineLabel = false,
  label,
  required = false,
  ...inputProps
}) => (
  <FormGroup
    errors={errors}
    withFormGroupText={true}
    compressed={compressed}
    inline={inline}
    inlineLabel={inlineLabel}
  >
    <input {...inputProps} className="text-input__input" />
    <Label
      className="text-input__label"
      hideLabel={hideLabel}
      labelFor={inputProps.id}
      required={required}
    >
      {label}
    </Label>
    {Icon}
  </FormGroup>
);

export default TextInput;
