import React, { ReactElement } from 'react';
import './styles.scss';
import ExclamationTriangleIcon from './components/ExclamationTriangleIcon';
import i18n from '../../../../../../../../src/i18n';

export default function DisconnectedDataCenterWarning(): ReactElement {
  const DISCONNECTED_DC_MESSAGE = i18n.t(
    'admin.dataCenters.dataCenterDisconnected',
  );

  return (
    <div className="disconnected-data-center-warning-wrapper">
      <ExclamationTriangleIcon />
      <p>{DISCONNECTED_DC_MESSAGE}</p>
    </div>
  );
}
