import classNames from 'classnames';
import * as React from 'react';

interface IProps {
  children: string;
  className?: string;
  hideLabel?: boolean;
  labelFor: string;
  required?: boolean;
}

const Label: React.SFC<IProps> = ({
  children,
  className,
  hideLabel = false,
  labelFor,
  required = true,
}) => (
  <label
    htmlFor={labelFor}
    className={classNames(className, { 'sr-only': hideLabel })}
  >
    {children}
    {required && <span>*</span>}
  </label>
);

export default Label;
