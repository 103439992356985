import { createSelector } from 'reselect';
import { getAdminState } from '../../selectors';
import { IApplicationState } from '../../../types';
import { ISavedExpo } from '../../../../app/entities/expos/types';
import {
  ExpoStates,
  ExpoDateFilters,
} from '../../../../app/entities/expos/constants';
import { ExposState } from '../reducer';

export const getExposState = (state: IApplicationState): ExposState =>
  getAdminState(state).expos;

export const getExposFetched = (state: IApplicationState): boolean =>
  getExposState(state).fetched;

export const getExposFilterBy = (state: IApplicationState): ExpoStates =>
  getExposState(state).filterBy;

export const getExposSortBy = (state: IApplicationState): ExpoDateFilters =>
  getExposState(state).sortBy;

export const getExpos = (state: IApplicationState): ISavedExpo[] =>
  getExposState(state).items;

export const getExposLoading = (state: IApplicationState): boolean =>
  getExposState(state).loading;

export const getSingleExpo = (
  state: IApplicationState,
  uid: string,
): ISavedExpo | undefined => {
  const expos = getExpos(state);
  return expos.find((expo) => expo.uid === uid);
};

export const getFilteredExpos = createSelector(
  getExpos,
  getExposFilterBy,
  (expos: ISavedExpo[], filter: ExpoStates): ISavedExpo[] => {
    if (filter === ExpoStates.ALL) return expos;
    return expos.filter((expo) => expo.state === filter);
  },
);
