const { IDP_CODE } = window.__EXPO_CONFIG__.SSO;

export enum AuthActionTypes {
  StartAuth = '@@expo/auth/start_auth',
  SetAuthStatus = '@@expo/auth/set_auth_status',
  SwapAccessCode = '@@kau/auth/swap_access_code',
}

export enum AuthStatusValues {
  NotAuthenticated = 'not-authenticated',
  Authenticated = 'authenticated',
  RefreshingTokens = 'refreshing-tokens',
}

export enum OauthQueryParams {
  AccessCode = 'code',
  CodeVerifier = 'code_verifier',
  StateParam = 'state',
}

interface IConfig extends IExpoWindowConfig {
  SSO: IExpoWindowConfig['SSO'] & {
    CODE_CHALLENGE_METHOD: string;
    CODE_VERIFIER_NAME: string;
  };
}

export const config: IConfig = {
  ...window.__EXPO_CONFIG__,
  SSO: {
    ...window.__EXPO_CONFIG__.SSO,
    ACCESS_TOKEN_NAME: `dc_${IDP_CODE}_a`,
    CODE_CHALLENGE_METHOD: 'S256',
    CODE_VERIFIER_NAME: `dc_${IDP_CODE}_c`,
    REFRESH_TOKEN_NAME: `dc_${IDP_CODE}_r`,
    RESPONSE_TYPE: 'code',
    SCOPE: 'openid profile email offline_access',
  },
};
