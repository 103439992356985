import classnames from 'classnames';
import * as React from 'react';
import './styles.scss';

export type SizeTypes = 'default' | 'small' | 'wide';

export type ColorTypes =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'negative'
  | 'success'
  | 'gray-ghost'
  | 'white'
  | 'white-ghost';

export interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  ariaLabel?: string;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  id?: string;
  size?: SizeTypes;
  color?: ColorTypes;
  onClick?(e: React.MouseEvent<HTMLElement>): void;
}

const defaultProps: Partial<IProps> = {
  ariaLabel: undefined,
  className: '',
  color: 'default',
  disabled: false,
  onClick: () => void 0,
  size: 'default',
  type: 'button',
};

const classes = (props: IProps) =>
  classnames(
    'btn',
    `btn--${props.size}`,
    `btn--${props.color}`,
    props.className,
  );

const Button: React.SFC<IProps> = (props) => (
  <button
    aria-label={props.ariaLabel}
    className={classes(props)}
    disabled={props.disabled}
    id={props.id}
    onClick={props.onClick}
    type={props.type}
  >
    {props.children}
  </button>
);

Button.defaultProps = defaultProps;
export default Button;
