import { ToastSeverity } from '../../../../../app/common/pov-common-ui';
import { SagaIterator } from 'redux-saga';
import { call, delay, put } from 'redux-saga/effects';
import { FLICKER_DELAY } from '../../../../../app/common/constants';
import { toastActions } from '../../../../toast/actions';
import { modalActions } from '../../../../modals/actions';
import { unpublishExpo } from '../../../../../app/entities/expos/service';
import { exposActions, UNPUBLISH_EXPO } from '../../actions';
import { IActionWithPayload } from '../../../../action-helpers';

export default function* unpublishExpoWorker(
  action: IActionWithPayload<typeof UNPUBLISH_EXPO, string>,
): SagaIterator {
  try {
    yield put(modalActions.modalLoading(true));
    yield call(unpublishExpo, action.payload);
    yield delay(FLICKER_DELAY);
    yield put(exposActions.unpublishExpoSuccess(action.payload));
    yield put(
      toastActions.showToast(
        'Success',
        'eXpo unpublished',
        ToastSeverity.SUCCESS,
      ),
    );
  } catch (e) {
    throw e;
  } finally {
    yield put(modalActions.closeModal());
    yield put(modalActions.modalLoading(false));
  }
}
