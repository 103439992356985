import { createAction } from '../../../action-helpers';
import { ActionsUnion } from '../../../action-types';
import { IExpoWithCategories } from '../../../../app/entities/expos/types';

export const FETCH_EXPO = 'PUBLIC/EXPO/FETCH';
export const FETCH_EXPO_SUCCESS = 'PUBLIC/EXPO/FETCH_SUCCESS';

export const fetchExpo = (uid: string) => createAction(FETCH_EXPO, uid);
export const fetchExpoSuccess = (expo: IExpoWithCategories) =>
  createAction(FETCH_EXPO_SUCCESS, expo);

export const expoActions = {
  fetchExpo,
  fetchExpoSuccess,
};

export type Actions = ActionsUnion<typeof expoActions>;
