import { AuthActionTypes, AuthStatusValues } from '../../auth';
import { StartAuth, SetAuthStatus, SwapAccessCode } from './types';

export const startAuth = (): StartAuth => ({
  type: AuthActionTypes.StartAuth,
});

export const setAuthStatus = (payload: AuthStatusValues): SetAuthStatus => ({
  type: AuthActionTypes.SetAuthStatus,
  payload,
});

export const swapAccessCode = (): SwapAccessCode => ({
  type: AuthActionTypes.SwapAccessCode,
});
