import { SagaIterator } from 'redux-saga';
import { AxiosError } from 'axios';
import { ToastSeverity } from '../../../../../app/common/pov-common-ui';
import { call, put, select } from 'redux-saga/effects';
import { globalUiActions } from '../../../../ui/loader/actions';
import { toastActions } from '../../../../toast/actions';
import { modalActions } from '../../../../modals/actions';
import { MODAL_NAMES } from '../../../../../app/common/components/modal/constants';
import { getEtag, getTempExpo } from '../../selectors';
import { ISavedExpo } from '../../../../../app/entities/expos/types';
import { updateExpo } from '../../../../../app/entities/expos/service';
import { editActions } from '../../actions';
import {
  mapFromExpoResponse,
  mapToExpoEditRequest,
} from '../../../../../app/entities/expos/utilities';

export default function* saveExpoWorker(): SagaIterator {
  yield put(globalUiActions.showLoader());

  const etag: string = yield select(getEtag);
  const expo: ISavedExpo = yield select(getTempExpo);
  const payload = mapToExpoEditRequest(expo);

  try {
    const response = yield call(updateExpo, payload, etag);
    const savedExpo = mapFromExpoResponse(response.data);

    yield put(
      editActions.fetchExpoSuccess({
        expo: savedExpo,
        etag: response.headers.etag,
      }),
    );

    yield put(
      toastActions.showToast('Success', 'eXpo saved', ToastSeverity.SUCCESS),
    );
  } catch (e) {
    const error: AxiosError = e;

    if (error.response!.status === 412) {
      yield put(modalActions.openModal(MODAL_NAMES.EXPO_CHANGED));
    }
    throw error;
  } finally {
    yield put(globalUiActions.hideLoader());
  }
}
