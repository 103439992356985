import { Button } from '../../../pov-common-ui';
import * as React from 'react';
import ModalInner from '../modal-inner';
import './styles.scss';

export interface IProps {
  closeModal: VoidFunction;
  resources: IDemoResource[];
}

export default function DemoResources({ resources, closeModal }: IProps) {
  const generateLinks = () =>
    resources.map((resource) => (
      <li key={resource.name}>
        <a
          className="demo-resource"
          href={resource.documentLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          {resource.name}
        </a>
      </li>
    ));

  return (
    <div className="demo-resources-modal">
      <ModalInner
        title="Resources"
        content={
          <ul className="resource-links-container">{generateLinks()}</ul>
        }
        buttons={
          <Button
            onClick={closeModal}
            id="demo-resources-modal_ok"
            color="primary"
          >
            OK
          </Button>
        }
      />
    </div>
  );
}
