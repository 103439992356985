import { AxiosError, AxiosRequestConfig } from 'axios';
import { push } from 'connected-react-router';
import { ROUTES } from '../../app/common/constants';
import { MODAL_NAMES } from '../../app/common/components/modal/constants';
import { modalActions } from '../../redux/modals/actions';
import { ErrorCodes } from './constants';
import { Store } from 'redux';
import { IApplicationState } from '../../redux/types';

const errorUrls = {
  expo: /expos\/[A-Za-z0-9]+/,
  publicExpo: /public\/expos\/[A-Za-z0-9]+/,
  accessCode: /public\/access-codes\/([0-9])+/,
  contentTransformations: /content-transformations/,
  manage: /\/public\/engagements/,
  endEngagement: /\/engagements/,
  getEngagement: /public\/engagements\/[A-Za-z0-9]+/,
};

export const genericErrorHandler = (
  e: AxiosError,
  store: Store<IApplicationState>,
) => {
  switch (e.response!.status) {
    case ErrorCodes.Forbidden:
      store.dispatch(push(ROUTES.FORBIDDEN_403));
      break;
    case ErrorCodes.NotFound:
      store.dispatch(push(ROUTES.RESOURCE_NOT_FOUND_404));
      break;
    default:
      store.dispatch(
        modalActions.openModal(MODAL_NAMES.GENERIC_ERROR, {
          errors: e.response!.data,
        }),
      );
  }
};

export function checkToSkipErrorHandler(
  code: number,
  config: AxiosRequestConfig,
) {
  const { url } = config;

  switch (code) {
    case ErrorCodes.NotAuthorized:
      return true;
    case ErrorCodes.PreconditionFailed:
      return errorUrls.expo.test(url!);
    case ErrorCodes.NotFound:
      // todo: clean up
      return (
        errorUrls.publicExpo.test(url!) ||
        errorUrls.accessCode.test(url!) ||
        (errorUrls.manage.test(url!) && config.method === 'delete') ||
        (errorUrls.endEngagement.test(url!) && config.method === 'delete')
      );
    case ErrorCodes.BadlyFormed:
    case ErrorCodes.UnprocessableEntity:
      return errorUrls.contentTransformations.test(url!);
    default: {
      // Test is code is one of the 3xx Redirection codes
      // FIXME code > ErrorCodes.MultipleChoices looks like it should be code >= ErrorCodes.MultipleChoices
      if (code > ErrorCodes.MultipleChoices && code < ErrorCodes.BadlyFormed) {
        return errorUrls.getEngagement.test(url!) && config.method === 'get';
      }
      return false;
    }
  }
}

export default function configuredErrorHandler(
  store: Store<IApplicationState>,
) {
  return function errorHandling(error: AxiosError) {
    const { status, config } = error.response!;
    const skipGenericHandler = checkToSkipErrorHandler(status, config);

    if (!skipGenericHandler) {
      genericErrorHandler(error, store);
    }

    throw error;
  };
}
