import { ISavedExpo } from '../../entities/expos/types';
import { DATA_CENTERS } from '../../common/constants';
import { deepClone } from '../../common/utilities/helpers';
import { IColumn } from '../../common/pov-common-ui';
import { initialExpo } from '../../entities/expos/defaults';

export const returnAmountOfDemoErrors = (data: Demo[]): number => {
  let errors: string[] = [];

  data.forEach((demo) => {
    Object.keys(demo).forEach((key) => {
      errors = [...errors, ...demo[key].errors];
    });
  });

  return errors.length;
};

export function returnGeneralFormFields(expo: ISavedExpo | null) {
  if (!expo) return initialExpo;
  const keys = Object.keys(initialExpo);
  const items = keys.map((key) => ({ [key]: expo[key] }));

  return Object.assign({}, ...items);
}

export function renderDemoContent(content: DemoContent): FormattedDemoContent {
  const inOrder = DATA_CENTERS.reduce((obj, key) => {
    if (!content[key]) return obj;
    return { ...obj, [key]: content[key] };
  }, {});

  return Object.keys(inOrder).map((key) => ({
    name: key,
    errors: returnAmountOfDemoErrors(content[key]),
    demos: content[key],
  }));
}

export function renderCapacityContent(
  content: ICapacityContent,
): IFormattedCapacityContent[] {
  return Object.keys(content).map((key) => ({
    name: key,
    error: Boolean(content[key].errorCount),
    errorCount: content[key].errorCount,
    summary: [content[key].summary],
    demos: content[key].demos.map((demo) => ({
      content: [demo.content],
      details: demo.details,
    })),
  }));
}

export const doesContentContainError = (content: ICapacityContent) => {
  const keys = Object.keys(content) as DataCenters[];
  return keys.some((key) => Boolean(content[key]!.errorCount));
};

export function stringifySeats(expo: ISavedExpo): ISavedExpo {
  const copy = deepClone(expo);
  copy.noOfSeats = String(copy.noOfSeats);
  return copy;
}

export function orderColumnByMap<ColObj = {}>(
  arrayOrderMap: string[],
  columnToOrder: Array<IColumn<ColObj>>,
) {
  const sortKeys = arrayOrderMap.map((x) => x.replace('_', ' '));

  return columnToOrder.sort((a, b) => {
    return sortKeys.indexOf(a.Header ?? '') - sortKeys.indexOf(b.Header ?? '');
  });
}

export const contentHasErrors = (content: IFormattedDemo[]) =>
  content.some((item) => item.errors > 0);
