import { Toast, ToastPadding, ToastSeverity } from '../../pov-common-ui';
import * as React from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../../../redux/types';
import { getToastProps } from '../../../../redux/toast/selectors';
import './styles.scss';

export interface IProps {
  title: string;
  message: string;
  severity: ToastSeverity;
  show: boolean;
}

export function ExpoToast(props: IProps) {
  const className = `${props.show ? 'show-toast' : ''}`;
  return (
    <Toast {...props} className={className} padding={ToastPadding.LOOSE} />
  );
}

const mapStateToProps = (state: IApplicationState) => getToastProps(state);

export default connect(mapStateToProps)(ExpoToast);
