import classnames from 'classnames';
import * as React from 'react';
export interface IProps {
  id: string;
  index: number;
  title: string;
  active?: boolean;
  setSelected(index: number, title: string): void;
}

class TabsItem extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    active: false,
    id: '',
  };

  public render(): React.ReactNode {
    const { active, id, title } = this.props;
    return (
      <li
        role="presentation"
        className={classnames('tab', { active })}
        key={title}
      >
        <button
          id={id}
          aria-selected={active}
          role="tab"
          tabIndex={active ? -1 : 0}
          type="button"
          onClick={this.handleOnClick}
        >
          <div className="tab__heading">{title}</div>
        </button>
      </li>
    );
  }

  private handleOnClick = (): void => {
    const { setSelected, index, title } = this.props;
    setSelected(index, title);
  };
}

export default TabsItem;
