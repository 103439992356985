import { Card, CardContent, CardHeader } from '../../pov-common-ui';
import * as React from 'react';

interface IProps {
  children: React.ReactNode;
  title: string;
}

function CategoryCard(props: IProps) {
  return (
    <Card>
      <CardHeader title={props.title} />
      <CardContent>{props.children}</CardContent>
    </Card>
  );
}

export default CategoryCard;
