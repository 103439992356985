import { Columns, IColumn } from '../../../../../common/pov-common-ui';
import * as React from 'react';
import Cell from '../../../../../common/components/table-cell';
import { orderColumnByMap } from '../../../utilities';

const summaryColumns = (data: ICapacityDetail[]): Columns<ICapacityDetail> =>
  orderColumnByMap(
    ['capacity', 'dns', 'public-ip', 'device'],
    Object.keys(data[0]).map(
      (key) =>
        ({
          Header: key.replace('_', ' '),
          Cell: (cellInfo) => <Cell {...cellInfo.original[key]} />,
          accessor: `${key}.value`,
        } as IColumn<ICapacityDetail>),
    ),
  );

export default summaryColumns;
