import * as React from 'react';
import './styles.scss';

interface IProps {
  left?: React.ReactNode;
  right?: React.ReactNode;
}

function NavBar({ left, right }: IProps) {
  return (
    <header className="header header--compressed nav-bar">
      <div className="header-bar container layout__container">
        <div className="logo-container">
          <span className="icon-cisco" />
          <h1>eXpo</h1>
        </div>
        <div className="nav-bar__main">{left}</div>
        <div className="header-toolbar">{right}</div>
      </div>
    </header>
  );
}

export default NavBar;
