import * as React from 'react';
import {
  ExpoStates,
  ExpoStatesText,
} from '../../../../entities/expos/constants';
import './styles.scss';

export interface IProps {
  children: ExpoStates;
}

const classes = {
  IN_PROGRESS: 'success',
  PUBLISHED: 'vibblue',
  UNPUBLISHED: 'indigo',
  COMPLETE: 'dkgray',
};

const text = {
  IN_PROGRESS: ExpoStatesText.IN_PROGRESS,
  PUBLISHED: ExpoStatesText.PUBLISHED,
  UNPUBLISHED: ExpoStatesText.UNPUBLISHED,
  COMPLETE: ExpoStatesText.COMPLETE,
};

export function CardLabel({ children }: IProps) {
  return (
    <div className={`label menu-expo-card-label label--${classes[children]}`}>
      {text[children]}
    </div>
  );
}
