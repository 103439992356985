import { connect } from 'react-redux';
import RebalanceControls from '.';
import { IApplicationState } from '../../../../../redux/types';
import { infoActions } from '../../../../../redux/admin/info/actions';

import {
  getFilterBy,
  getInitialTotalTargetSessions,
  getTotalTargetSessions,
  getTargetInputIsDirty,
} from '../../../../../redux/admin/info/selectors';

const mapStateToProps = (state: IApplicationState) => ({
  filterBy: getFilterBy(state),
  initialTotalTargetSessions: getInitialTotalTargetSessions(state),
  totalTargetSessions: getTotalTargetSessions(state),
  targetInputIsDirty: getTargetInputIsDirty(state),
});

const mapDispatchToProps = {
  applyChanges: infoActions.applyRebalanceChanges,
};

export default connect(mapStateToProps, mapDispatchToProps)(RebalanceControls);
