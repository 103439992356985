import { ISavedExpo } from '../../../../../app/entities/expos/types';
import {
  Actions,
  FETCH_EXPO_SUCCESS,
  RESET,
  SET_CSV_VALUE,
  UPDATE_TEMP_EXPO_FIELD,
} from '../../actions';

export type EditTempExpoState = ISavedExpo | null;

export default function tempExpoReducer(
  state: EditTempExpoState = null,
  action: Actions,
): EditTempExpoState {
  switch (action.type) {
    case FETCH_EXPO_SUCCESS:
      return action.payload.expo;

    case SET_CSV_VALUE:
      if (state === null) return state;
      return { ...state, csvContent: action.payload };

    case UPDATE_TEMP_EXPO_FIELD:
      if (state === null) return state;

      return {
        ...state,
        ...action.payload,
      };

    case RESET:
      return null;

    default:
      return state;
  }
}
