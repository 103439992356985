import * as React from 'react';
import './styles.scss';

interface IProps {
  buttons?: React.ReactNode;
  title: string;
  content: React.ReactNode | string;
}

export default function ModalInner({ buttons, content, title }: IProps) {
  return (
    <>
      <div className="modal__header">
        <h1 className="modal__title">{title}</h1>
      </div>
      <div className="modal__body">{content}</div>
      <div className="modal__footer">{buttons}</div>
    </>
  );
}
