import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../pov-common-ui';
import ModalInner from '../modal-inner';

export type EndExpoProps = {
  closeModal: () => void;
  endExpo: (expoUid: string) => void;
  expoUid: string;
};

const EndExpo = ({
  closeModal,
  endExpo,
  expoUid,
}: EndExpoProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <ModalInner
      title={t('components.modal.endExpo.title')}
      content={
        <>
          <p className="text-italic">
            {t('components.modal.endExpo.content.note')}
          </p>
          <p>{t('components.modal.endExpo.content.question')}</p>
        </>
      }
      buttons={
        <>
          <Button
            onClick={() => endExpo(expoUid)}
            color="primary"
            id={`end-modal-end-btn-${expoUid}`}
          >
            {t('components.modal.buttons.end')}
          </Button>
          <Button
            onClick={closeModal}
            color="secondary"
            id={`end-modal-cancel-btn-${expoUid}`}
          >
            {t('components.modal.buttons.cancel')}
          </Button>
        </>
      }
    />
  );
};

export default EndExpo;
