import { determineDataCenterConnectivity } from '../../../../../app/admin/info/components/rebalance/utils/utils';
import { IDataCenter } from '../../../../../app/entities/expos/types';

export const getFilterValueWithConnectivity = (
  name: string,
  value: string = name,
  connectivity: string | undefined,
): {
  name: string;
  value: string;
} => {
  return {
    name: connectivity === '(Disconnected)' ? `${name} ${connectivity}` : name,
    value,
  };
};

export const determineConnectivity = (
  dataCenters: IDataCenter[],
  name: string,
): string => {
  let connectivityResult;

  if (dataCenters.length === 0) {
    connectivityResult = '';
  } else {
    connectivityResult = determineDataCenterConnectivity(
      dataCenters,
      name,
      'DISCONNECTED',
    )
      ? '(Disconnected)'
      : '';
  }

  return connectivityResult;
};
