import classnames from 'classnames';
import * as React from 'react';
import TabPanels from './components/TabPanels/TabPanels';
import TabsItems from './components/TabsItems/TabsItems';
import { Align } from './types';

export interface IProps {
  id: string;
  bordered?: boolean;
  align?: Align;
  children: React.ReactNode;
  tall?: boolean;
  vertical?: boolean;
  onTabChange?(title: string): void;
}

interface IState {
  active: number;
}

class Tabs extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    align: undefined,
    bordered: false,
    onTabChange: () => void 0,
    tall: false,
    vertical: false,
  };

  public state: IState = {
    active: 0,
  };

  public setSelected = (
    index: number,
    title: string | React.ReactElement<{ children: string }>,
  ): void => {
    const { onTabChange } = this.props;

    if (onTabChange) {
      const value = typeof title === 'string' ? title : title.props.children;
      onTabChange(value);
    }

    this.setState({ active: index });
  };

  public renderTabsItems = (): React.ReactNode => {
    const { active } = this.state;
    const { id, children, vertical, ...rest } = this.props;
    return (
      <TabsItems
        id={id}
        active={active}
        setSelected={this.setSelected}
        items={children}
        vertical={vertical}
        {...rest}
      />
    );
  };

  public renderTabPanels = (): React.ReactNode => {
    const { active } = this.state;
    const { children } = this.props;
    return <TabPanels active={active} items={children} />;
  };

  public render(): React.ReactNode {
    const { vertical } = this.props;
    return (
      <>
        {vertical && (
          <div className={classnames({ row: vertical })}>
            <div className={classnames({ 'col-md-3': vertical })}>
              {this.renderTabsItems()}
            </div>
            <div className={classnames({ 'col-md-9': vertical })}>
              {this.renderTabPanels()}
            </div>
          </div>
        )}
        {!vertical && (
          <>
            {this.renderTabsItems()}
            {this.renderTabPanels()}
          </>
        )}
      </>
    );
  }
}

export default Tabs;
