import { Button, Card } from '../../../../common/pov-common-ui';
import * as React from 'react';
import { LinkButton } from '../../../../common/components/link-button';
import { DEMO_TYPES, ROUTES } from '../../../../common/constants';
import './styles.scss';

export interface IProps {
  endDemo: () => void;
  demoName: string;
  demoType: string;
  expoId: string;
}

export default function InProgress({
  demoName,
  demoType,
  endDemo,
  expoId,
}: IProps) {
  return (
    <div className="row" id="in-progress">
      <div className="col-md-6">
        <Card className="in-progress">
          <h2 className="in-progress__title">In Progress</h2>
          <div className="in-progress__content">
            <div className="in-progress__info">
              <h3 className="in-progress__demo">{demoName}</h3>
            </div>
            <div className="in-progress__buttons">
              <LinkButton
                color="primary"
                to={
                  demoType === DEMO_TYPES.INSTANT
                    ? ROUTES.INSTANT_DEMO.replace(':id', expoId)
                    : ROUTES.SCHEDULED_DEMO.replace(':id', expoId)
                }
              >
                Re-launch
              </LinkButton>
              <Button
                id="end-demo-engagement"
                color="secondary"
                onClick={endDemo}
              >
                Logout and End Session
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
