export const base64URLEncode = (buffer: ArrayBuffer) => {
  const array: number[] = Array.from(new Uint8Array(buffer));
  return btoa(String.fromCharCode.apply(null, array))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
};

export const stripTrailingSlash = (text: string): string =>
  text.replace(/\/+$/, '');

export const stripLeadingAndTrailingSlash = (text: string): string =>
  text.replace(/^\/*(.+?)\/*$/, '$1');

export const buildUrl = (baseUrl: string, path: string): string =>
  [stripTrailingSlash(baseUrl), stripLeadingAndTrailingSlash(path)].join('/');

export const generateRandomString = (): string => {
  const array = new Uint32Array(28);
  window.crypto.getRandomValues(array);
  return Array.from(array, (dec) => ('0' + dec.toString(16)).substr(-2)).join(
    '',
  );
};

export const sha256 = async (plain: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return await window.crypto.subtle.digest('SHA-256', data);
};
