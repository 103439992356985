import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { IActionWithPayload } from '../../../../action-helpers';
import { engagementActions, CREATE_ENGAGEMENT } from '../../actions';
import { modalActions } from '../../../../modals/actions';
import { globalUiActions } from '../../../../ui/loader/actions';
import {
  postEngagement,
  getExpoDemoEngagement,
} from '../../../../../app/entities/expos/service';
import { storageSet } from '../../../../../app/common/storage';
import {
  LOCAL_STORAGE_KEYS,
  DEMO_TYPES,
  ROUTES,
} from '../../../../../app/common/constants';

type Action = IActionWithPayload<
  typeof CREATE_ENGAGEMENT,
  {
    demo: IDemo;
    expoUid: string;
    values: IStartEngagementPayload;
  }
>;

export default function* startDemoEngagementWorker(
  action: Action,
): SagaIterator {
  const { values, demo, expoUid } = action.payload;

  try {
    yield put(globalUiActions.showLoader());
    const engagementId = yield call(postEngagement, demo.uid, values);
    yield put(engagementActions.createEngagementSuccess(engagementId, expoUid));
    yield call(storageSet, LOCAL_STORAGE_KEYS.ENGAGEMENT_ID, engagementId);
    yield call(storageSet, LOCAL_STORAGE_KEYS.EXPO_UID, expoUid);
    yield put(modalActions.closeModal());

    const route =
      demo.type === DEMO_TYPES.INSTANT
        ? ROUTES.INSTANT_DEMO
        : ROUTES.SCHEDULED_DEMO;

    const withReplacedId = route.replace(':id', expoUid);

    const response = yield call(getExpoDemoEngagement, engagementId);
    yield put(engagementActions.fetchEngagementSuccess(response));
    yield put(push(withReplacedId));
  } catch (e) {
    // swallow the error here - do not throw the error
  } finally {
    yield put(globalUiActions.hideLoader());
  }
}
