import { connect } from 'react-redux';
import {
  getExposFetched,
  getExposLoading,
} from '../../redux/admin/expos/selectors';
import { IApplicationState } from '../../redux/types';
import { exposActions } from '../../redux/admin/expos/actions';
import { metadataActions } from '../../redux/admin/metadata/actions';
import Admin from '.';
import {
  getMetadataSurveyTypesFetched,
  getMetadataSurveyTypesLoading,
} from '../../redux/admin/metadata/selectors';

const mapStateToProps = (state: IApplicationState) => ({
  exposFetched: getExposFetched(state),
  exposLoading: getExposLoading(state),
  surveyTypesFetched: getMetadataSurveyTypesFetched(state),
  surveyTypesLoading: getMetadataSurveyTypesLoading(state),
});

const mapDispatchToProps = {
  fetchExpos: exposActions.fetchExpos,
  fetchSurveyTypes: metadataActions.fetchSurveyTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
