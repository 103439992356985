import { connect } from 'react-redux';
import Content from '.';
import { IApplicationState } from '../../../../../redux/types';
import { getDemosWithDataCenterConnectivity } from '../../../../../redux/admin/info/selectors';

const mapStateToProps = (state: IApplicationState) => ({
  demos: getDemosWithDataCenterConnectivity(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
