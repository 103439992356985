import { Columns, IColumn } from '../../../../../common/pov-common-ui';
import * as React from 'react';
import Cell from '../../../../../common/components/table-cell';
import { orderColumnByMap } from '../../../utilities';

const columns = (
  data: IFormattedCapacityDemoContent[],
): Columns<ICapacityDetail> => [
  ...orderColumnByMap(
    ['DEMO_ID', 'NAME', 'CATEGORY', 'DEMO_TYPE', 'SESSIONS', 'CONTENT_POOL'],
    Object.keys(data[0].content[0]).map(
      (key) =>
        ({
          Header: key.replace('_', ' '),
          Cell: (cellInfo) => <Cell {...cellInfo.original.content[0][key]} />,
          accessor: `content[0][${key}].value`,
        } as IColumn<ICapacityDetail>),
    ),
  ),
  {
    Header: '',
    expander: true,
    maxWidth: 75,
    Expander: ({ isExpanded, ...rest }) =>
      !!rest.original.details.length && (
        <span
          className={`table-chevron icon-chevron-${
            isExpanded ? 'up' : 'down'
          } icon-medium-small`}
        />
      ),
  },
];

export default columns;
