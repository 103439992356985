import { useEffect, useMemo } from 'react';
import { EngagementTimeoutValues } from '../../../../entities/expos/constants';
import { EXTENSION_HRS_ID, END_ENGAGEMENT_MANAGEMENT_ID } from '../constants';

const { DEFAULT_HOURS, EXTENSION_MAX_MINS, EXTENSION_MIN_MINS } =
  EngagementTimeoutValues;

export const extensionIsMax = (mins: number | undefined) =>
  mins === EXTENSION_MAX_MINS;

export default function useEngagementDurationState(
  isNewExpo: boolean,
  updateField: (name: string, value: number | boolean | undefined) => void,
  maxExtensionName: string,
  maxExtensionValue?: number,
) {
  const extensionHrs = useMemo(() => {
    if (!maxExtensionValue) return EXTENSION_MAX_MINS;
    return maxExtensionValue - (maxExtensionValue % 60);
  }, [maxExtensionValue]);

  const extensionMins = useMemo(() => {
    if (!maxExtensionValue) return 0;
    return maxExtensionValue % 60;
  }, [maxExtensionValue]);

  const updateAllowExtension = (): void => {
    const totalMins: number | undefined = maxExtensionValue
      ? undefined
      : extensionHrs + extensionMins;

    updateField(maxExtensionName, totalMins);
  };

  const updateExtension = (name: string, value: number): void => {
    const sum =
      name === EXTENSION_HRS_ID ? value + extensionMins : extensionHrs + value;
    const totalMins: number = extensionIsMax(value) ? EXTENSION_MAX_MINS : sum;

    updateField(maxExtensionName, totalMins);
  };

  const updateTimeout = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    parseInt(value) < 0
      ? updateField(name, 0)
      : updateField(name, parseInt(value, 10) * 60);
  };

  const toggleManagement = (e: React.FormEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    if (!checked) {
      updateField(
        'engagementTimeoutInMin',
        parseInt(DEFAULT_HOURS.toString(), 10) * EXTENSION_MIN_MINS,
      );
      updateField('engagementTimeoutWarningInMin', 60);
      updateField(maxExtensionName, EXTENSION_MAX_MINS);
    }
    updateField(END_ENGAGEMENT_MANAGEMENT_ID, checked);
  };

  useEffect(() => {
    if (isNewExpo) {
      updateExtension(maxExtensionName, extensionHrs + extensionMins);
    }
    // eslint-disable-next-line
  }, []);

  return {
    extensionHrs,
    extensionMins,
    toggleManagement,
    updateAllowExtension,
    updateExtension,
    updateTimeout,
  };
}
