import { Card, CardHeader } from '../../../../common/pov-common-ui';
import * as React from 'react';
import AddContentForm from './add-content-form';
import DemoContentTable from './demo-content-table';
import { editActions } from '../../../../../redux/admin/edit/actions';
import './styles.scss';

export interface IProps {
  value?: string;
  errors: string[];
  demoContent?: FormattedDemoContent;
  disableValidate: boolean;
  setCsvValue: typeof editActions.setCsvValue;
  showCheckCapacity: boolean;
  disableCheckCapacity: boolean;
  validating: boolean;
  validate: typeof editActions.validateCsv;
  checkCapacity: typeof editActions.checkCapacity;
  isCapacityChecked: boolean;
  capacityDetail: IFormattedCapacityContent[];
}

export default function Content({
  value,
  errors,
  validating,
  demoContent,
  disableValidate,
  setCsvValue,
  showCheckCapacity,
  disableCheckCapacity,
  validate,
  checkCapacity,
  isCapacityChecked,
  capacityDetail,
}: IProps) {
  const callValidate = React.useCallback(() => {
    if (!value) return;
    validate();
  }, [value, validate]);

  return (
    <Card className="content-card" loading={validating}>
      <CardHeader title="Content" alignTitle="center" />
      <AddContentForm
        csvErrors={errors}
        csvContent={value}
        disableValidate={disableValidate}
        setCsvValue={setCsvValue}
        validate={callValidate}
      />
      {demoContent && (
        <DemoContentTable
          data={demoContent}
          showCheckCapacity={showCheckCapacity}
          disableCheckCapacity={disableCheckCapacity}
          checkCapacity={checkCapacity}
          isCapacityChecked={isCapacityChecked}
          capacityDetail={capacityDetail}
        />
      )}
    </Card>
  );
}
