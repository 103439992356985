import { SagaIterator } from 'redux-saga';
import { all, takeLatest } from 'redux-saga/effects';
import {
  FETCH_ENGAGEMENT,
  CREATE_ENGAGEMENT,
  END_ENGAGEMENT,
  EXTEND_ENGAGEMENT,
} from '../actions';
import fetchEngagementWorker from './fetch-engagement-worker';
import startDemoEngagementWorker from './start-demo-engagement-worker';
import { endDemoEngagementWorker } from './end-demo-engagement-worker';
import { extendEngagementWorker } from './extend-engagement-worker';

export default function* engagementsWatcher(): SagaIterator {
  yield all([
    takeLatest(FETCH_ENGAGEMENT, fetchEngagementWorker),
    takeLatest(CREATE_ENGAGEMENT, startDemoEngagementWorker),
    takeLatest(END_ENGAGEMENT, endDemoEngagementWorker),
    takeLatest(EXTEND_ENGAGEMENT, extendEngagementWorker),
  ]);
}
