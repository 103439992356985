import {
  Actions,
  CREATE_EXPO_SUCCESS,
  SET_FORM_VALIDITY,
  UPDATE_EXPO,
} from '../actions';
import { initialExpo } from '../../../../app/entities/expos/defaults';

export type CreateState = typeof initialState;

export const initialState = {
  valid: false,
  expo: initialExpo,
};

export default function createReducer(
  state: CreateState = initialState,
  action: Actions,
): CreateState {
  switch (action.type) {
    case UPDATE_EXPO:
      return {
        ...state,
        expo: action.payload,
      };

    case CREATE_EXPO_SUCCESS:
      return initialState;

    case SET_FORM_VALIDITY:
      return { ...state, valid: action.payload };

    default:
      return state;
  }
}
