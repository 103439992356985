import { SagaIterator } from 'redux-saga';
import { select, call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { getEngagementUid } from '../../selectors';
import {
  getExpoDemoEngagement,
  endExpoDemoEngagement,
} from '../../../../../app/entities/expos/service';
import { engagementActions } from '../../actions';
import { storageDelete } from '../../../../../app/common/storage';
import {
  LOCAL_STORAGE_KEYS,
  ROUTES,
} from '../../../../../app/common/constants';

export default function* fetchEngagementWorker(): SagaIterator {
  try {
    const uid = yield select(getEngagementUid);
    const response = yield call(getExpoDemoEngagement, uid);
    yield put(engagementActions.fetchEngagementSuccess(response));
  } catch (e) {
    if (e.response.status >= 300 && e.response.status <= 430) {
      try {
        const uid = yield select(getEngagementUid);
        yield call(storageDelete, LOCAL_STORAGE_KEYS.ENGAGEMENT_ID);
        yield put(engagementActions.clearEngagement());
        yield call(endExpoDemoEngagement, uid);
        yield put(push(ROUTES.RESOURCE_NOT_FOUND_404_USER));
      } catch (e) {
        yield put(push(ROUTES.RESOURCE_NOT_FOUND_404_USER));
      }
    }
  }
}
