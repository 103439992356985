import { required } from '../../../common/pov-common-ui';
import {
  belowEqualToInt,
  isCheckedAndHasValue,
  maxLength,
  notAFloat,
  numberBetween,
  positiveNum,
  requiredTimeZone,
  requiredValue,
} from '../../../common/form/validation';
import { EngagementTimeoutValues } from '../../../entities/expos/constants';

const { MIN_HOURS, MAX_HOURS } = EngagementTimeoutValues;
const NO_OF_SEATS_ERROR = 'Please enter a whole number between 0 and 500';
const DURATION_ERROR = 'Please enter a number between 1 and 120';

export default {
  backgroundImage: [
    required('Please choose a background image'),
    maxLength('Enter a URL of 255 characters or less', 255),
  ],
  end: [requiredValue('Please provide the end date')],
  gettingStarted: [
    required('Please provide a getting started text'),
    maxLength('Must be 500 characters or less', 500),
  ],
  location: [
    required('Please provide the location of the expo'),
    maxLength('Enter a location with 30 characters or less', 30),
  ],
  name: [
    required('Please enter the name of your expo'),
    maxLength('Enter a name with 20 characters or less', 20),
  ],
  noOfSeats: [
    requiredValue('Please enter the number of seats'),
    notAFloat(NO_OF_SEATS_ERROR),
    positiveNum(NO_OF_SEATS_ERROR),
    belowEqualToInt(NO_OF_SEATS_ERROR, 500),
  ],
  start: [requiredValue('Please provide the start date')],
  description: [maxLength('Must be 50 characters or less', 50)],
  type: [required('Please provide the expo type')],
  timezone: [requiredTimeZone('Please select a timezone')],
  engagementTimeoutInMin: [
    notAFloat('Please enter a whole number'),
    numberBetween(DURATION_ERROR, MIN_HOURS * 60, MAX_HOURS * 60),
  ],
  engagementManaged: [],
  engagementTimeoutWarningInMin: [],
  maxEngagementExtensionInMin: [],
  feedbackSurveyManagement: [],
  feedbackName: [],
  feedbackOption: [
    isCheckedAndHasValue('Please select an option', 'feedbackSurveyManagement'),
  ],
  feedbackUrl: [
    isCheckedAndHasValue(
      'Please enter a valid URL',
      'feedbackSurveyManagement',
    ),
  ],
};
