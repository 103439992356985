import { AuthActions } from './types';
import { AuthActionTypes, AuthStatusValues } from '../../auth';

export function authReducer(
  state = AuthStatusValues.NotAuthenticated,
  action: AuthActions,
): AuthStatusValues {
  switch (action.type) {
    case AuthActionTypes.SetAuthStatus:
      return action.payload;

    default:
      return state;
  }
}
