import { IApplicationState } from '../../../types';
import { CreateState } from '../reducer';
import { IExpoBase } from '../../../../app/entities/expos/types';

export const getCreateState = (state: IApplicationState): CreateState =>
  state.admin.create;

export const getFormValidity = (state: IApplicationState): boolean =>
  getCreateState(state).valid;

export const getExpo = (state: IApplicationState): IExpoBase =>
  getCreateState(state).expo;
